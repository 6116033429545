import * as yup from 'yup';
import { createConditionalValidator, createEquipmentTypeValidator } from 'utils/yup';
import { getFeaturesSchema } from 'constants/featuresSchema';

const schema = getFeaturesSchema();

const validationSchema = yup.object().shape({
  ...schema.reduce((schema, feature) => {
    const tmp = {};
    if (Array.isArray(feature.form)) {
      feature.form.forEach((field) => {
        if (field.name.includes('equipment_type')) {
          tmp[field.name] = createEquipmentTypeValidator(feature.name);
        }
      });
    }
    return { ...schema, ...tmp };
  }, {}),
  solar_power_system_size_installation: createConditionalValidator('solar_power_system', 'number'),
  battery_storage_size: createConditionalValidator('battery_storage', 'number'),
  facade_insulation_type: createConditionalValidator('facade_insulation', 'string'),
  facade_insulation_area: createConditionalValidator('facade_insulation', 'number'),
  heating_system_for_renovation: createConditionalValidator('heating_system', 'string'),
  new_windows_number: createConditionalValidator('new_windows', 'number'),
  lift_and_slide_doors_number: createConditionalValidator('new_windows', 'number'),
  roof_type: createConditionalValidator('roof', 'string'),
  roof_area: createConditionalValidator('roof', 'number'),
  insulation_top_ceiling_area: createConditionalValidator('insulation_top_ceiling', 'number'),
  insulation_basement_ceiling_area: createConditionalValidator('insulation_basement_ceiling', 'number'),
});

export default validationSchema;
