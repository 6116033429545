import React, { memo, useEffect, useMemo } from 'react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { areEqualFields } from 'utils/form';
import cn from 'classnames';
import s from './Counter.module.scss';

const Counter = ({
  field: { name, value },
  form,
  label,
  defaultValue,
  min = 0,
  max,
  description,
  sideEffectOnChange,
  dependencyFieldName,
  calculatedMin,
  calculatedMax,
}) => {
  const { setFieldValue, touched, errors, values } = form;

  const minValue = useMemo(
    () => (dependencyFieldName ? calculatedMin(values[dependencyFieldName]) : min),
    [dependencyFieldName, min, calculatedMin, values],
  );

  const maxValue = useMemo(
    () => (dependencyFieldName ? calculatedMax(values[dependencyFieldName]) : max),
    [dependencyFieldName, max, calculatedMax, values],
  );

  const handleOnChangeNumber = React.useCallback(
    (condition) => {
      let newValue = value;
      if (condition === 'increment' && newValue < maxValue) newValue += 1;
      if (condition === 'decrement' && newValue > minValue) newValue -= 1;
      if (value < minValue) newValue = minValue;
      setFieldValue(name, newValue);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ value: newValue, form });
    },
    [value, maxValue, minValue, setFieldValue, name, sideEffectOnChange, form],
  );

  useEffect(() => {
    if (!Number.isFinite(value)) {
      setFieldValue(name, defaultValue || minValue);
    }
    if (Number.isFinite(value) && value < minValue) setFieldValue(name, minValue);
  }, [name, value, defaultValue, setFieldValue, minValue]);

  return (
    <div className="field-wrapper">
      <label htmlFor={name}>{label}</label>
      <div className={s.counter}>
        <button type="button" onClick={() => handleOnChangeNumber('decrement')} className={cn(s.counterBtn, s.ripple)}>
          <Minus />
        </button>

        <div className={s.value}>{value}</div>

        <button type="button" onClick={() => handleOnChangeNumber('increment')} className={cn(s.counterBtn, s.ripple)}>
          <Plus />
        </button>
      </div>
      {description ? <div className={s.description}>{description}</div> : null}
      {touched[name] && errors[name] && <div className="text-danger">{errors[name]}</div>}
    </div>
  );
};

export default memo(Counter, areEqualFields);
