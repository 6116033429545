export default {
  translation: {
    button: {
      start: 'Start',
      next: 'Weiter',
      back: 'Zurück',
      ok: 'Ok',
      save: 'Sichern',
      continue: 'Fortsetzen',
      goHome: 'Zurück zur Homepage',
    },
    modal: {
      detailed_info: 'Detailinformationen',
    },
    errors: {
      chooseOne: 'Bitte Auswahl treffen',
      fillField: 'Bitte dieses Feld ausfüllen!',
      wrongZipCode: 'Bitte korrekte Postleitzahl eingeben!',
      privacyPolicyRequired: 'Bevor es weitergeht, bitte die Datenschutzerklärung akzeptieren',
      invalidEmail: 'Emailadresse ist ungültig',
      projectNotFound: 'Projekt nicht gefunden',
      unexpectedError: 'Unexpected error occurred',
      termsOfUseRequired: 'Bitte den Nutzungsbedingungen zustimmen!',
      minItems: 'Mindestens {{min}} gültige Domain',
    },
    user: {
      firstName: 'Vorname',
      lastName: 'Nachname',
    },
    auth: {
      email: 'E-Mail',
      phoneNumber: 'Telefon',
      privacyPolicyLabel:
        'Ich habe die <privacyPolicyUrl>Datenschutzerklärung</privacyPolicyUrl> gelesen und akzeptiere sie',
      termsOfUseLabel: 'Ich akzeptiere die <termsOfUseUrl>Nutzungsbedingungen</termsOfUseUrl>',
      contactAgreement: 'Ich bin damit einverstanden, dass mich dummy bezüglich Energieberatung kontaktieren',
    },
    priceHubbleApiSection: {
      title: 'Wertsteigerung Deiner Immobilie',
      subtitle:
        'Durch die Sanierung verbessert sich nicht nur Dein Energieverbrauch und die CO2-Bilanz. Auch steigt Deine Immobilie in ihrem Wert unmittelbar nach der Umsetzung der Sanierungsmaßnahmen!',
      today: 'Heute',
      inTheFuture: 'In Zukunft',
      increaseInValue: 'Wertsteigerung',
      serviceIsNotAvailable: 'N/A \n Service ist aktuell nicht verfügbar',
    },
    breakEvenPointTracker: {
      title: 'Ab wann zahlen sich Deine Investitionen aus?',
      details:
        'Hier kann man den Finanzierungsbedarf und die monatlichen Kosten für den geplanten Zeitraum berechnen. Die grafische Darstellung passt sich entsprechend der Wert an und zeigt, ab wann sich die Investitionen ausgezahlt haben.',
      form: {
        financingRequirement: 'Finanzierungsbedarf',
        totalRenovationCosts: 'Gesamtkosten Sanierung',
        governmentFunding: 'Staatliche Förderung',
        equity: 'Eigenkapital',
        financing: 'Finanzierung',
        period: 'Laufzeit',
        interestRate: 'Zinssatz p.a.',
        monthlyRate: 'Finanzierungskosten',
        energyCosts: 'Energiekosten',
        new: 'Neu',
        old: 'Alt',
        totalMonthly: 'Insgesamt monatlich',
      },
      chart: {
        title: 'Kostenersparnis',
        time: 'Zeit',
        energyCostsWithoutRenovations: {
          firstPart: 'Energiekosten ohne',
          secondPart: 'Sanierungsmaßnahmen',
        },
        financingRepaid: {
          firstPart: 'Finanzierung',
          secondPart: 'getilgt',
        },
        profitStartPoint: {
          firstPart: 'Ab hier fängt',
          secondPart: 'Dein Gewinn an',
        },
        energyCostsWithRenovations: {
          firstPart: 'Energiekosten mit',
          secondPart: 'Sanierungsmaßnahmen',
          thirdPart: 'und Finanzierungskosten',
        },
        legend: {
          governmentFunding: 'Staatliche Förderung',
          equity: 'Eigenkapital',
          loanAmount: 'Finanzierungsbedarf',
        },
      },
    },
    co2CalculatorForm: {
      moreInfoAboutHouse: 'Die wichtigsten Details zu Deinem Haus',
      heated_living_area: 'Beheizte Wohnfläche',
      calculationTypeLabel: 'Mit welchen Energieverbräuchen möchtest Du weitermachen?',
      calculationType: {
        custom: 'Eigene Werte',
        system: 'Unsere Schätzwerte',
      },
      heated_living_area_disclaimer:
        'Hinweis: Die beheizte Wohnfläche kann nicht größer als die Wohnfläche sein, diese kann <linkToThirdStep>im Schritt 3</linkToThirdStep> erhöht werden.',
      kind_of_house: {
        name: 'Um welchen Haustyp handelt es sich?',
        single_family_house: 'Standard Einfamilienhaus',
        bungalow_or_complex_floor_plan: 'Bungalow oder komplexer Grundriss',
        town_house: 'Reihenhaus',
        semi_detached_house: 'Doppelhaushälfte',
      },
      energy_standard: {
        name: 'Energiestandard des Hauses',
        description: 'ab 2006 (Niedrigenergie)',
        detailed_info:
          'Der Energiestandard eines Gebäudes legt fest, wie hoch der Energiebedarf pro Quadratmeter Energiebezugsfläche und Jahr sein darf. Generell wird ein bestimmter Energiestandard durch bauliche Maßnahmen und Haustechnik erreicht. Je älter ein unsaniertes Haus ist, um so schlechter ist im Regelfall der Energiestandard, unser Kalkulator arbeit hier mit Durchschnittswerten.',
        before_1960: 'Vor 1960',
        between_1960_and_1975: '1960-1975',
        between_1976_and_1990: '1976-1990',
        after_1990: '1990-2005',
        low_energy_house: 'ab 2006',
        passive_house: 'Passivhaus',
      },
      renovations: {
        name: 'Wurden bereits Renovierungen durchgeführt?',
        new_windows: 'Neue Fenster',
        insulation_top_ceiling: 'Dämmung oberste Geschossdecke',
        insulation_basement_ceiling: 'Dämmung Kellerdecke (m²)',
        insulation_facade: 'Fassadendämmung',
        controlled_living_space_ventilation: 'Kontrollierte Wohnraumlüftung',
        notRenovated: 'Nicht renoviert',
      },
      number_of_people: 'Anzahl der Personen die im Haus leben',
      tell_about_your_heating: 'Nähere Informationen über Deine Heizung',
      more_info_about_hot_water: 'Nähere Informationen zum Warmwasser',
      more_info_about_electricity: 'Nähere Informationen zum Stromverbrauch',
      heating_system: {
        name: 'Heizsystem',
        natural_gas: 'Erdgas',
        district_heating_biomass: 'Fernwärme Biomasse',
        district_heating_standard: 'Fernwärme Standard',
        wood_chips: 'Hackschnitzel',
        wood_pellets: 'Holz Pellets',
        firewood: 'Stückholz',
        coal: 'Kohle',
        heating_oil: 'Heizöl',
        electric: 'Stromheizung',
        heat_pump: 'Wärmepumpe',
      },
      is_solar_heating_system: 'Es existiert bereits eine Solarthermieanlage',
      solar_heating_system_type: {
        name: 'Welche Art von Solarthermie hast Du',
        heating_and_hot_water: 'Für Heizung & Warmwasser',
        only_hot_water: 'Nur Warmwasser',
      },
      age_of_heating_system: {
        name: 'Alter der Heizung',
        description: '"Neue Technologie" bedeutet, dass die Heizung jünger als 10 Jahre',
        less_than_10_years: 'Neue Technologie',
        between_10_and_20_years: 'Zwischen 10 und 20 Jahre',
        more_than_20_years: 'Älter als 20 Jahre',
      },
      temperature: {
        name: 'Wie hoch ist die Raumtemperatur?',
        description: 'Jedes Grad mehr erhöht Energiebedarf um 6%',
        less_than_21: 'Weniger als 21 Grad',
        between_21_and_23: 'Zwischen 21 and 23 Grad',
        more_than_23: 'Über 23 Grad',
      },
      hot_water_producer: {
        name: 'Wie wird das Warmwasser produziert?',
        heating_system: 'Mit dem Heizungssystem ',
        electric_boiler: 'Elektrischer Boiler ',
        gas_heater: 'Mit Gas',
        heat_pump: 'Wärmepumpe',
      },
      amount_of_hot_water: {
        name: 'Angaben zum Warmwasserverbrauch',
        description: 'Niedrig (nur duschen) - Mittel (50l/Tag duschen & baden) - Hoch (vorwiegend Vollbäder)',
        low: 'Niedrig',
        medium: 'Mittel',
        hight: 'Hoch',
      },
      are_devices_less_than_10_years_old:
        'Sind die meisten Deiner Geräte wie Herd, Kühl- und Gefriergeräte, Waschmaschine und Trockner jünger als 10 Jahre? ',
      power_consumers: {
        name: 'Welche außerordentlichen Stromverbraucher hast Du?',
        sauna: 'Sauna',
        outdoor_whirlpool: 'Outdoor-Whirlpool',
        swimming_pool: 'Pool (beheizt)',
        air_condition: 'Klimaanlage',
        waterbed: 'Wasserbett',
      },
      solarPowerSystem: {
        title: 'Photovoltaikanlage',
        isSolarPowerSystem: 'Es existiert bereits eine Photovoltaikanlage',
        solarPowerSystemSize: 'Wie groß ist die Anlage (kWp)?',
      },
      energyRequirements: {
        title: 'Eigene Verbrauchswerte erfassen (optional)',
        question:
          'Kennst Du Deinen exakten jährlichen Stromverbrauch UND Wärmebedarf (bitte nur ausfüllen wenn Du beides kennst)?',
        naturalGas: 'Erdgas (kWh)',
        coal: 'Kohle (kg)',
        firewood: 'Brennholz (Festmeter)',
        woodChips: 'Hackschnitzel (Raummeter)',
        woodPellets: 'Holzpellets (kg)',
        electricityUsed: 'Stromverbrauch (kWh)',
        heatingOil: 'Heizöl (Liter)',
        districtHeating: 'Fernwärme(kWh)',
      },
    },
    co2CalculatorResult: {
      calculatedValue: 'Schätzwerte',
      userValue: 'Eigene Werte',
      deviationWarningMessage: 'Abweichung zwischen unseren Schätzwerten und Deinen eigenen Werten {{value}}%',
      energyScaleClasses: {
        title: 'Energiebilanz Deines Hauses',
        actualClass: 'Heute',
        potentialClass: 'In Zukunft',
      },
      customEnergyRequirementsTable: {
        headers: {
          fuelType: 'Heizmittel',
          userInput: 'Eigene Eingabe',
          energy: 'Energie',
          costs: 'Kosten',
          ghg: 'GHG kg/a',
        },
        fuelType: {
          naturalGas: 'Erdgas (kWh)',
          coal: 'Kohle (kg)',
          firewood: 'Brennholz (Festmeter)',
          woodChips: 'Hackschnitzel (Raummeter)',
          woodPellets: 'Holzpellets (kg)',
          electricityUsed: 'Stromverbrauch (kWh)',
          heatingOil: 'Heizöl (Liter)',
          districtHeating: 'Fernwärme (kWh)',
        },
      },
      energyGhgEquivalents: {
        title: 'Dein Beitrag zum Klimaschutz: Deine Einsparungen entsprechen ...',
        details: 'Umgerechnet bedeuten Deine Einsparungen Folgendes:',
        flights: 'Flüge',
        equivalentCar: 'Autokilometern',
        equivalentPlane: ' Frankfurt - New York',
        equivalentTrees: 'Bäumen, die CO2 binden',
      },
      improveHouseBtnLabel: 'Haus optimieren',
      potentialEnergyBalance: {
        afterTitle: 'Einsparungspotential Deines Hauses, pro Jahr',
        title: 'Dein Einsparungspotential',
        description: 'Deine Werte bei vollumfänglicher Sanierung und Einbau einer Photovoltaikanlage (5kwP)',
        redevelopmentHouseLabel: 'Möchtest Du Dein individuelles Energieeinsparungspotential berechnen?',
        ghgBalance: {
          label: 'CO₂-Ausstoß',
          value: '{{value}} kg',
        },
        totalEnergyCosts: {
          label: 'Energiekosten',
          value: '{{value}} €',
        },
        totalEnergyBalancePerYear: {
          label: 'Energieverbrauch',
          value: '{{value}} kWh',
        },
      },
      heatingResult: {
        tableTitle: 'Ergebnisse Heizung',
        totalExternalEnergyDemand: 'Gesamter externer Energiebedarf',
        specificHeatingDemand: 'Spezifischer Heizwärmebedarf',
        specificHeatingDemandDescription: 'benötigte Wärmemenge pro m² beheizter Bruttogeschossfläche',
        heatingFactorKea: 'Heizungsfaktor KEA',
        heatingFactorKeaDescription:
          'beinhaltet alle energetischen Aufwendungen für Herstellung, Transport und Verbrauch des Heizmittels.',
        heatingFactorGhg: 'Heizungsfaktor CO₂ (GHG)',
        heatingFactorGhgDescription: 'Menge an CO₂ die je kWh Heizwärme mit dem gewählten Heizmittel produziert wird',
        totalHeatingDemand: 'Heizwärmebedarf',
        totalHeatingDemandDescription:
          'benötigte thermische Energie, um das Haus auf die gewünschte Temperatur zu heizen',
        primaryEnergyForHeating: 'Primärenergiebedarf für das Heizen',
        primaryEnergyForHeatingDescription:
          'Dies ist die Energiemenge in (kWh) die Du benötigst, um das Haus zu heizen, also den Heizwärmebedarf zu decken. Ein sehr starker Einflussfaktor ist hierbei natürlich auch der Wirkungsgrad des Heizsystems sowie die Menge an Energie die zur Herstellung des Heizmittels benötigt wird.',
        heatingGhgEmission: 'CO₂ Emission Heizung',
        heatingGhgEmissionDescription: 'Gesamtmenge an CO₂ die durch Deine Heizung erzeugt wird',
        heatingGhgEquivalentCar: 'Dies entspricht Kilometer mit einem Benzin-PKW',
        heatingGhgEquivalentCarDescription: 'Grundlage ist eine CO₂-Menge von 150g/km',
        heatingGhgEquivalentPlane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht  ',
        heatingGhgEquivalentPlaneDescription: 'Frankfurt - New York - Frankfurt Flüge (Economy-Class)',
        heatingGhgEquivalentTrees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
        heatingGhgEquivalentTreesDescription: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
        heatingCosts: 'Jährliche Heizkosten',
        heatingCostsDescription: 'basierend auf Durchschnittspreisen in Deutschland',
      },
      hotWaterResult: {
        tableTitle: 'Heisswasser',
        primaryEnergyForHotWater: 'Energiebedarf für Warmwasser',
        primaryEnergyForHotWaterDescription:
          'Dies ist die Energiemenge in (kWh) die Du benötigst, um das Warmwasser in Deinem Haus zu produzieren',
        hotWaterGhgEmission: 'CO₂ Emission Warmwasser',
        hotWaterGhgEmissionDescription: 'Gesamtmenge an CO₂ das durch die Warmwasserproduktion entsteht',
        hotWaterGhgEquivalentCar: 'Dies entspricht Kilometer mit einem Benzin-PKW',
        hotWaterGhgEquivalentCarDescription: 'Grundlage ist eine CO₂-Menge von 150g/km',
        hotWaterGhgEquivalentPlane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht  ',
        hotWaterGhgEquivalentPlaneDescription: 'Frankfurt - New York - Frankfurt Flüge (Economy-Class)',
        hotWaterGhgEquivalentTrees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
        hotWaterGhgEquivalentTreesDescription: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
        hotWaterCosts: 'Jährliche Kosten für Warmwasser',
        hotWaterCostsDescription: 'basierend auf Durchschnittspreisen in Deutschland',
      },
      electricityResult: {
        tableTitle: 'Ergebnisse Stromverbrauch',
        totalPowerConsumptionPerYear: 'Jährlicher Stromverbrauch',
        totalPowerConsumptionPerYearDescription: 'Gesamtverbrauch für Deinen Haushalt',
        totalPowerProductionPerYear: 'Jährliche Stromerzeugung durch PV-Anlage',
        totalPowerProductionPerYearDescription:
          'Strommenge die durch Deine PV-Anlage durchschnittlich produziert wird ',
        ownConsumption: 'Jährlicher Eigenverbrauch',
        ownConsumptionDescription: 'Geschätzter Eigenverbrauch des PV-Stroms',
        powerConsumptionFromEnergy: 'Menge an Strom die vom Energieversorger zugekauft wird',
        powerConsumptionFromEnergyDescription:
          'Dieser Wert kann auch negativ sein, wenn mehr PV-Strom produziert, als Strom verbraucht wird',
        electricalFeedInToGrid: 'Menge an Strom die ins Netz eingespeist wird',
        electricalFeedInToGridDescription: 'Überschussstrom der verkauft werden kann',
        ghgSavingsSolarPowerSystem: 'CO₂-Einsparung durch PV-Anlage',
        ghgSavingsSolarPowerSystemDescription:
          'Grundlage ist hier die CO₂ Menge die durchschnittlich bei der Stromerzeugung in Europa entsteht',
        ghgBalanceOfPowerConsumption: 'CO₂-Bilanz Deines Stromverbrauchs',
        ghgBalanceOfPowerConsumptionDescription:
          'Diese Menge an CO₂ entsteht durch Deinen Stromverbrauch bei der Stromerzeugung in Deutschland ',
        electricityGhgEquivalentCar: 'Dies entspricht Kilometer mit einem Benzin-PKW:',
        electricityGhgEquivalentCarDescription: 'Grundlage ist eine CO₂-Menge von 150g/km',
        electricityGhgEquivalentPlane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht',
        electricityGhgEquivalentPlaneDescription: 'Frankfurt - New York - Frankfurt Flüge (Economy-Class)',
        electricityGhgEquivalentTrees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
        electricityGhgEquivalentTreesDescription: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
        solarPowerSystemHelpsToSave: 'Deine PV-Anlage spart Dir jährlich',
        solarPowerSystemHelpsToSaveDescription: 'Basierend auf einer Einspeisevergütung von rund 8 Cent/kWh',
        totalElectricityCosts: 'Gesamtkosten für Strom',
        totalElectricityCostsDescription: 'basierend auf Durchschnittspreisen in Deutschland',
      },
      energyAndCo2BalanceResult: {
        tableTitle: 'DIE Energie- & CO₂-Bilanz Deines Hauses',
        systemTableTitle: 'DIE Energie- & CO₂-Bilanz Deines Hauses - basierend auf unserem Kalkulator',
        customTableTitle: 'DIE Energie- & CO₂-Bilanz Deines Hauses - basierend auf Deinen eingegebenen Verbräuchen',
        totalEnergyBalancePerYear: 'Dein gesamter jährlicher Energiebedarf ',
        totalEnergyBalancePerYearDescription: 'Wie viele kWh benötigst Du für das Heizen, Warmwasser und den Strom',
        energyGhgBalancePerYear: 'Deine jährliche CO₂-Bilanz',
        energyGhgBalancePerYearDescription: 'Menge an CO₂ die dadurch produziert wird ',
        energyGhgEquivalentCar: 'Dies entspricht Kilometer mit einem Benzin-PKW:',
        energyGhgEquivalentCarDescription: 'Grundlage ist eine CO₂-Menge von 150g/km',
        energyGhgEquivalentPlane: 'Anzahl an Flugreisen die dieser CO₂-Menge entspricht',
        energyGhgEquivalentPlaneDescription: 'Frankfurt - New York - Frankfurt Flüge (Economy-Class)',
        energyGhgEquivalentTrees: 'Anzahl an Bäumen die diese CO₂-Menge speichern',
        energyGhgEquivalentTreesDescription: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
        totalEnergyCosts: 'Gesamtkosten für Energie',
        totalEnergyCostsDescription: 'basierend auf Durchschnittspreisen in Deutschland',
      },
      valuesBeforeAndAfter: {
        tableTitle: 'Einsparungspotential einzelner Verbrauchsarten nach den Sanierungsmassnahmen',
        beforeRenovation: 'Vor der Sanierung pro Jahr',
        afterRenovation: 'Nach der Sanierung pro Jahr',
        totalEnergyDemand: 'Gesamter jährlicher Energiebedarf',
        totalElectricPowerConsumption: 'Stromverbrauch',
        totalElectricPowerProduction: 'Stromerzeugung durch PV-Anlage',
        producedElectricityOwnConsumption: 'Eigenverbrauch',
        electricityConsumptionFromEnergySupplyCompany: 'Netzbezug',
        electricalFeedIntoGrid: 'Netz-Einspeisung',
        primaryEnergyForHotWater: 'Primärenergiebedarf für Warmwasser',
        primaryEnergyForHeating: 'Primärenergiebedarf für das Heizen',
        hotWaterIsPartOfHeating: 'bei Eingabe von eigenen Verbrauchswerten ist Heißwasser Teil der Heizung',
        costsArePartOfElectricityCosts:
          'Kosten sind in Stromkosten enthalten, da es sich um ein elektrisches Heizsystem handelt',
        includedCosts: 'Inkludierte Kosten',
        energyDemandArePartOfPowerConsumption:
          'Energiebedarf ist im Stromverbrauch enthalten, da es sich um ein elektrisches Heizsystem handelt',
        includedEnergyDemand: 'Inkludierter Energieverbrauch',
      },
      showCalculations: 'Berechnungsdetails anzeigen',
      hideCalculations: 'Berechnungsdetails ausblenden',
      numberOfEconomyClassFlights: ' ',
      shouldShowAllCalculations: 'Möchtest Du alle Berechnungsdetails sehen?',
      numberStoredTrees: 'Ein Baum speichert rund 10kg CO₂ pro Jahr',
      houseEnergyBalance: {
        header: 'Aktuelle Energiebilanz Deines Hauses',
        beforeAndAfterHeader: 'Einsparungspotential durch die Sanierungsmaßnahmen',
        label: 'Energiekennzahlen Deines Hauses ohne Sanierungsmaßnahmen',
        specificHeatingDemand: '{{value}} kWh/m²a',
        heating: 'Heizung {{value}} kg CO₂/a',
        hotWater: 'Warmwasser {{value}} kg CO₂/a',
        electricity: 'Strom {{value}} kg CO₂/a',
      },
    },
    project: {
      tabs: {
        firstTab: 'Objektdaten',
        secondTab: 'Berechnungen auswählen',
        thirdTab: 'CO₂ Status',
        fourthTab: 'Allgemein',
        fifthTab: 'CO₂-Optimierung',
        finalTab: 'Ergebnisübersicht',
      },
      solar_power_system_size_installation: 'Wie groß soll die Anlage sein? (kWp)',
      solar_power_system_size_installation_description:
        'Beachte, dass für eine Nennleistung von 1 kWp ("Kilowatt peak") eine Dachfläche von ca. 7m² benötigt wird.',
      solar_power_system_equipment_type: {
        name: 'Welche Ausstattungskategorie soll Deine PV-Anlage haben',
        standard_description:
          'Eine <b>Standard</b> PV-Anlage wird auf das Dach montiert. Der daraus gewonnene Strom wird direkt in das Stromnetz eingespeist.',
        premium_description:
          'Bei einer <b>gehobenen</b> PV-Anlage wird von einem App-basierten Energiemanagementsystem ausgegangen. Eine höhere Eigennutzung der produzierten Energie ist das Ergebnis. Zusätzlich werden die PV-Module direkt in das Dach integriert, wodurch sich diese Variante auch optisch zum <b>Standard</b> abhebt.',
        superior_description:
          'Bei einer <b>Premium</b> PV-Anlage spricht man beispielsweise von Solar-Dachziegeln, die eine besonders ästhetische Optik ermöglichen. Teilweise entstehende Mehrkosten werden hierbei durch geringere Kosten bei der Dacheindeckung kompensiert.',
      },
      equipment_type: 'Ausstattungskategorie',
      floors: 'Anzahl der Etagen (ohne Keller und Dachboden)',
      project_name: 'Gib dem Projekt einen Namen',
      zip_code: 'Postleitzahl',
      full_address: 'Vollständige Adresse (Straße, Hausnummer, Ort)',
      complete_previous_step: 'Du musst zunächst das erste Projekt berechnen, bevor Du Zugang zum Dashboard haben!',
      complete_previous_step_btn: 'Projektdetails berrechnen',
      wrong_min_house_area: `Die Fläche des Hauses kann nicht kleiner als die beheizte Wohnfläche sein - {{value}} m²`,

      go_to_step_three_btn: ' weiter zu Schritt 3',
      update_btn_label: 'Angaben zum Haus ändern',

      improve_or_renovate_house: 'Möchtest Du Dein Haus sanieren oder verbessern?',
      edit_co2_calculator_values: 'Werte editieren',
      second_step_title: 'Haus renovieren & klimafit machen',

      new_windows_number: 'Wie viele Fenster werden getauscht?',
      extra_large_windows_number: 'Wie viele extra-große Fenster (>3m²) bzw. Balkontüren werden getauscht?',
      lift_and_slide_doors_number: 'Wie viele Hebeschiebetüren soll es geben?',
      heating_system_for_renovation: {
        name: 'Welches Heizsystem möchtest Du in Zukunft verwenden?',
        hot_water_with_new_heating_system: 'Soll das Warmwasser mit dem neuen Heizsystem erzeugt werden?',
        hot_water_with_new_heating_system_description: 'Warmwasserproduktion erfolgt durch die neue Heizanlage',
        district: 'Fernwärme',
        gas: 'Gasheizung',
        pellet: 'Pelletsheizung',
        heat_pump: 'Wärmepumpe',
      },
      roof: {
        name: 'Welche Dachform hat das Haus?',
        gable: 'Satteldach',
        flat: 'Flachdach',
        hipped: 'Walmdach',
        gable_description: 'Kalkuliert wird die Fläche eines durchschnittlich steilen Satteldachs ohne Gaube.',
        flat_description: 'Kalkuliert wird die Fläche eines Flachdachs mit geringem Überstand.',
        hipped_description: 'Kalkuliert wird die Fläche eines durchschnittlich steilen Walmdachs ohne Gaube.',
        area: 'Geschätzte Dachfläche (m²) - Du kannst den Wert anpassen.',
      },
      facade_insulation: {
        type: {
          name: 'Welche Anbausituation liegt vor?',
          single_house: 'Freistehend',
          double_house: 'Doppelhaus',
          middle_house: 'Mittelhaus',
          single_house_description: 'Alle Seiten des Hauses müssen gedämmt werden.',
          double_house_description: 'Nur 3 Seiten des Hauses müssen gedämmt werden',
          middle_house_description: 'Nur 2 Seiten des Hauses müssen gedämmt werden',
        },
        area: 'Geschätzte Fassadenfläche (m²) - Du kannst den Wert anpassen.',
      },
      heating_consumption: 'Heizwärmebedarf',
      electricity_consumption: 'Stromverbrauch',
      house_area: 'Wohnnutzfläche (m²)',
      insulation_top_ceiling_area: 'Fläche der obersten Geschossdecke in m²',
      insulation_basement_ceiling_area: 'Fläche der Kellerdecke in m²',
      sanitary_renovation_area: 'Wie viel m² haben die neuen Sanitärbereiche insgesamt?',
      fifthStep: {
        title: 'Auswahl der gewünschten Sanierungsmaßnahmen',
        description:
          'In diesem Schritt erfolgt eine Vorauswahl der gewünschten Sanierungsmaßnahmen. Im nächsten Schritt berechnen wir die Kosten sowie die Auswirkungen auf den Energieverbrauch bzw. die CO2-Emissionen, und Du hast nochmals die Gelegenheit die Planung zu finalisieren.',
      },
    },
    requestPdfModal: {
      modalHeader: 'Jetzt kostenlosen dummy-Ergebnisbericht inkl. Wertsteigerung erhalten!',
      projectImplementationStartPoint: {
        label: 'Wann möchtest Du mit der Umsetzung starten?',
        options: {
          withinThreeMonths: 'Innerhalb von 3 Monaten',
          inThreeToSixMonths: 'In 3 bis 6 Monaten',
          laterThanInSixMonths: 'Später als in 6 Monaten',
        },
      },
      requestPdfBtn: 'Ergebnisbericht erhalten',
      submitFormBtn: 'Jetzt Ergebnisbericht erhalten',
      confirmationText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat commodo magna porttitor consequat. Nullam ex nisi, tincidunt in dolor ut, maximus ultrices orci. <br /><br />Donec euismod placerat sapien et commodo. Aenean in magna vestibulum nulla iaculis gravida. Suspendisse bibendum tellus eu tellus hendrerit faucibus. Donec porttitor felis velit, vitae congue enim euismod pellentesque. Ut porta pretium neque, et vulputate ligula porttitor vitae.',
    },
    plan: {
      title: 'Dein Sanierungsprojekt „{{projectName}}“',
      total: 'Gesamt',
      ourPartner: 'Unser Partner',
      totalCosts: 'Kosten und nutzen der Sanierungsmaßnahmen',
      costsAndSavingsOfMeasures: 'Kosten und Einsparungen einzelner Sanierungsmaßnahmen',
      completePreviousStep: `Du hast noch keine Planung durchgeführt`,
      completePreviousStepBtn: 'Projekt berechnen',
      backToCalculator: 'Zurück zum Rechner',
      successMessage: 'Der Projektbericht wurde an die angegebene Mailadresse versendet!',
      recalculateProjectBtn: 'Projekt neu berechnen',
      pdfCTASection: {
        description:
          'Wir senden gerne eine Zusammenfassung der Ergebnisse an Deine E-Mail-Adresse. Damit kannst Du direkt mit einem Immobilien-Profi die nächsten Schritte planen.',
      },
      getAppointmentSection: {
        title: 'Nutze Deine Chance',
        message:
          'Du kannst die Energie-Bilanz Deiner Immobilie verbessern, ihren Wert steigern und etwas Gutes für die Umwelt tun! Vereinbare einen ersten kostenlosen Telefontermin mit unseren Immobilien-Profis bei Der Sparkasse Bremen und erfahre mehr dazu!',
        buttonLabel: 'Telefontermin vereinbaren',
      },
      totalSavingsPotentialSection: {
        title: 'Gesamtes Einsparungspotential',
        text: 'Durch die Umsetzung Deiner Maßnahmen kannst Du {{interest}} % der bisherigen Energiekosten einsparen. Ein Energie-Effizienz-Experte kann Dir eventuell weitere Möglichkeiten aufzeigen. Unsere Partner von 42watt helfen hier gerne!',
      },
      renovationPerspective: {
        title: 'Was hast du in 20 Jahren nach den Sanierungsmassnahmen erreicht?',
        energyCosts: 'Gesamte Einsparung der Energiekosten',
        energyConsumption: 'Gesamte Einsparung des Energieverbrauchs',
        co2Emissions: 'Gesamte Einsparung des CO2-Ausstroßes',
        renovationBenefits: 'Gesamte Wertsteigerung Deiner Immobilie',
      },
      chartLabel: {
        totalCosts: 'Gesamtkosten',
        costSavings: 'Einsparungen/a',
        co2Savings: 'CO2 Einsparungen',
      },
      tableOverview: {
        headers: {
          category: 'Maßnahme',
          price: 'Kosten',
          co2Savings: 'CO2 Einsparung',
          savingEnergyCosts: 'Einsparung Energiekosten',
        },
        perYear: 'pro Jahr',
        noReduction: 'Keine Einsparung, da durch vorangegangene Sanierung bereits der höchste Standard erreicht wurde',
        noSavings: 'Keine Einsparung',
        roofSavingsArePartOfTopCeiling:
          'Einsparungen durch die Dachsanierung sind bereits Teil der Dämmung der obersten Geschossdecke',
        batteryStorage: {
          noCO2Savings: 'Keine Veränderung der CO2-Bilanz, da kein zusätzlicher Strom produziert wird',
          noSavingEnergyCosts: 'Die Reduktion ist bei den Einsparungen durch das PV-System berücksichtigt',
        },
      },
    },
    dbcPartnerSection: {
      title: 'Kompletten Ergebnisbericht erhalten!',
      description: `
        <ul>
          <li>Kostenloser PDF-Ergebnisbericht zum Teilen</li>
          <li>Anschauliche Diagramme</li>
          <li>Zusätzliche Erläuterungen</li>
        </ul>
        <p>Im Anschluss kannst Du direkt eine unverbindliche Beratung zur Finanzierungsanfrage vereinbaren.</p>
        `,
      btnLabel: 'Ergebnisbericht erhalten',
    },
    dbcPartnerExtraSection: {
      title: 'Die Wertsteigerung Deiner Immobilie erfahren!',
      description:
        'Lade den Ergebnisbericht herunter und erfahre, wie der Wert Deiner Immobilie direkt nach der Umsetzung der Sanierungsmaßnahmen steigt.',
      btnLabel: 'Ergebnisbericht erhalten',
    },
    fortyTwoWattSection: {
      title: 'Energieberater:in gesucht? Unser Tipp: 42watt',
      description:
        'Die unabhängigen Expert:innen erstellen einen individuellen Sanierungsfahrplan für Dich. Schnell, digital, unkompliziert.',
      btnLabel: 'Zur 42watt Website',
    },
    equipmentTypes: {
      superior: 'Premium***',
      premium: 'Gehoben**',
      standard: 'Standard*',
      mixed: 'unterschiedliche',
    },
    projectFeatures: {
      battery_storage: 'Batteriespeicher',
      battery_storage_description:
        'Batteriespeicher haben in Verbindung mit einer PV-Anlage den Zweck, den selbsterzeugten Strom zwischenspeichern zu können. Mit einer intelligenten Speicherlösungen kann man seinen eigenen Sonnenstrom auch dann verbrauchen, wenn die Sonne nicht scheint, sonst muss der erzeugte Strom entweder sofort verbraucht oder ins Netz eingespeist werden. ',
      battery_storage_size: 'Größe des Batteriespeichers (kWh)',
      battery_storage_size_description:
        'Als Faustregel gilt, dass die Kapazität des Batteriespeichers in kWh in etwa der Peakleistung (kWp) der PV-Anlage entspricht.',
      battery_storage_blackout_option: 'Willst Du auch dass Deine Anlage notstromfähig ist?',
      battery_storage_blackout_option_description:
        'Die Versorgung mit Strom aus dem Stromspeicher ohne das öffentliche Netz ist aus Sicherheitsgründen nicht möglich, da bei Netzausfall der Wechselrichter sofort ausgeschaltet wird. Nur durch einen zusätzlichen Notstromschalter kann der Stromspeicher weiterhin Strom an die Verbraucher im Haus liefern.',
      solar_heating: 'Solarthermieanlage',
      solar_heating_description:
        'Die Solarthermie dient der Warmwasseraufbereitung und speichert dieses in einem Boiler.',
      solar_heating_equipment_type_description:
        'Die <b>Standardvariante</b> dient für Warmwasser, in den teureren Kategorien dient die Anlage auch für Heizunterstützung und verfügt über hochwertigere Komponenten.',
      domestic_ventilation: 'Aktive Wohnraumlüftung',
      domestic_ventilation_description:
        'Eine aktive Wohnraumlüftung sorgt durch Luftein- und -auslässe für einen automatischen und energieeffizienten Luftaustausch.',
      domestic_ventilation_equipment_type_description:
        'Unterschiede ergeben sich in der Steuerungsmöglichkeit, den Filtern und der Qualität der Komponenten.',
      insulation_top_ceiling: 'Dämmung der obersten Geschossdecke',
      insulation_top_ceiling_description:
        'Eine kostengünstigere Variante anstelle der Dachstuhlsanierung berücksichtigt lediglich das Anbringen eines Dämmstoffs auf die oberste Geschoßdecke.',
      insulation_top_ceiling_equipment_type_description:
        '<b>Standardausführung</b> sind lose verlegte Dämmplatten mit beschränkter Begehbarkeit. In den höheren Kategorien kommen trittfeste und nachhaltig produzierte Dämmplatten zum Einsatz.',
      insulation_basement_ceiling: 'Dämmung der Kellerdecke',
      insulation_basement_ceiling_description:
        'Bei einem unbeheizten Keller sollte die Decke gut gedämmt werden um Wärmeverluste zu minimieren.',
      insulation_basement_ceiling_equipment_type_description:
        'Unterschiede bestehen in der Qualität und (nachhaltigen) Ausführung der Dämmung.',
      front_door: 'Eingangstüre erneuern',
      front_door_description: 'Die Grundlage ist eine moderne Sicherheitstüre inklusive Einbau.',
      front_door_equipment_type_description:
        'Unterschiede ergeben sich in der Größe und Ausführung der Türe (Material und Technik wie Fingerprint-Sensor).',
      new_windows: 'Fenstertausch',
      new_windows_description:
        'Preistreiber sind hier vor allem die Größe der Fenster, die Ausführung (Kunstoff, Holz oder Holz/Alu) sowie Zusatzausstattungen wie z.B. Sonnenschutz, Absturzsicherung, etc.',
      new_windows_equipment_type_description:
        'Die <b>Standardvariante</b> beinhaltet die Entsorgung der alten Fenster und den Einbau günstiger Kunststofffenster. Bei der <b>gehobenen Variante</b> handelt es sich um höherwertige Kunststoff-Aluminiumfenster und teilweisen Sonnenschutz. Die <b>Premiumvariante</b> enthält hochwertige Holz-Alufenster mit durchgängiger Beschattung, welche zusätzlich per App (Smartphone, etc.) gesteuert werden kann.',
      heating_system: 'Heizung erneuern (vorzugsweise Wärmepumpe oder Fernwärme)',
      heating_system_description:
        'Sollte ein Anschluss an ein Fernwärmenetz nicht möglich sein, ist in fast allen Fällen eine Wärmepumpe im Hinblick auf Kosteneinsparungen und CO2-Reduktion die beste Lösung. In vielen Fällen ist dies auch im Altbestand ohne weitere Dämmmaßnahmen möglich - mit einer Energieberatung VorOrt lässt sich das rasch klären!',
      heating_system_equipment_type_description:
        'Bei der <b>Standardvariante</b> wird nur die Heizung inklusive aller notwendigen Pumpen getauscht. Die <b>gehobene Variante</b> beinhaltet zusätzlich zur Heizung auch neue Heizkörper und eine moderne Steuerung. Bei der <b>Premiumvariante</b> sind die Kosten für den (teilweisen) Umstieg auf eine Fußbodenheizung ebenfalls berücksichtigt, zusätzlich ist die Anlage via App (Smartphone, etc.) steuerbar.',
      roof: 'Dach erneuern und dämmen',
      roof_description:
        'Die Grundlage ist die Entfernung der alten Dacheindeckung und eine neue Dacheindeckung inklusive Dämmung auf Basis des bestehenden Dachstuhls.',
      roof_equipment_type_description:
        'Die <b>Standardvariante</b> beinhaltet die Erneuerung der Dacheindeckung inklusive einer Dämmung auf Basis des bestehenden Dachstuhls. Bei der <b>gehobenen Variante</b> ist eine neue Dachunterschicht sowie die Erneuerung aller Spenglerarbeiten zusätzlich berücksichtigt. Die <b>Premiumvariante</b> beinhaltet einen kompletten Tausch des bestehenden Dachstuhls inklusive Dämmung und wohnlicher Innenausführung.',
      facade_insulation: 'Fassade dämmen',
      facade_insulation_description:
        'Die Grundlage ist die Dämmung der Außenwände, die Fassade erhält einen neuen Außenputz. Bitte beachte, dass ein allfälliger Tausch der Fenster ebenfalls eingeplant werden sollte.',
      facade_insulation_equipment_type_description:
        'Bei der <b>Standardvariante</b> werden kostengünstige Dämm- und Putzmaterialien sowie einfache Fensterbänke verwendet. Die <b>gehobene Variante</b> inkludiert höherwertige Materialien (Dämmung, Außenputz, Fensterbänke). Bei der <b>Premiumvariante</b> werden ökologische Dämmstoffe (z.B. Hanf) mit hochwertigem Außenputz und Fensterbänken kombiniert. Die Dicke der Dämmung wird wie folgt kalkuliert: Standard mit 10cm/ Gehoben 16cm/ Premium 20cm - Dein Baumeister/Architekt/Energieberater informiert Dich gerne über die notwendige Dämmstärke.',
      solar_power_system: 'Photovoltaik',
      solar_power_system_description:
        'Eine PV-Anlage macht ein Stück weit unabhängig. Oft gibt es dafür auch öffentliche Förderungen - informiere Dich! ',
      wallbox_e_mobility: 'Ladestation für E-Auto - Wallbox',
      wallbox_e_mobility_description:
        'Um den Neubau zukunftssicher zu gestalten, sollte eine Wallbox immer in der Planung Berücksichtigung finden.',
      wallbox_e_mobility_equipment_type_description:
        'In der Kategorie <>Standard</> wird eine hochwertige Wallbox mit Installation durch einen Fachelektriker geplant. Die Grundlage ist hier die Verwendung der bestehenden Verkabelung, was bedeutet dass oft nur eine Ladeleistung von 3,7kW möglich ist. In den höheren Kategorien wird eine zusätzliche Verkabelung, und eine Wallbox mit Integrationsmöglichkeit in eine PV-Anlage kalkuliert, damit ist eine Ladeleistung von bis zu 11kW möglich.',
    },
    pageTitles: {
      createProject: 'Dein digitaler Energieberater',
      plan: 'Dein Einsparpotenzial durch die Sanierung',
    },
  },
};

export const backendErrors = {
  disabled_origin: 'You can not open the calculator',
  access_denied: 'Zugriff verweigert',
  auth: {
    not_authorized: 'Nicht berechtigt',
  },
  project: {
    limit_of_projects: 'Mehr als 10 Projekte sind nicht möglich',
    expired: 'Projekt abgelaufen',
  },
};
