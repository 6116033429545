import React from 'react';
import { useFinalStepContext } from '../FinalTab/FinalStepContext';
import { FastField, Form, useFormikContext } from 'formik';
import NumberInput from 'components/inputs/NumberInput';
import { useTranslation } from 'react-i18next';
import s from './BreakEvenPointForm.module.scss';
import { formatCurrency } from 'utils/helpers';
import { round } from 'lodash';
import { FakeInput, FakeInputVariant, EnergyCostsFields } from './BreakEvenPointForm.components';

export interface BreakEvenPointFormValues {
  governmentFunding: number;
  equity: number;
  period: number;
  interestRate: number;
}

interface BreakEvenPointFormParams {
  monthlyRate: number;
  loanAmount: number;
}

const BreakEvenPointForm: React.FunctionComponent<BreakEvenPointFormParams> = ({ loanAmount, monthlyRate }) => {
  const { project } = useFinalStepContext();
  const { t } = useTranslation();
  const { values } = useFormikContext<BreakEvenPointFormValues>();

  return (
    <section className={s.section}>
      <Form>
        <h3 className="mt-0 mb-4">{t('breakEvenPointTracker.form.financingRequirement')}</h3>
        <FakeInput
          leftValue={t('breakEvenPointTracker.form.totalRenovationCosts')}
          rightValue={formatCurrency(project.price)}
          variant={FakeInputVariant.white}
          className="mb-4"
        />
        <FastField
          name="governmentFunding"
          component={NumberInput}
          max={project.price - values.equity || 0}
          prefix="€"
          endText={`${values.governmentFunding ? round((values.governmentFunding / project.price) * 100, 1) : 0} %`}
          label={t('breakEvenPointTracker.form.governmentFunding')}
        />
        <FastField
          name="equity"
          component={NumberInput}
          max={project.price - values.governmentFunding || 0}
          prefix="€"
          label={t('breakEvenPointTracker.form.equity')}
        />
        <FakeInput
          leftValue={t('breakEvenPointTracker.form.financingRequirement')}
          rightValue={formatCurrency(loanAmount)}
          variant={FakeInputVariant.white}
          className="mb-4"
        />
        <h3 className="mt-0 mb-4">{t('breakEvenPointTracker.form.financing')}</h3>
        <div style={{ display: 'flex', gap: '8px' }}>
          <FastField
            name="period"
            component={NumberInput}
            max={35}
            suffix=" Jahre"
            label={t('breakEvenPointTracker.form.period')}
          />
          <FastField
            name="interestRate"
            component={NumberInput}
            max={100}
            suffix=" %"
            label={t('breakEvenPointTracker.form.interestRate')}
          />
        </div>
        <FakeInput
          bold={false}
          variant={FakeInputVariant.white}
          label={t('breakEvenPointTracker.form.monthlyRate')}
          leftValue={formatCurrency(monthlyRate)}
          rightValue="mtl."
        />
        <EnergyCostsFields monthlyRate={monthlyRate || 0} />
      </Form>
    </section>
  );
};

export default BreakEvenPointForm;
