import React, { useCallback, useState, useEffect, useMemo, memo } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getDistance } from 'geolib';
import debounce from 'lodash/debounce';
import Loading from 'components/common/Loading';
import axios from 'axios';
import settings from 'config/config';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import cn from 'classnames';
import { isNil } from 'lodash';
import s from './LocationSearchInput.module.scss';
import { useTranslation } from 'react-i18next';
import { areEqualFields } from 'utils/form';

const LocationSearchInput = ({ field, form, label, latLngName, skipScrollingToField }) => {
  const { t } = useTranslation();
  const { value, name } = field;
  const { errors } = form;
  const [fullAddress, setFullAddress] = useState(!isNil(value) ? value : '');

  useEffect(() => {
    if (isNil(value)) form.setFieldValue(name, '');
  }, [form, name, value]);

  const onChange = useCallback((v) => {
    setFullAddress(v);
  }, []);

  const [position, setPosition] = useState(new window.google.maps.LatLng(0, 0));
  const [radius, setRadius] = useState(10000);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const f = useCallback(
    debounce(async (zip) => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zip},austria&key=${settings.googleApiKey}`,
      );
      if (response.data.status === 'OK') {
        const bounds = response.data.results[0]?.geometry?.bounds;
        if (bounds) {
          const { northeast, southwest } = bounds;
          const newLat = (northeast.lat - southwest.lat) / 2;
          const newLng = (northeast.lng - southwest.lng) / 2;

          setPosition(new window.google.maps.LatLng(southwest.lat + newLat, southwest.lng + newLng));
          const distance = getDistance(
            { latitude: northeast.lat, longitude: northeast.lng },
            { latitude: northeast.lat + newLat, longitude: northeast.lng + newLng },
          );
          setRadius(distance);
        }
      } else {
        setPosition(new window.google.maps.LatLng(0, 0));
        setRadius(1000);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    f(form.values.zip);
  }, [f, form.values.zip]);

  const onSelect = useCallback(
    async (location) => {
      try {
        form.setFieldValue(name, location);
        setFullAddress(location);
        const results = await geocodeByAddress(location);

        const latLng = await getLatLng(results[0]);
        form.setFieldValue(latLngName, latLng);

        const country = results[0]?.address_components.find(({ types }) => types.includes('country'));
        if (country) form.setFieldValue('country', country.short_name);

        const postalCode = results[0]?.address_components.find(({ types }) => types.includes('postal_code'));
        const zip = postalCode?.long_name || form.values.zip || '';
        form.setFieldValue('zip', zip);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [form, name, latLngName],
  );

  const handleOnBlur = React.useCallback(() => {
    setFullAddress(form.values.full_address || '');
  }, [form]);

  const fieldWrapperClassnames = useMemo(() => {
    let classes = 'field-wrapper';
    if (!skipScrollingToField) classes += ' scroll-to-field';
    return cn(classes, s.customFieldWrapper);
  }, [skipScrollingToField]);

  return (
    <PlacesAutocomplete
      value={fullAddress}
      onChange={onChange}
      onSelect={onSelect}
      debounce={300}
      searchOptions={{ location: position, radius, types: ['address'], componentRestrictions: { country: 'de' } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={fieldWrapperClassnames}>
          <input
            name={field.name}
            id={field.name}
            {...getInputProps({
              className: 'default-input',
              onBlur: handleOnBlur,
              autoComplete: 'none',
            })}
          />
          <label
            className={cn(fullAddress ? 'label-when-focused-input' : 'text-label')}
            htmlFor={name}
            id={`label-${name}`}
          >
            {label}
          </label>
          <div className={cn(s.suggestionItemsContainer, { [s.isActive]: !!suggestions.length })}>
            {loading && <Loading size={25} />}
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? s.suggestionItemActive : s.suggestionItem;
              return (
                <div
                  {...getSuggestionItemProps(suggestion, { className: `${className} text-left` })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
          {!!get(errors, name) && <div className="text-danger">{t(get(errors, name))}</div>}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default memo(LocationSearchInput, areEqualFields);
