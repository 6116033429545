/* eslint-disable no-undef */
import React from 'react';
import { ReactComponent as DistrictStandard } from 'assets/icons/calculator/heatingSystem/district-heating-standard.svg';
import { ReactComponent as NaturalGas } from 'assets/icons/calculator/heatingSystem/natural-gas.svg';
import { ReactComponent as HeatingPump } from 'assets/icons/calculator/heatingSystem/heating-pump.svg';
import { ReactComponent as WoodPellets } from 'assets/icons/calculator/heatingSystem/wood-pellets.svg';
import { clamp } from 'lodash';

export const handleOnChangeRoofType = ({ value, form }) => {
  const { values, setFieldValue } = form;
  const getRoofArea = (roofType) => {
    let area = 0;
    if (roofType === 'gable') {
      area = (values.house_area / values.floors_number) * 1.35 * 1.4;
    } else if (roofType === 'hipped') {
      area = (values.house_area / values.floors_number) * 1.35 * 1.5;
    } else if (roofType === 'flat') {
      area = (values.house_area / values.floors_number) * 1.35;
    }
    return Math.ceil(area);
  };
  setFieldValue('roof_area', getRoofArea(value));
};

export const handleOnChangeFacadeInsulationType = ({ value, form }) => {
  const { values, setFieldValue } = form;
  const getFacadeArea = (facadeType) => {
    let area = 0;
    if (facadeType === 'single') {
      area = Math.sqrt(values.house_area / values.floors_number) * 3.5 * 4 * values.floors_number * 1.3;
    } else if (facadeType === 'double') {
      area = Math.sqrt(values.house_area / values.floors_number) * 3.5 * 3 * values.floors_number * 1.3;
    } else if (facadeType === 'middle') {
      area = Math.sqrt(values.house_area / values.floors_number) * 3.5 * 2 * values.floors_number * 1.3;
    }
    return Math.ceil(area);
  };
  setFieldValue('facade_insulation_area', getFacadeArea(value));
};

export const handleSelectSolarPowerSystem = ({ value, form }) => {
  const { setFieldValue } = form;
  if (!value) setFieldValue('features.battery_storage', false);
};

export const sideEffectOnSolarSystemSizeChange = ({ value, form }) => {
  form.setFieldValue('battery_storage_size', value);
};

export function createEquipmentTypeSelect(options) {
  return {
    label: 'project.equipment_type',
    type: 'button_select',
    name: options.name,
    options: [
      {
        value: 'standard',
        label: 'equipmentTypes.standard',
      },
      {
        value: 'premium',
        label: 'equipmentTypes.premium',
      },
      {
        value: 'superior',
        label: 'equipmentTypes.superior',
      },
    ],
    required: true,
    nullable: false,
    ...options,
  };
}

export const getFeaturesSchema = (values) => [
  {
    id: 'de43b036-6779-4864-8e68-9c60842e1d4f',
    name: 'facade_insulation',
    label: 'projectFeatures.facade_insulation',
    description: 'projectFeatures.facade_insulation_description',
    index: 1,
    form: [
      {
        label: 'project.facade_insulation.type.name',
        type: 'image_select',
        name: 'facade_insulation_type',
        sideEffectOnChange: handleOnChangeFacadeInsulationType,
        options: [
          {
            imageSrc: require('assets/images/house-types/single.png'),
            label: 'project.facade_insulation.type.single_house',
            description: 'project.facade_insulation.type.single_house_description',
            value: 'single',
          },
          {
            imageSrc: require('assets/images/house-types/double.png'),
            label: 'project.facade_insulation.type.double_house',
            description: 'project.facade_insulation.type.double_house_description',
            value: 'double',
          },
          {
            imageSrc: require('assets/images/house-types/middle.png'),
            label: 'project.facade_insulation.type.middle_house',
            description: 'project.facade_insulation.type.middle_house_description',
            value: 'middle',
          },
        ],
        required: true,
        nullable: false,
      },
      {
        label: 'project.facade_insulation.area',
        type: 'slider_with_tooltip',
        name: 'facade_insulation_area',
        labelInTwoRows: true,
        required: true,
        step: 10,
        min: 20,
        max: 500,
        units: 'm²',
        forciblyReplaceInputValue: true,
      },
      createEquipmentTypeSelect({
        name: 'facade_insulation_equipment_type',
        description: 'projectFeatures.facade_insulation_equipment_type_description',
      }),
    ],
  },
  {
    id: '7adaaa8a-bb7e-428e-879d-2c7233a87c63',
    name: 'new_windows',
    label: 'projectFeatures.new_windows',
    description: 'projectFeatures.new_windows_description',
    index: 2,
    form: [
      {
        label: 'project.new_windows_number',
        type: 'counter',
        name: 'new_windows_number',
        required: true,
        min: 1,
        max: 20,
      },
      {
        label: 'project.extra_large_windows_number',
        type: 'counter',
        name: 'extra_large_windows_number',
        required: true,
        min: 0,
        max: 20,
      },
      {
        label: 'project.lift_and_slide_doors_number',
        name: 'lift_and_slide_doors_number',
        type: 'counter',
        min: 0,
        max: 5,
      },
      createEquipmentTypeSelect({
        name: 'new_windows_equipment_type',
        description: 'projectFeatures.new_windows_equipment_type_description',
      }),
    ],
  },
  {
    id: '235bc892-a296-4795-bb06-666cdfaa9dc',
    name: 'roof',
    label: 'projectFeatures.roof',
    description: 'projectFeatures.roof_description',
    index: 3,
    form: [
      {
        label: 'project.roof.name',
        type: 'image_select',
        name: 'roof_type',
        sideEffectOnChange: handleOnChangeRoofType,
        options: [
          {
            imageSrc: require('assets/images/roof/gable.png'),
            label: 'project.roof.gable',
            description: 'project.roof.gable_description',
            value: 'gable',
          },
          {
            imageSrc: require('assets/images/roof/flat.png'),
            label: 'project.roof.flat',
            description: 'project.roof.flat_description',
            value: 'flat',
          },
          {
            imageSrc: require('assets/images/roof/hipped.png'),
            label: 'project.roof.hipped',
            description: 'project.roof.hipped_description',
            value: 'hipped',
          },
        ],
        required: true,
        nullable: false,
      },
      {
        label: 'project.roof.area',
        type: 'slider_with_tooltip',
        name: 'roof_area',
        required: true,
        labelInTwoRows: true,
        min: 30,
        max: 300,
        step: 10,
        units: 'm²',
        forciblyReplaceInputValue: true,
      },
      createEquipmentTypeSelect({
        name: 'roof_equipment_type',
        description: 'projectFeatures.roof_equipment_type_description',
      }),
    ],
  },
  {
    id: 'dcc72e7d-d445-46ea-983e-5145dae4348d',
    name: 'insulation_top_ceiling',
    label: 'projectFeatures.insulation_top_ceiling',
    description: 'projectFeatures.insulation_top_ceiling_description',
    index: 4,
    form: [
      {
        label: 'project.insulation_top_ceiling_area',
        type: 'slider_with_tooltip',
        name: 'insulation_top_ceiling_area',
        labelInTwoRows: true,
        required: true,
        default_value_formula: 'Math.ceil(values.house_area / values.floors_number)',
        min: 25,
        max: 300,
        step: 5,
        units: 'm²',
      },
    ],
  },
  {
    id: 'e9b709ac-78f4-4732-bc95-dc41255f4071',
    name: 'insulation_basement_ceiling',
    label: 'projectFeatures.insulation_basement_ceiling',
    description: 'projectFeatures.insulation_basement_ceiling_description',
    index: 5,
    form: [
      {
        label: 'project.insulation_basement_ceiling_area',
        type: 'slider_with_tooltip',
        name: 'insulation_basement_ceiling_area',
        default_value_formula: 'Math.ceil(values.house_area / values.floors_number)',
        required: true,
        min: 27,
        max: 300,
        step: 3,
        units: 'm²',
      },
    ],
  },
  {
    id: '53c6335f-349c-4375-b688-5e712097bb7d',
    name: 'heating_system',
    label: 'projectFeatures.heating_system',
    description: 'projectFeatures.heating_system_description',
    index: values && !['heat_pump', 'district_heating_standard'].includes(values.heating_system) ? 0 : 6,
    highlighted: !values ? false : !['heat_pump', 'district_heating_standard'].includes(values.heating_system),
    form: [
      {
        label: 'project.heating_system_for_renovation.name',
        type: 'button_select',
        name: 'heating_system_for_renovation',
        contentInColumn: true,
        inOneRow: false,
        labelInTwoRows: true,
        options: [
          {
            icon: <HeatingPump className="fill" />,
            buttonStyles: { minWidth: '40%' },
            value: 'heat_pump',
            label: 'project.heating_system_for_renovation.heat_pump',
          },
          {
            icon: <DistrictStandard className="fill" />,
            buttonStyles: { minWidth: '40%' },
            value: 'district_heating_standard',
            label: 'project.heating_system_for_renovation.district',
          },
          {
            icon: <WoodPellets className="fill" style={{ width: '40px', height: '40px' }} />,
            buttonStyles: { minWidth: 'initial' },
            value: 'wood_pellets',
            label: 'project.heating_system_for_renovation.pellet',
          },
          {
            icon: <NaturalGas className="fill" style={{ width: '40px', height: '40px' }} />,
            buttonStyles: { minWidth: 'initial' },
            value: 'natural_gas',
            label: 'project.heating_system_for_renovation.gas',
          },
        ],
        required: true,
        nullable: false,
      },
      {
        name: 'hot_water_with_new_heating_system',
        label: 'project.heating_system_for_renovation.hot_water_with_new_heating_system',
        description: 'project.heating_system_for_renovation.hot_water_with_new_heating_system_description',
        type: 'checkbox',
      },
      createEquipmentTypeSelect({
        name: 'heating_system_equipment_type',
        description: 'projectFeatures.heating_system_equipment_type_description',
      }),
    ],
  },
  {
    id: '16d4b0eb-596c-4be2-87eb-b613a96c268d',
    name: 'solar_power_system',
    label: 'projectFeatures.solar_power_system',
    description: 'projectFeatures.solar_power_system_description',
    sideEffectOnChange: handleSelectSolarPowerSystem,
    index: 7,
    form: [
      {
        label: 'project.solar_power_system_size_installation',
        type: 'counter',
        name: 'solar_power_system_size_installation',
        min: 3,
        max: 15,
        description: 'project.solar_power_system_size_installation_description',
        required: true,
        sideEffectOnChange: sideEffectOnSolarSystemSizeChange,
      },
      {
        type: 'image_select',
        name: 'solar_power_system_equipment_type',
        label: 'project.solar_power_system_equipment_type.name',
        options: [
          {
            imageSrc: require('assets/images/pv_standard.png'),
            label: 'equipmentTypes.standard',
            description: 'project.solar_power_system_equipment_type.standard_description',
            value: 'standard',
          },
          {
            imageSrc: require('assets/images/pv_premium.png'),
            label: 'equipmentTypes.premium',
            description: 'project.solar_power_system_equipment_type.premium_description',
            value: 'premium',
          },
          {
            imageSrc: require('assets/images/pv_superior.png'),
            label: 'equipmentTypes.superior',
            description: 'project.solar_power_system_equipment_type.superior_description',
            value: 'superior',
          },
        ],
        required: true,
      },
    ],
  },
  {
    id: 'd45f0e40-e766-482e-a622-7df356bb85e8',
    name: 'battery_storage',
    label: 'projectFeatures.battery_storage',
    description: 'projectFeatures.battery_storage_description',
    requiredCategory: 'solar_power_system',
    index: 8,
    form: [
      {
        label: 'projectFeatures.battery_storage_size',
        type: 'counter',
        name: 'battery_storage_size',
        dependencyFieldName: 'solar_power_system_size_installation',
        calculatedMin: (v) => clamp(v - 5, 3, 15),
        calculatedMax: (v) => clamp(v + 5, 3, 15),
        description: 'projectFeatures.battery_storage_size_description',
        required: true,
      },
      {
        label: 'projectFeatures.battery_storage_blackout_option',
        type: 'checkbox',
        name: 'battery_storage_blackout_option',
        description: 'projectFeatures.battery_storage_blackout_option_description',
        style: { marginLeft: '1cm' },
      },
    ],
  },
  {
    id: '77d30980-c195-4ece-a6a2-76aff351dcb1',
    name: 'solar_heating',
    label: 'projectFeatures.solar_heating',
    description: 'projectFeatures.solar_heating_description',
    index: 9,
    form: [],
  },
  {
    id: 'b90f5301-a816-48d8-ad84-85b3a2eba0d4',
    name: 'domestic_ventilation',
    label: 'projectFeatures.domestic_ventilation',
    description: 'projectFeatures.domestic_ventilation_description',
    index: 10,
    form: [
      createEquipmentTypeSelect({
        name: 'domestic_ventilation_equipment_type',
        description: 'projectFeatures.domestic_ventilation_equipment_type_description',
      }),
    ],
  },
  {
    id: 'ba05c7b3-05ec-4c5d-acd9-f95d6bd70a7c',
    name: 'front_door',
    label: 'projectFeatures.front_door',
    description: 'projectFeatures.front_door_description',
    index: 11,
    form: [
      createEquipmentTypeSelect({
        name: 'front_door_equipment_type',
        description: 'projectFeatures.front_door_equipment_type_description',
      }),
    ],
  },
  {
    id: 'f5226dae-1fb5-4d1b-9ae5-343f8ebb93c6',
    name: 'wallbox_e_mobility',
    label: 'projectFeatures.wallbox_e_mobility',
    description: 'projectFeatures.wallbox_e_mobility_description',
    index: 12,
    form: [],
  },
];
