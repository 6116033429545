import { isMobile } from 'react-device-detect';

export default {
  xl: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            minWidth: '800px',
            maxWidth: '900px',
          }),
    },
  },
  lg: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            minWidth: '60px',
            maxWidth: '700px',
          }),
    },
  },
  md: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            width: '40%',
            minWidth: '550px',
          }),
    },
  },
  sm: {
    content: {
      ...(isMobile
        ? {
            width: '100%',
          }
        : {
            width: '30%',
            minWidth: '400px',
          }),
    },
  },
};
