import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFinalStepContext } from '../FinalTab/FinalStepContext';
import s from './BreakEvenPointForm.components.module.scss';
import { formatCurrency } from 'utils/helpers';
import cn from 'classnames';

export enum FakeInputVariant {
  white = 'white',
  primary = 'primary',
  secondary = 'secondary',
}

const variantToCn = {
  [FakeInputVariant.white]: s.fakeInput,
  [FakeInputVariant.primary]: s.primaryFakeInput,
  [FakeInputVariant.secondary]: s.secondaryFakeInput,
};

export const FakeInput = ({
  label,
  rightValue,
  leftValue,
  variant,
  bold = true,
  className,
}: {
  label?: string;
  bold?: boolean;
  rightValue: string;
  leftValue: string;
  variant: FakeInputVariant;
  className?: string;
}) => {
  const Input = (
    <div className={cn(variantToCn[variant], className)}>
      <span className={bold ? 'bold' : ''}>{leftValue}</span>
      <span className={bold ? 'bold' : ''}>{rightValue}</span>
    </div>
  );

  if (!label) return Input;

  return (
    <div className={s.fakeInputWrapper}>
      {label ? <label className={s.label}>{label}</label> : null}
      {Input}
    </div>
  );
};

export const EnergyCostsFields = ({ monthlyRate }: { monthlyRate: number }) => {
  const { t } = useTranslation();
  const { ecoTrackerItems = [] } = useFinalStepContext();
  const ecoTrackerItem = useMemo(() => ecoTrackerItems.find(({ key }) => key === 'costs'), [ecoTrackerItems]);

  const potentialMonthlyValue = useMemo(() => {
    return Math.floor((ecoTrackerItem?.potentialValue || 0) / 12);
  }, [ecoTrackerItem?.potentialValue]);

  const totalMonthlyValue = useMemo(() => {
    return potentialMonthlyValue + Math.floor(monthlyRate);
  }, [monthlyRate, potentialMonthlyValue]);

  if (!ecoTrackerItem) return null;

  return (
    <>
      <h3 className="mt-0 mb-4">{t('breakEvenPointTracker.form.energyCosts')}</h3>
      <div style={{ display: 'flex', gap: '8px' }}>
        <FakeInput
          bold={false}
          variant={FakeInputVariant.white}
          label={t('breakEvenPointTracker.form.new')}
          leftValue={formatCurrency(potentialMonthlyValue)}
          rightValue="mtl."
        />
        <FakeInput
          bold={false}
          variant={FakeInputVariant.white}
          label={t('breakEvenPointTracker.form.old')}
          leftValue={formatCurrency(ecoTrackerItem.actualValue / 12)}
          rightValue="mtl."
        />
      </div>
      <FakeInput
        variant={FakeInputVariant.secondary}
        leftValue={t('breakEvenPointTracker.form.totalMonthly')}
        rightValue={formatCurrency(totalMonthlyValue)}
      />
    </>
  );
};
