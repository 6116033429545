import { gql } from '@apollo/client';

export const CUSTOM_ENERGY_REQUIREMENTS = gql`
  fragment customEnergyRequirements on CustomEnergyRequirements {
    coal {
      user_value
      energy
      costs
      ghg
    }
    heating_oil {
      user_value
      energy
      costs
      ghg
    }
    natural_gas {
      user_value
      energy
      costs
      ghg
    }
    firewood {
      user_value
      energy
      costs
      ghg
    }
    district_heating {
      user_value
      energy
      costs
      ghg
    }
    wood_chips {
      user_value
      energy
      costs
      ghg
    }
    wood_pellets {
      user_value
      energy
      costs
      ghg
    }
    electricity_used {
      user_value
      energy
      costs
      ghg
    }
  }
`;
