import React, { useCallback, useMemo } from 'react';
import { Field } from 'formik';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ButtonSelect from 'components/inputs/ButtonSelect';
import Timeline from 'components/inputs/Timeline';
import { ReactComponent as SingleStandardHouse } from 'assets/icons/calculator/kindOfHouse/single.svg';
import { ReactComponent as SemiDetachedHouse } from 'assets/icons/calculator/kindOfHouse/semi-detached.svg';
import { ReactComponent as Bungalow } from 'assets/icons/calculator/kindOfHouse/bungalow.svg';
import { ReactComponent as TownHouse } from 'assets/icons/calculator/kindOfHouse/town-house.svg';
import { ReactComponent as OnePeople } from 'assets/icons/calculator/peoples/one-people.svg';
import { ReactComponent as TwoPeople } from 'assets/icons/calculator/peoples/two-people.svg';
import { ReactComponent as ThreePeople } from 'assets/icons/calculator/peoples/three-people.svg';
import { ReactComponent as FourPeople } from 'assets/icons/calculator/peoples/four-people.svg';
import { ReactComponent as FivePeople } from 'assets/icons/calculator/peoples/five-people.svg';
import { ReactComponent as SixPeople } from 'assets/icons/calculator/peoples/six-people.svg';
import { ReactComponent as NewWindows } from 'assets/icons/calculator/renovations/new-windows.svg';
import { ReactComponent as InsulationTop } from 'assets/icons/calculator/renovations/insulation-top-ceiling.svg';
import { ReactComponent as InsulationBasement } from 'assets/icons/calculator/renovations/insulation-basement-ceiling.svg';
import { ReactComponent as InsulationFacade } from 'assets/icons/calculator/renovations/insulation-facade.svg';
import { ReactComponent as Ventilation } from 'assets/icons/calculator/renovations/ventilation.svg';
import { ReactComponent as NoneIcon } from 'assets/icons/none.svg';
import { Trans } from 'react-i18next';

const FirstSection = ({ values, initialValues, t, isEditMode, goToThirdStep }) => {
  const changeRenovationsHandler = useCallback((form, value) => {
    if (!value.length) return;
    if (value.includes('none')) {
      form.setFieldValue('renovations', ['none']);
    }
  }, []);

  const isRenovationsDisabled = useMemo(() => values.renovations.includes('none'), [values.renovations]);

  return (
    <>
      <h2 className="text-primary">{t('co2CalculatorForm.moreInfoAboutHouse')}</h2>
      <Field
        name="heated_living_area"
        component={SliderWithTooltip}
        label={t('co2CalculatorForm.heated_living_area')}
        min={25}
        defaultValue={110}
        units="m²"
        max={initialValues?.house_area || 300}
        description={
          isEditMode ? (
            <Trans
              i18nKey="co2CalculatorForm.heated_living_area_disclaimer"
              components={{
                linkToThirdStep: (
                  <button type="button" onClick={goToThirdStep} className="link" key="1">
                    {t('co2CalculatorForm.link_to_the_third_step')}
                  </button>
                ),
              }}
            />
          ) : null
        }
      />

      <Field
        name="kind_of_house"
        component={ButtonSelect}
        contentInColumn
        inOneRow={false}
        label={t('co2CalculatorForm.kind_of_house.name')}
        options={[
          {
            icon: <SingleStandardHouse className="fill" />,
            label: t('co2CalculatorForm.kind_of_house.single_family_house'),
            value: 'single_family_house',
          },
          {
            icon: <Bungalow className="fill" />,
            label: t('co2CalculatorForm.kind_of_house.bungalow_or_complex_floor_plan'),
            value: 'bungalow_or_complex_floor_plan',
          },
          {
            icon: <TownHouse className="fill" />,
            label: t('co2CalculatorForm.kind_of_house.town_house'),
            value: 'town_house',
          },
          {
            icon: <SemiDetachedHouse className="fill" />,
            label: t('co2CalculatorForm.kind_of_house.semi_detached_house'),
            value: 'semi_detached_house',
          },
        ]}
      />
      <Field
        name="energy_standard"
        component={Timeline}
        label={t('co2CalculatorForm.energy_standard.name')}
        detailedInfo={t('co2CalculatorForm.energy_standard.detailed_info')}
        description={t('co2CalculatorForm.energy_standard.description')}
        options={[
          {
            label: t('co2CalculatorForm.energy_standard.before_1960'),
            value: 'before_1960',
          },
          {
            label: t('co2CalculatorForm.energy_standard.between_1960_and_1975'),
            value: 'between_1960_and_1975',
          },
          {
            label: t('co2CalculatorForm.energy_standard.between_1976_and_1990'),
            value: 'between_1976_and_1990',
          },
          {
            label: t('co2CalculatorForm.energy_standard.after_1990'),
            value: 'after_1990',
          },
          {
            label: t('co2CalculatorForm.energy_standard.low_energy_house'),
            value: 'low_energy_house',
          },
          {
            label: t('co2CalculatorForm.energy_standard.passive_house'),
            value: 'passive_house',
          },
        ]}
      />

      {!['low_energy_house', 'passive_house'].includes(values.energy_standard) ? (
        <Field
          label={t('co2CalculatorForm.renovations.name')}
          name="renovations"
          contentInColumn
          multi
          inOneRow={false}
          component={ButtonSelect}
          sideEffectOnChange={changeRenovationsHandler}
          nullable={false}
          options={[
            {
              icon: <NoneIcon className="fill" />,
              label: t('co2CalculatorForm.renovations.notRenovated'),
              value: 'none',
            },
            {
              icon: <NewWindows className="fill" />,
              label: t('co2CalculatorForm.renovations.new_windows'),
              value: 'new_windows',
              disabled: isRenovationsDisabled,
            },
            {
              icon: <InsulationTop className="fill" />,
              label: t('co2CalculatorForm.renovations.insulation_top_ceiling'),
              value: 'insulation_top_ceiling',
              disabled: isRenovationsDisabled,
            },
            {
              icon: <InsulationBasement className="fill" />,
              label: t('co2CalculatorForm.renovations.insulation_basement_ceiling'),
              value: 'insulation_basement_ceiling',
              disabled: isRenovationsDisabled,
            },
            {
              icon: <InsulationFacade className="fill" />,
              label: t('co2CalculatorForm.renovations.insulation_facade'),
              value: 'insulation_facade',
              disabled: isRenovationsDisabled,
            },
            {
              icon: <Ventilation className="fill" />,
              label: t('co2CalculatorForm.renovations.controlled_living_space_ventilation'),
              value: 'controlled_living_space_ventilation',
              disabled: isRenovationsDisabled,
            },
          ]}
        />
      ) : null}
      <Field
        name="number_of_people"
        component={ButtonSelect}
        contentInColumn
        inOneRow={false}
        options={[
          {
            icon: <OnePeople className="fill" />,
            value: 1,
          },
          {
            icon: <TwoPeople className="fill" />,
            value: 2,
          },
          {
            icon: <ThreePeople className="fill" />,
            value: 3,
          },
          {
            icon: <FourPeople className="fill" />,
            value: 4,
          },
          {
            icon: <FivePeople className="fill" />,
            value: 5,
          },
          {
            icon: <SixPeople className="fill" />,
            value: 6,
          },
        ]}
        label={t('co2CalculatorForm.number_of_people')}
      />
    </>
  );
};

export default FirstSection;
