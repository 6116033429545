import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { useTranslation } from 'react-i18next';
import routePaths from 'router/routePaths';

interface IGoHomeAlert {
  message: string;
}

const GoHomeAlert: FC<IGoHomeAlert> = ({ message }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Alert color="danger">
        <div>{message}</div>
        <div className="mt-4 text-center">
          <Button color="danger" onClick={() => navigate(routePaths.newProject)}>
            {t('button.goHome')} <Home />
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default GoHomeAlert;
