import React, { useState, useEffect, useCallback, memo } from 'react';
import axios from 'axios';
import settings from 'config/config';
import isEqual from 'lodash/isEqual';
import GoogleMap from './GoogleMap';
import s from './MapContainer.module.scss';

const MapContainer = ({ position }) => {
  const [isMap, setIsMap] = useState(!!position);

  const f = useCallback(async () => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/streetview/metadata?key=${settings.googleApiKey}&location=${position.lat},${position.lng}`,
    );
    if (response.data.status === 'OK') {
      setIsMap(false);
    }
  }, [position.lat, position.lng]);

  useEffect(() => {
    f();
  }, [f, position.lat, position.lng]);

  return <div className={s.mapContainer}>{isMap ? <GoogleMap position={position} /> : null}</div>;
};

export default memo(MapContainer, isEqual);
