import { calculateTotal } from 'components/CalculatorForm/CalculatorSecondTab';
import TableScrollButtons from 'components/common/HorizontalScrollButtons/TableScrollButtons';
import React, { useRef } from 'react';
import { Visible } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatValue } from 'utils/helpers';

interface IFuelMatrixItem {
  fuelType: string;
  userInput: number;
  energy: number;
  costs: number;
  ghg: number;
}

interface ICustomEnergyRequirementsTable {
  fuelMatrix: IFuelMatrixItem[];
}

const CustomEnergyRequirementsTable: FC<ICustomEnergyRequirementsTable> = ({ fuelMatrix }) => {
  const { t } = useTranslation();
  const tableWrapperRef = useRef<HTMLDivElement>();

  return (
    <div style={{ overflowX: 'scroll' }} ref={tableWrapperRef}>
      <table className="table grey-first">
        <thead>
          <tr>
            <th className="text-left">{t('co2CalculatorResult.customEnergyRequirementsTable.headers.fuelType')}</th>
            <th className="text-left">{t('co2CalculatorResult.customEnergyRequirementsTable.headers.userInput')}</th>
            <th className="text-left">{t('co2CalculatorResult.customEnergyRequirementsTable.headers.energy')}</th>
            <th className="text-left">{t('co2CalculatorResult.customEnergyRequirementsTable.headers.costs')}</th>
            <th className="text-left">{t('co2CalculatorResult.customEnergyRequirementsTable.headers.ghg')}</th>
          </tr>
        </thead>
        <tbody>
          {fuelMatrix.map(({ fuelType, userInput, energy, costs, ghg }: IFuelMatrixItem) => (
            <tr key={fuelType}>
              <td className="text-left">
                {t(`co2CalculatorResult.customEnergyRequirementsTable.fuelType.${fuelType}`)}
              </td>
              <td>{formatValue(userInput)}</td>
              <td>{`${formatValue(energy)} kWh`}</td>
              <td>{formatCurrency(costs)}</td>
              <td>{`${formatValue(ghg)} kg/a`}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td />
            <td />
            <td>{`${formatValue(calculateTotal(fuelMatrix, 'energy'))} kWh`}</td>
            <td>{formatCurrency(calculateTotal(fuelMatrix, 'costs'))}</td>
            <td>{`${formatValue(calculateTotal(fuelMatrix, 'ghg'))} kg/a`}</td>
          </tr>
        </tfoot>
      </table>
      <Visible xs sm>
        <TableScrollButtons {...{ tableWrapperRef }} />
      </Visible>
    </div>
  );
};

export default CustomEnergyRequirementsTable;
