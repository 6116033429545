import React, { useMemo } from 'react';
import { ReactComponent as CarIcon } from 'assets/icons/calculator/car.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/calculator/plane.svg';
import { ReactComponent as TreeIcon } from 'assets/icons/calculator/tree.svg';
import { formatValue } from 'utils/helpers';
import s from './EnergyGhgEquivalents.module.scss';
import { useTranslation } from 'react-i18next';
import { useFinalStepContext } from 'components/CalculatorForm/FinalTab/FinalStepContext';
import { get } from 'lodash';

function EnergyGhgEquivalent({ icon: Icon, label, t, value }) {
  return (
    <div className={s.equivalent}>
      <div className={s.iconWrapper}>
        <Icon />
      </div>
      <div className={s.textWrapper}>
        <b className={s.value}>{value}</b>
        <p className={s.label}>{t(label)}</p>
      </div>
    </div>
  );
}

function getEnergyEquivalents(values, t) {
  return [
    {
      key: 'energyGhgEquivalentPlane',
      value: `${formatValue(values.energyGhgEquivalentPlane)} ${t('co2CalculatorResult.energyGhgEquivalents.flights')}`,
      label: 'co2CalculatorResult.energyGhgEquivalents.equivalentPlane',
      icon: PlaneIcon,
    },
    {
      key: 'energyGhgEquivalentCar',
      value: formatValue(values.energyGhgEquivalentCar),
      label: 'co2CalculatorResult.energyGhgEquivalents.equivalentCar',
      icon: CarIcon,
    },
    {
      key: 'energyGhgEquivalentTrees',
      value: formatValue(values.energyGhgEquivalentTrees),
      label: 'co2CalculatorResult.energyGhgEquivalents.equivalentTrees',
      icon: TreeIcon,
    },
  ];
}

const EnergyGhgEquivalents = ({ co2Calculations: _co2Calculations = null, costOverviewTab = false }) => {
  const { t } = useTranslation();
  const { co2Calculations } = useFinalStepContext();
  const calculations = useMemo(() => co2Calculations || _co2Calculations, [_co2Calculations, co2Calculations]);

  const before = useMemo(() => get(calculations, 'co2_emissions_before.result', null), [calculations]);
  const after = useMemo(
    () => get(calculations, `${costOverviewTab ? 'co2_emissions_after' : 'co2_emissions_potential'}.result`, null),
    [calculations, costOverviewTab],
  );

  const equivalentValues = useMemo(() => {
    const values = { energyGhgEquivalentPlane: 0, energyGhgEquivalentCar: 0, energyGhgEquivalentTrees: 0 };
    if (before && after) {
      values.energyGhgEquivalentPlane = before.energy_ghg_equivalent_plane - after.energy_ghg_equivalent_plane;
      values.energyGhgEquivalentCar = before.energy_ghg_equivalent_car - after.energy_ghg_equivalent_car;
      values.energyGhgEquivalentTrees = before.energy_ghg_equivalent_trees - after.energy_ghg_equivalent_trees;
    }
    return values;
  }, [before, after]);

  const energyEquivalents = useMemo(() => getEnergyEquivalents(equivalentValues, t), [equivalentValues, t]);

  return (
    <section className={s.section}>
      <div className={s.bg} />
      <h2 className="subtitle">{t(`co2CalculatorResult.energyGhgEquivalents.title`)}</h2>
      {/* <p className="subtitle-description text-secondary">{t(`co2CalculatorResult.energyGhgEquivalents.details`)}</p> */}
      <div className={s.row}>
        {energyEquivalents.map((v) => (
          <div className={s.col} key={v.key}>
            <EnergyGhgEquivalent {...v} t={t} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default EnergyGhgEquivalents;
