/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import s from './CircleButton.module.scss';
import Loading from '../Loading';

const buttonThemes = {
  default: s.default,
  primary: s.primary,
  secondary: s.secondary,
  outline: s.outline,
};

export default function CircleButton({
  children,
  className,
  variant = 'default',
  disabled,
  loading,
  type = 'button',
  onClick,
  ...rest
}: any) {
  return (
    <button
      className={cn(s.circleButton, buttonThemes[variant], className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {loading ? <Loading size={25} /> : children}
    </button>
  );
}
