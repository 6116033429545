import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { ReactComponent as ArrowBack } from 'assets/icons/angle-back.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import { Col, Row } from 'react-grid-system';
import fifthStepImg from 'assets/images/fifth-step.png';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ScrollToTop from 'components/common/ScrollToTop';
import validationSchema from './yupSchemas/fifthStepSchema';
import s from './calculator.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { getFeaturesSchema } from 'constants/featuresSchema';
import Checkbox from 'components/inputs/Checkbox';
import { localizeWizard } from 'utils/helpers';
import { get, isNil } from 'lodash';
import cn from 'classnames';
import MorphField from 'components/inputs/MorphField';
import Collapsible from 'react-collapsible';
import { ReactComponent as LightningIcon } from 'assets/icons/lightning.svg';
import { ImageContainer } from 'components/common/ImageContainer';
import { useParams } from 'react-router-dom';

const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);

function getDefaultValue(field, values) {
  if (!field) return null;
  let defaultValue = !isNil(field.defaultValue) ? field.defaultValue : null;
  if (isNil(defaultValue) && field.default_value_formula) {
    defaultValue = eval(field.default_value_formula);
  }
  return defaultValue;
}

const FeatureFormFields = ({ isOpen, feature, t, values }) => (
  <Collapsible trigger={<div />} open={isOpen} transitionTime={800}>
    {feature.form.map((field) => {
      const defaultValue = getDefaultValue(field, values);
      const { units, disabled, tooltip, ...localizedProps } = field;
      return (
        <MorphField
          key={field.name}
          {...localizeWizard(localizedProps, {
            formatter: (id) => {
              if (!id) return id;
              const localizedLabel = t(id);
              if (!containsHTML(localizedLabel)) return localizedLabel;
              return <Trans i18nKey={localizedLabel} components={{ b: <b /> }} />;
            },
          })}
          {...(units ? { units } : {})}
          {...{ disabled, defaultValue, tooltip }}
        />
      );
    })}
  </Collapsible>
);

const CalculatorFifthTab = ({
  onSubmitStep,
  onSubmit,
  lastStep,
  isEditMode,
  nextStep,
  previousStep,
  formValuesRef,
  onBack,
}) => {
  const { t } = useTranslation();
  const [schema, setSchema] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    if (!schema.length) {
      const s = getFeaturesSchema(formValuesRef?.current);
      setSchema(s.sort((a, b) => a.index - b.index));
    }
  }, [formValuesRef, schema.length]);

  if (!schema.length) return null;

  return (
    <Row justify="center" className={s.fifthTab}>
      <ScrollToTop />
      <Col md={8} lg={6} order={{ xs: 1 }}>
        <h2 className={cn('subtitle', s.title)}>{t('project.fifthStep.title')}</h2>
        <p>{t('project.fifthStep.description')}</p>
        <Formik
          initialValues={formValuesRef?.current}
          onSubmit={async (values, formikActions) => {
            await onSubmit({ values, formikActions, isEditMode: true, lastStep, nextStep });
            onSubmitStep(values);
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, isSubmitting }) => (
            <Form>
              <ScrollToError />
              {schema.map((feature) => {
                const { name, label, description, requiredCategory, sideEffectOnChange, highlighted } = feature;
                const [isOpen, setIsOpen] = useState(get(values, `features.${name}`, false));

                const handleToggle = useCallback(
                  (props) => {
                    setIsOpen(props.value);
                    if (typeof sideEffectOnChange === 'function') sideEffectOnChange(props);
                  },
                  [sideEffectOnChange],
                );

                if (requiredCategory && !values.features[requiredCategory]) return null;

                return (
                  <React.Fragment key={name}>
                    <Field
                      name={`features.${name}`}
                      component={Checkbox}
                      label={t(label)}
                      icon={<LightningIcon />}
                      description={description ? t(description) : undefined}
                      sideEffectOnChange={handleToggle}
                      skipScrollingOnChange
                      highlighted={highlighted}
                    />
                    <FeatureFormFields {...{ isOpen, feature, t, values }} />
                  </React.Fragment>
                );
              })}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t('button.back')}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t(`button.${isEditMode ? 'save' : 'next'}`)}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} order={{ lg: 1 }}>
        <div className={s.stickyBox}>
          <ImageContainer src={fifthStepImg} alt="house" width="80%" />
        </div>
      </Col>
    </Row>
  );
};

export default CalculatorFifthTab;
