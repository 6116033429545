import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { CO2_CALCULATIONS } from './useCO2Calculations';

const UPDATE_CO2_CALCULATED_VALUES_MUTATION = gql`
  mutation updateCO2CalculatedValues($projectId: ID!, $input: CO2CalculatedValuesInput!) {
    updateCO2CalculatedValues(projectId: $projectId, input: $input) {
      success
    }
  }
`;

const useUpdateCO2CalculatedValues = () => {
  const [updateCO2CalculatedValuesMutation] = useMutation(UPDATE_CO2_CALCULATED_VALUES_MUTATION);

  const handleUpdate = useCallback(
    async (projectId, value) => {
      try {
        await updateCO2CalculatedValuesMutation({
          variables: { projectId, input: { ...value } },
          refetchQueries: [{ query: CO2_CALCULATIONS, variables: { projectId } }],
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        toast.error(e.message);
      }
    },
    [updateCO2CalculatedValuesMutation],
  );
  return handleUpdate;
};

export default useUpdateCO2CalculatedValues;
