import React from 'react';
import { Field } from 'formik';
import TextInput from './TextInput';
import NumberInput from './NumberInput';
import SwitchInput from './Switch';
import ButtonSelect from './ButtonSelect';
import Counter from './Counter';
import Checkbox from './Checkbox';
import SliderWithTooltip from './SliderWithTooltip';
import ImageSelect from './ImageSelect';

const MorphField = ({ style, ...field }) => {
  let customComponent;
  let styles = { ...style };
  switch (field.type) {
    case 'text':
      customComponent = TextInput;
      break;
    case 'number':
      customComponent = NumberInput;
      break;
    case 'money':
      customComponent = NumberInput;
      break;
    case 'counter':
      customComponent = Counter;
      break;
    case 'image_select':
      customComponent = ImageSelect;
      break;
    case 'switch':
      customComponent = SwitchInput;
      break;
    case 'button_select':
      customComponent = ButtonSelect;
      break;
    case 'hidden':
      customComponent = NumberInput;
      styles = { ...style, display: 'none' };
      break;
    case 'slider_with_tooltip':
      customComponent = SliderWithTooltip;
      break;
    case 'checkbox':
      customComponent = Checkbox;
      break;
    default:
      return null;
  }
  return <Field name={field.name} style={styles} component={customComponent} {...field} />;
};

export default MorphField;
