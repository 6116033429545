import flatten from 'flat';
import en from './translations/en';
import de from './translations/de';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from 'config/config';

export const enum Locales {
  en = 'en',
  de = 'de',
}

interface Translations {
  [key: string]: string;
}

const resources: Record<Locales, Translations> = {
  en: { translation: flatten(en.translation) },
  de: { translation: flatten(de.translation) },
};

export const LOCAL_STORAGE_KEY = '__i18n_locale__';
let lng: Locales = Locales.de;
try {
  // const l = localStorage.getItem(LOCAL_STORAGE_KEY) as Locales;
  const l = config.defaultLocale;
  if (!l) {
    console.log(`No locale stored in localStorage. Fallback to ${lng}`);
  } else if (Object.keys(resources).includes(l)) {
    console.log(`Load and setting default locale from localStorage to ${l}`);
    lng = l;
  } else {
    console.warn(`Unknown locale loaded from localStorage ${l}. Fallback to lng`);
  }
} catch (error) {
  console.warn('Error retrieving current locale from localStorage', error);
}

i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: Locales.de,
  // keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

// i18n.on('languageChanged', (l) => {
//   try {
//     localStorage.setItem(LOCAL_STORAGE_KEY, l);
//   } catch (error) {
//     console.warn('Error saving current locale to localStorage', error);
//   }
// });

export default i18n;
