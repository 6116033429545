import React, { useMemo } from 'react';
import s from './BreakEvenPointChart.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { BreakEvenPointFormValues } from './BreakEvenPointForm';
import {
  DotMarker,
  FinancingRepaid,
  Legend,
  ProfitStartPoint,
  TriangleMarker,
  equityColor,
  loanAmountColor,
  governmentFundingColor,
  lineColor,
} from './BreakEvenPointChart.component';
import { round } from 'lodash';

interface BreakEvenPointChartParams {
  loanAmount: number;
  breakEvenPoint: number;
}

const yAxisHeight = 620;
const maxEquityAndLoanAmountHeight = 490;
const firstBar = {
  line: { x1: 300, x2: 300, y1: 645, y2: 100 },
  text: { x: 315, y: 648 },
  icon: { x: '310', y: '350' },
};

const secondBar = {
  line: { x1: 500, x2: 495, y1: 645, y2: 90 },
  text: { x: 510, y: 648 },
  icon: { x: '510', y: '350' },
};

const BreakEvenPointChart: React.FunctionComponent<BreakEvenPointChartParams> = ({ loanAmount, breakEvenPoint }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<BreakEvenPointFormValues>();

  const yAxisBarHeights = useMemo(() => {
    const total = values.governmentFunding + loanAmount + values.equity;
    const total2 = loanAmount + values.equity;

    const governmentHeightPercentage = values.governmentFunding / total;
    const equityHeightPercentage = values.equity / total2;
    const loanHeightPercentage = loanAmount / total2;

    const governmentHeight = round(governmentHeightPercentage * yAxisHeight);
    const equityHeight = round(equityHeightPercentage * maxEquityAndLoanAmountHeight);
    const loanHeight = round(loanHeightPercentage * maxEquityAndLoanAmountHeight);

    return {
      equity: { y: yAxisHeight - equityHeight, height: equityHeight },
      loan: { y: yAxisHeight - equityHeight - loanHeight, height: loanHeight },
      government: { y: yAxisHeight - equityHeight - loanHeight - governmentHeight, height: governmentHeight },
    };
  }, [loanAmount, values.equity, values.governmentFunding]);

  const bars = useMemo(() => {
    const isLoanPeriodBelowBreakEven = values.period < breakEvenPoint;
    const firstBarPeriod = isLoanPeriodBelowBreakEven ? values.period : breakEvenPoint;
    const secondBarPeriod = isLoanPeriodBelowBreakEven ? breakEvenPoint : values.period;
    const firstIcon = isLoanPeriodBelowBreakEven ? FinancingRepaid : ProfitStartPoint;
    const secondIcon = isLoanPeriodBelowBreakEven ? ProfitStartPoint : FinancingRepaid;
    return {
      first: { period: firstBarPeriod, icon: firstIcon },
      second: { period: secondBarPeriod, icon: secondIcon },
    };
  }, [breakEvenPoint, values.period]);

  return (
    <section className={s.card}>
      <h3 className="mt-0">{t('breakEvenPointTracker.chart.title')}</h3>
      <div className={s.chartWrapper}>
        <svg viewBox="-20 0 820 650" width="100%" height="100%">
          <defs>
            <DotMarker id="redDot" color={loanAmountColor} />
            <TriangleMarker id="redTriangle" color={loanAmountColor} />

            <DotMarker id="blackDot" color={lineColor} />
            <TriangleMarker id="blackTriangle" color={lineColor} />
          </defs>

          <g id="yAxis">
            <rect
              x="-20"
              y={yAxisBarHeights.government.y}
              width="20"
              height={yAxisBarHeights.government.height}
              fill={governmentFundingColor}
            />
            <rect
              x="-20"
              y={yAxisBarHeights.loan.y}
              width="20"
              height={yAxisBarHeights.loan.height}
              fill={loanAmountColor}
            />
            <rect
              x="-20"
              y={yAxisBarHeights.equity.y}
              width="20"
              height={yAxisBarHeights.equity.height}
              fill={equityColor}
            />
          </g>

          <polygon points="495,90 580,0 800,00 800,80" fill="#C8E48A" />

          <g id="bars">
            <g id="firstBar">
              <line
                x1={firstBar.line.x1}
                y1={firstBar.line.y1}
                x2={firstBar.line.x2}
                y2={firstBar.line.y2}
                stroke="black"
                strokeDasharray="5"
                markerStart="url(#blackDot)"
              />
              {bars.first.icon({ x: firstBar.icon.x, y: firstBar.icon.y, period: bars.first.period })}
              <text x={firstBar.text.x} y={firstBar.text.y} fontSize={14} style={{ fontWeight: 'bold' }}>
                {bars.first.period} Jahre
              </text>
            </g>
            <g id="secondBar">
              <line
                x1={secondBar.line.x1}
                y1={secondBar.line.y1}
                x2={secondBar.line.x2}
                y2={secondBar.line.y2}
                stroke="black"
                strokeDasharray="5"
                markerStart="url(#blackDot)"
              />
              {bars.second.icon({ x: secondBar.icon.x, y: secondBar.icon.y, period: bars.second.period })}
              <text x={secondBar.text.x} y={secondBar.text.y} fontSize={14} style={{ fontWeight: 'bold' }}>
                {bars.second.period} Jahre
              </text>
            </g>
          </g>

          <g id="xAxis">
            <line x1="0" y1="619" x2="800" y2="619" stroke={lineColor} markerEnd="url(#blackTriangle)" />
            <text x="770" y="648" fontSize={14} style={{ fontWeight: 'bold' }}>
              {t('breakEvenPointTracker.chart.time')}
            </text>
          </g>
          <g id="zAxis">
            <text transform="rotate(-47 600 150)" fontSize={14}>
              <tspan x="0" y="0" style={{ textAnchor: 'middle' }}>
                {t('breakEvenPointTracker.chart.energyCostsWithoutRenovations.firstPart')}
              </tspan>
              <tspan x="10" y="20" style={{ textAnchor: 'middle' }}>
                {t('breakEvenPointTracker.chart.energyCostsWithoutRenovations.secondPart')}
              </tspan>
            </text>
            <line x1="0" y1="620" x2="580" y2="0" stroke={lineColor} markerEnd="url(#blackTriangle)" />
          </g>

          <g id="redLine">
            <text
              transform="rotate(-5)"
              textAnchor="start"
              fontSize={14}
              style={{ fontWeight: 'bold' }}
              fill={loanAmountColor}
            >
              <tspan x="0" y={yAxisBarHeights.loan.y + 20}>
                {t('breakEvenPointTracker.chart.energyCostsWithRenovations.firstPart')}
              </tspan>
              <tspan x="0" y={yAxisBarHeights.loan.y + 40}>
                {t('breakEvenPointTracker.chart.energyCostsWithRenovations.secondPart')}
              </tspan>
              <tspan x="0" y={yAxisBarHeights.loan.y + 60}>
                {t('breakEvenPointTracker.chart.energyCostsWithRenovations.thirdPart')}
              </tspan>
            </text>
            <line
              x1="0"
              y1={yAxisBarHeights.loan.y}
              x2="300"
              y2="100"
              stroke={loanAmountColor}
              markerEnd="url(#redDot)"
            />
            <line x1="300" y1="100" x2="495" y2="90" stroke={loanAmountColor} markerEnd="url(#redDot)" />
            <line x1="495" y1="90" x2="800" y2="80" stroke={loanAmountColor} markerEnd="url(#redTriangle)" />
          </g>
        </svg>
      </div>
      <Legend />
    </section>
  );
};

export default BreakEvenPointChart;
