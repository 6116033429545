import React, { memo, useMemo, useRef } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import Button from 'components/common/Button';
import { areEqualFields, scrollToNextMatchingSibling } from 'utils/form';
import s from './ButtonSelect.module.scss';
import { useTranslation } from 'react-i18next';

const SButton = ({ name, option, handleToggle, onBlur, value, contentInColumn }) => {
  const { icon, value: optionValue, label, disabled = false, buttonStyles = {} } = option;
  const { t } = useTranslation();

  const isSelected = React.useCallback(
    (o) => {
      return Array.isArray(value) ? value.includes(o.value) : o.value === value;
    },
    [value],
  );

  return (
    <Button
      startIcon={icon}
      name={name}
      key={optionValue}
      onClick={(e) => handleToggle(option, e)}
      onBlur={onBlur}
      style={buttonStyles}
      className={cn(
        s.button,
        isSelected(option) ? s.selected : s.notSelected,
        contentInColumn && s.contentInColumn,
        icon && s.buttonWithIcon,
      )}
      disabled={disabled}
    >
      {t(label)}
    </Button>
  );
};

const InlineSelect = ({
  label,
  onChange,
  onBlur,
  value,
  options,
  multi,
  nullable,
  error,
  description,
  contentInColumn,
  inOneRow = true,
  labelInTwoRows,
  skipScrollingToField,
  name,
}) => {
  const fieldWrapperRef = useRef();
  const { t } = useTranslation();

  const handleToggle = React.useCallback(
    async (option, e) => {
      async function triggerChange() {
        if (multi) {
          let result = null;
          if (Array.isArray(value)) {
            result = value;
            if (value.includes(option.value)) result = result.filter((item) => item !== option.value);
            else result = [...result, option.value];
          } else {
            result = [option.value];
          }
          await onChange(result);
        } else if (value === option.value) {
          if (nullable !== false) {
            await onChange(null);
          }
        } else {
          await onChange(option.value);
        }
      }
      e.persist();
      await triggerChange();
      e.target.blur();
      if (!multi && !skipScrollingToField) {
        scrollToNextMatchingSibling(fieldWrapperRef);
      }
    },
    [multi, value, onChange, nullable, skipScrollingToField],
  );

  return (
    <div
      className={`field-wrapper ${!skipScrollingToField ? 'scroll-to-field' : ''} ${
        labelInTwoRows ? s.labelInTwoRows : ''
      }`}
      ref={fieldWrapperRef}
    >
      <label className={s.label}>{t(label)}</label>
      <div className={cn(s.buttonsContainer, inOneRow ? s.buttonsInOneRow : '')}>
        {options.map((option) => (
          <SButton
            key={`${name}_${option.value}`}
            {...{ name, option, handleToggle, onBlur, value, contentInColumn }}
          />
        ))}
      </div>
      {error ? <div className="text-danger mt-2">{t(error)}</div> : null}
      {description ? (
        <div className={s.note}>
          <small className="muted">{description}</small>
        </div>
      ) : null}
    </div>
  );
};

function InlineSelectContainer({ form, field, sideEffectOnChange, ...rest }) {
  const { name } = field;
  const { errors, touched } = form;

  const onChange = React.useCallback(
    (v) => {
      form.setFieldValue(name, v);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange(form, v);
    },
    [form, name, sideEffectOnChange],
  );

  const error = useMemo(
    () => (get(errors, name) && get(touched, name) ? get(errors, name) : null),
    [errors, name, touched],
  );

  return <InlineSelect {...{ ...rest, ...field, onChange, error }} />;
}

export default memo(InlineSelectContainer, areEqualFields);
