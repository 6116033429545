import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as CircleAngleBack } from 'assets/icons/circle-angle-back.svg';
import s from './TableScrollButtons.module.scss';

enum ScrollDirection {
  left = 'left',
  right = 'right',
}

interface TableScrollButtons {
  tableWrapperRef: React.RefObject<HTMLDivElement>;
}

const TableScrollButtons = ({ tableWrapperRef }: TableScrollButtons) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    setScrollPosition(tableWrapperRef.current.scrollLeft);
  }, [tableWrapperRef]);

  useEffect(() => {
    tableWrapperRef.current.addEventListener('scroll', handleScroll);
    // return () => {
    // tableWrapperRef.current.removeEventListener('scroll', f);
    // };
  }, [handleScroll, tableWrapperRef]);

  const onScrollButtonClick = useCallback(
    (direction: ScrollDirection) => {
      const offset = direction === ScrollDirection.right ? -100 : 100;
      tableWrapperRef.current.scroll({ left: tableWrapperRef.current.scrollLeft + offset, behavior: 'smooth' });
    },
    [tableWrapperRef],
  );

  const hideLeftButton = useMemo(() => scrollPosition <= 50, [scrollPosition]);
  const hideRightButton = useMemo(() => {
    if (!tableWrapperRef.current) return false;
    return scrollPosition >= tableWrapperRef.current.scrollWidth - tableWrapperRef.current.clientWidth;
  }, [scrollPosition, tableWrapperRef]);

  return (
    <>
      {!hideLeftButton ? (
        <button className={s.btnScrollLeft} onClick={() => onScrollButtonClick(ScrollDirection.right)}>
          <CircleAngleBack />
        </button>
      ) : null}
      {!hideRightButton ? (
        <button className={s.btnScrollRight} onClick={() => onScrollButtonClick(ScrollDirection.left)}>
          <CircleAngleBack style={{ transform: 'rotate(180deg)' }} />
        </button>
      ) : null}
    </>
  );
};

export default TableScrollButtons;
