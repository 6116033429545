import React, { useCallback } from 'react';
import { Field } from 'formik';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as SaunaIcon } from 'assets/icons/calculator/electricityConsumers/sauna.svg';
import { ReactComponent as WhirlpoolIcon } from 'assets/icons/calculator/electricityConsumers/whirlpool.svg';
import { ReactComponent as SwimmingPoolIcon } from 'assets/icons/calculator/electricityConsumers/swimming-pool.svg';
import { ReactComponent as AirConditionIcon } from 'assets/icons/calculator/electricityConsumers/air-condition.svg';
import { ReactComponent as WaterbedIcon } from 'assets/icons/calculator/electricityConsumers/waterbed.svg';
import NumberInput from 'components/inputs/NumberInput';
import Counter from 'components/inputs/Counter';
import Switch from 'components/inputs/Switch';
import { Col, Row } from 'react-grid-system';
import { customEnergyRequirementWhiteList } from 'pages/ProjectDetailsCalculator.data';

const FourthSection = ({ t, values }) => {
  const onChangeEnergyDemand = useCallback(({ value, form }) => {
    if (value) return;

    customEnergyRequirementWhiteList.map((key) => {
      form.setFieldValue(key, 0);
    });
  }, []);

  return (
    <>
      <h2 className="text-primary text-left">{t('co2CalculatorForm.more_info_about_electricity')}</h2>
      <Field
        label={t('co2CalculatorForm.are_devices_less_than_10_years_old')}
        name="are_devices_less_than_10_years_old"
        component={Switch}
      />
      <Field
        label={t('co2CalculatorForm.power_consumers.name')}
        name="power_consumers"
        multi
        contentInColumn
        inOneRow={false}
        component={ButtonSelect}
        options={[
          {
            icon: <SaunaIcon className="stroke" />,
            label: t('co2CalculatorForm.power_consumers.sauna'),
            value: 'sauna',
          },
          {
            icon: <WhirlpoolIcon className="stroke" />,
            label: t('co2CalculatorForm.power_consumers.outdoor_whirlpool'),
            value: 'outdoor_whirlpool',
          },
          {
            icon: <SwimmingPoolIcon className="stroke" />,
            label: t('co2CalculatorForm.power_consumers.swimming_pool'),
            value: 'swimming_pool',
          },
          {
            icon: <AirConditionIcon className="stroke" />,
            label: t('co2CalculatorForm.power_consumers.air_condition'),
            value: 'air_condition',
          },
          {
            icon: <WaterbedIcon className="stroke" />,
            label: t('co2CalculatorForm.power_consumers.waterbed'),
            value: 'waterbed',
          },
        ]}
      />

      <h2 className="text-primary text-left">{t('co2CalculatorForm.solarPowerSystem.title')}</h2>
      <Field
        label={t('co2CalculatorForm.solarPowerSystem.isSolarPowerSystem')}
        name="is_solar_power_system"
        component={Switch}
      />
      {values.is_solar_power_system ? (
        <Field
          label={t('co2CalculatorForm.solarPowerSystem.solarPowerSystemSize')}
          name="solar_power_system_size"
          component={Counter}
          min={3}
          max={15}
        />
      ) : null}

      <h2 className="text-primary text-left">{t('co2CalculatorForm.energyRequirements.title')}</h2>
      <Field
        name="energyDemand"
        component={Switch}
        label="co2CalculatorForm.energyRequirements.question"
        sideEffectOnChange={onChangeEnergyDemand}
      />

      {values.energyDemand ? (
        <Row>
          <Col xs={6}>
            <Field
              label={t('co2CalculatorForm.energyRequirements.heatingOil')}
              name="heating_oil"
              component={NumberInput}
            />
            <Field
              label={t('co2CalculatorForm.energyRequirements.naturalGas')}
              name="natural_gas"
              component={NumberInput}
            />
            <Field label={t('co2CalculatorForm.energyRequirements.coal')} name="coal" component={NumberInput} />
            <Field label={t('co2CalculatorForm.energyRequirements.firewood')} name="firewood" component={NumberInput} />
          </Col>
          <Col xs={6}>
            <Field
              label={t('co2CalculatorForm.energyRequirements.districtHeating')}
              name="district_heating"
              component={NumberInput}
            />
            <Field
              label={t('co2CalculatorForm.energyRequirements.woodChips')}
              name="wood_chips"
              component={NumberInput}
            />
            <Field
              label={t('co2CalculatorForm.energyRequirements.woodPellets')}
              name="wood_pellets"
              component={NumberInput}
            />
            <Field
              label={t('co2CalculatorForm.energyRequirements.electricityUsed')}
              name="electricity_used"
              component={NumberInput}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default FourthSection;
