import React from 'react';
import { setConfiguration, Container, Row, Col, ScreenClassProvider } from 'react-grid-system';
import s from './AppLayout.module.scss';

setConfiguration({ maxScreenClass: 'xl', gridColumns: 12 });

type IAppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout: FC<IAppLayoutProps> = ({ children }) => {
  return (
    <ScreenClassProvider>
      <Container className={s.container}>
        <Row className={s.row}>
          <Col xs={12} className={s.col}>
            {children}
          </Col>
        </Row>
      </Container>
    </ScreenClassProvider>
  );
};

export default AppLayout;
