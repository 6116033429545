import React from 'react';
import cn from 'classnames';
import s from './heap.module.scss';

export function Small({ children }) {
  return (
    <>
      <br />
      <small className="muted">{children}</small>
    </>
  );
}

export const SectionWrapper = ({ children }) => {
  return <div className={s.sectionWrapper}>{children}</div>;
};

export const Header = ({ children, className = '', endIcon = null }) => {
  return (
    <div className={cn(s.header, className)}>
      {children}
      {endIcon ? <div className={s.endIcon}>{endIcon}</div> : null}
    </div>
  );
};
