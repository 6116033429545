import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { CUSTOM_ENERGY_REQUIREMENTS } from 'graphql/fragments/customEnergyRequirements';

export const SELECT_CO2_CALCULATIONS = gql`
  query selectCO2Calculations($projectId: ID!) {
    co2Calculations(projectId: $projectId) {
      _id
      custom_energy_requirements {
        ...customEnergyRequirements
      }
      co2_emissions_before {
        result {
          energy_ghg_equivalent_car
          energy_ghg_equivalent_plane
          energy_ghg_equivalent_trees
          total_energy_balance_per_year {
            calculated_value
          }
          energy_ghg_balance_per_year {
            calculated_value
          }
          total_energy_costs {
            calculated_value
          }
        }
      }
    }
  }
  ${CUSTOM_ENERGY_REQUIREMENTS}
`;

const useSelectCO2Calculations = (projectId) => {
  const { data, loading, error } = useQuery(SELECT_CO2_CALCULATIONS, { variables: { projectId }, skip: !projectId });
  return { co2Calculations: data?.co2Calculations, error, loading };
};

export default useSelectCO2Calculations;
