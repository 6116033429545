import Button from 'components/common/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as ExclamationTriangle } from 'assets/icons/exclamation-triangle.svg';
import routePaths from 'router/routePaths';

const ErrorPage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        textAlign: 'center',
      }}
    >
      <ExclamationTriangle width={80} height={80} />
      <h2>{t('errors.unexpectedError')}</h2>
      <Link to={routePaths.newProject}>
        <Button>
          <Home /> {t('button.goHome')}
        </Button>
      </Link>
    </div>
  );
};

export default ErrorPage;
