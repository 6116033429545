import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { CO2_CALCULATOR_FORM_VALUES_FRAGMENT } from 'graphql/fragments/co2CalculatorFormValuesFragment';

export const PROJECT_QUERY = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...project
    }
    co2Calculations(projectId: $id) {
      co2_emissions_before {
        form_values {
          ...co2CalculatorFormValues
        }
      }
      custom_energy_requirements {
        coal {
          user_value
        }
        heating_oil {
          user_value
        }
        natural_gas {
          user_value
        }
        firewood {
          user_value
        }
        district_heating {
          user_value
        }
        wood_chips {
          user_value
        }
        wood_pellets {
          user_value
        }
        electricity_used {
          user_value
        }
      }
    }
  }
  ${PROJECT_FRAGMENT}
  ${CO2_CALCULATOR_FORM_VALUES_FRAGMENT}
`;

const useProject = (projectId) => {
  const { loading, data, error } = useQuery(PROJECT_QUERY, { variables: { id: projectId }, skip: !projectId });
  return { project: data?.project, co2Calculations: data?.co2Calculations, loading, error };
};

export default useProject;
