import gql from 'graphql-tag';
import { CO2_CALCULATOR_FORM_VALUES_FRAGMENT } from './co2CalculatorFormValuesFragment';
import { CO2_CALCULATOR_RESULT } from './co2CalculatorResultFragment';
import { CUSTOM_ENERGY_REQUIREMENTS } from './customEnergyRequirements';

export const CO2_CALCULATIONS_FRAGMENT = gql`
  fragment co2CalculationsFragment on CO2Calculations {
    _id
    custom_energy_requirements {
      ...customEnergyRequirements
    }
    co2_emissions_before {
      form_values {
        ...co2CalculatorFormValues
      }
      result {
        ...co2CalculatorResult
      }
    }
    co2_emissions_potential {
      form_values {
        ...co2CalculatorFormValues
      }
      result {
        ...co2CalculatorResult
      }
    }
    co2_emissions_after {
      form_values {
        ...co2CalculatorFormValues
      }
      result {
        ...co2CalculatorResult
      }
    }
    energy_renovations {
      new_windows {
        co2_saving
        saving_energy_costs
      }
      insulation_top_ceiling {
        co2_saving
        saving_energy_costs
      }
      insulation_basement_ceiling {
        co2_saving
        saving_energy_costs
      }
      insulation_facade {
        co2_saving
        saving_energy_costs
      }
      controlled_living_space_ventilation {
        co2_saving
        saving_energy_costs
      }
      solar_power_system {
        co2_saving
        saving_energy_costs
      }
      heating_system {
        co2_saving
        saving_energy_costs
      }
    }
  }
  ${CO2_CALCULATOR_RESULT}
  ${CO2_CALCULATOR_FORM_VALUES_FRAGMENT}
  ${CUSTOM_ENERGY_REQUIREMENTS}
`;
