import React from 'react';
import settings from '../config/config';

export default function useFormValuesRef({ formValueMock, initialValues }) {
  const formValuesRef = React.useRef<any>();

  if (!formValuesRef.current) {
    formValuesRef.current = {
      ...(settings.isDevelopment ? { ...initialValues, ...formValueMock } : initialValues),
    };
  }
  return formValuesRef;
}
