import React, { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/common/Modal';
import { useModal } from 'components/common/Modal/Modal';
import s from './DetailedInfoIcon.module.scss';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';

export interface iDetailedInfo {
  detailedInfo?: React.ReactNode;
}

const modalStyle = {
  minHeight: 'unset',
};

const DetailedInfoIcon = ({ detailedInfo }: iDetailedInfo) => {
  const { t } = useTranslation();
  const { showModal, isVisible, hideModal } = useModal();

  if (!detailedInfo) return null;
  return (
    <>
      {' '}
      <FontAwesomeIcon className={s.icon} icon={faInfoCircle} onClick={() => showModal()} />
      <Modal {...{ isVisible, close: hideModal, modalStyle, headerText: t('modal.detailed_info') }}>
        {detailedInfo}
        <div className="text-center mt-4">
          <Button onClick={() => hideModal()}>{t('button.ok')}</Button>
        </div>
      </Modal>
    </>
  );
};

export default memo(DetailedInfoIcon, equal);
