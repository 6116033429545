/* eslint-disable no-unused-expressions */
import React from 'react';
import classnames from 'classnames';
import { Col, Row, Container } from 'react-grid-system';
import s from './Alert.module.scss';

const colorToClassNameMap = {
  primary: s.primary,
  danger: s.danger,
  warning: s.warning,
};

const AlertContent = ({ children, color, className, props, icon }) => (
  <div className={classnames(s.alert, colorToClassNameMap[color], className)} {...props}>
    {children}
    {icon ? <div className={s.iconContainer}>{icon}</div> : null}
  </div>
);

const Alert = ({ children, color = 'primary', icon, className, withContainer = false, ...props }: any) => {
  return withContainer ? (
    <Container>
      <Row>
        <Col xs={12}>
          <AlertContent {...{ children, color, className, props, icon }} />
        </Col>
      </Row>
    </Container>
  ) : (
    <AlertContent {...{ children, color, className, props, icon }} />
  );
};

export default Alert;
