import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { CO2_CALCULATIONS_FRAGMENT } from 'graphql/fragments/co2Calculations';

export const CO2_CALCULATIONS = gql`
  query calculatorData($projectId: ID!) {
    co2Calculations(projectId: $projectId) {
      ...co2CalculationsFragment
    }
  }
  ${CO2_CALCULATIONS_FRAGMENT}
`;

const useGetCO2Calculations = (projectId) => {
  const { data, loading, error } = useQuery(CO2_CALCULATIONS, {
    variables: { projectId },
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });
  return { co2Calculations: data?.co2Calculations, error, loading };
};

export default useGetCO2Calculations;
