import { getFeaturesSchema } from 'constants/featuresSchema';

export const customEnergyRequirementWhiteList = [
  'heating_oil',
  'coal',
  'natural_gas',
  'firewood',
  'district_heating',
  'wood_chips',
  'wood_pellets',
  'electricity_used',
];

export const co2CalculatorWhiteList = [
  'heated_living_area',
  'kind_of_house',
  'energy_standard',
  'renovations',
  'is_solar_heating_system',
  'solar_heating_system_type',
  'number_of_people',
  'heating_system',
  'age_of_heating_system',
  'temperature',
  'hot_water_producer',
  'amount_of_hot_water',
  'are_devices_less_than_10_years_old',
  'power_consumers',
  'is_solar_power_system',
  'solar_power_system_size',
];

const schema = getFeaturesSchema();
export const whitelistedProps = [
  'name',
  'zip',
  'form_values.floors_number',
  ...schema.flatMap((feature) =>
    Array.isArray(feature.form) ? feature.form.map(({ name }) => `form_values.${name}`) : [],
  ),
];

export const initialValuesCO2Calculator = {
  heated_living_area: null,
  kind_of_house: null,
  energy_standard: null,
  renovations: [],
  is_solar_heating_system: false,
  solar_heating_system_type: null,
  number_of_people: null,
  heating_system: null,
  age_of_heating_system: null,
  temperature: null,
  hot_water_producer: null,
  amount_of_hot_water: null,
  are_devices_less_than_10_years_old: false,
  power_consumers: [],
  is_solar_power_system: false,
  solar_power_system_size: null,
  ...customEnergyRequirementWhiteList.reduce((acc, key) => ({ ...acc, [key]: null }), {}),
};
