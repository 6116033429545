import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toastErrorMessage } from 'utils/helpers';

const CALCULATE_BREAK_EVEN_POINT_MUTATION = gql`
  mutation calculateBreakEvenPoint($projectId: ID!, $input: CalculateBreakEvenPointInput!) {
    calculateBreakEvenPoint(projectId: $projectId, input: $input) {
      loanAmount
      monthlyRate
      breakEvenPoint
    }
  }
`;

const useCalculateBreakEvenPoint = (projectId) => {
  const [calculateBreakEvenPointMutation, { data, loading }] = useMutation(CALCULATE_BREAK_EVEN_POINT_MUTATION);

  const handleUpdate = useCallback(
    async (input) => {
      try {
        const {
          data: { calculateBreakEvenPoint },
        } = await calculateBreakEvenPointMutation({ variables: { projectId, input } });
        return calculateBreakEvenPoint;
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [calculateBreakEvenPointMutation, projectId],
  );

  return { mutation: handleUpdate, data, loading };
};

export default useCalculateBreakEvenPoint;
