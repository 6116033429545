export default {
  translation: {
    button: {
      start: 'Start',
      back: 'Back',
      next: 'Next',
      ok: 'Ok',
      save: 'Save',
      continue: 'Continue',
      goHome: 'Go to Homepage',
    },
    modal: {
      detailed_info: 'Detailed information',
    },
    errors: {
      chooseOne: 'Choose one',
      fillField: 'Fill field',
      wrongZipCode: 'Wrong zip code',
      privacyPolicyRequired: 'You must agree with our Privacy Policy',
      invalidEmail: 'Invalid email',
      projectNotFound: 'Project not found',
      unexpectedError: 'Unexpected error occurred',
      termsOfUseRequired: 'You must agree with our Terms of Use',
      minItems: 'Field must have at least {{min}} item',
    },
    user: {
      firstName: 'First name',
      lastName: 'Last name',
    },
    auth: {
      email: 'Email',
      phoneNumber: 'Phone number',
      privacyPolicyLabel: 'I have read the <privacyPolicyUrl>Privacy Policy</privacyPolicyUrl> and I agree',
      termsOfUseLabel: 'I accept the <termsOfUseUrl>Terms of Use</termsOfUseUrl>',
      contactAgreement: "I agree that I'll be contacted by Dummy",
    },
    priceHubbleApiSection: {
      title: 'Wertsteigerung Deiner Immobilie',
      subtitle:
        'Durch die Sanierung verbessert sich nicht nur Dein Energieverbrauch und die CO2-Bilanz. Auch Deine Immobilie steigt in ihrem Wert unmittelbar nach der Umsetzung der Sanierungsmaßnahmen!',
      today: 'Today',
      inTheFuture: 'In the future',
      increaseInValue: 'Wertsteigerung',
      serviceIsNotAvailable: 'N/A \n Service is not available',
    },
    breakEvenPointTracker: {
      title: 'Ab wann zahlen sich Deine Investitionen aus?',
      details:
        'Hier kann man den Finanzierungsbedarf und die monatlichen Kosten für den geplanten Zeitraum berechnen. Die grafische Darstellung passt sich entsprechend der Wert an und zeigt, ab wann sich die Investitionen ausgezahlt haben.',
      form: {
        totalRenovationCosts: 'Total renovation costs',
        governmentFunding: 'Government funding',
        equity: 'Equity',
        financingRequirement: 'Financing requirement',
        financing: 'Financing',
        period: 'Period',
        interestRate: 'Interest rate per year',
        monthlyRate: 'Monthly rate',
        energyCosts: 'Energy costs',
        new: 'New',
        old: 'Old',
        totalMonthly: 'Total monthly',
      },
      chart: {
        title: 'Kostenersparnis',
        time: 'Time',
        baselineEnergyCosts: 'Baseline energy costs',
        energyCostsWithoutRenovations: {
          firstPart: 'Energiekosten ohne',
          secondPart: 'Sanierungsmaßnahmen',
        },
        financingRepaid: {
          firstPart: 'Finanzierung',
          secondPart: 'getilgt',
        },
        profitStartPoint: {
          firstPart: 'Ab hier fängt',
          secondPart: 'Dein Gewinn an',
        },
        energyCostsWithRenovations: {
          firstPart: 'Energiekosten mit',
          secondPart: 'Sanierungsmaßnahmen',
          thirdPart: 'und Finanzierungskosten',
        },
        legend: {
          governmentFunding: 'Government funding',
          equity: 'Own funds',
          loanAmount: 'Funding needs',
        },
      },
    },
    co2CalculatorForm: {
      moreInfoAboutHouse: 'More information about your house',
      heated_living_area: 'Heated living area',
      calculationTypeLabel: 'What values would you like to proceed with?',
      calculationType: {
        custom: 'Custom',
        system: 'Calculated by system',
      },
      energy_standard: {
        name: 'Energy standard of the house',
        description: 'as of 2006 (low energy)',
        detailed_info:
          '@Lorem ipsum dolor sit amet. Ut assumenda aliquid et suscipit voluptas aut fugiat consequuntur vel debitis quos non optio dolores. Qui molestiae ullam eum eveniet vero et voluptatem saepe vel libero alias qui aspernatur suscipit eos veritatis eius. Eum quod nihil aut similique earum id expedita itaque.',
        before_1960: 'Before 1960',
        between_1960_and_1975: '1960-1975',
        between_1976_and_1990: '1976-1990',
        after_1990: 'After 1990',
        low_energy_house: 'Low energy',
        passive_house: 'Passive',
      },
      renovations: {
        name: 'Has there been renovations?',
        new_windows: 'New windows',
        insulation_top_ceiling: 'Insulation roof/top ceiling',
        insulation_basement_ceiling: 'Insulation basement ceiling',
        insulation_facade: 'Insulation facade',
        controlled_living_space_ventilation: 'Controlled living space ventilation',
        notRenovated: 'Not renovated',
      },
      kind_of_house: {
        name: 'Which kind of house do you have?',
        single_family_house: 'Single family house',
        bungalow_or_complex_floor_plan: 'Bungalow or non cubic-layout',
        town_house: 'Town house',
        semi_detached_house: 'Semi detached house',
      },
      number_of_people: 'How many people are living in the house?',
      tell_about_your_heating: 'Tell us about your heating',
      more_info_about_hot_water: 'More information about your hot water',
      more_info_about_electricity: 'More information about your electricity',
      heating_system: {
        name: 'Heating system',
        natural_gas: 'Natural gas',
        district_heating_biomass: 'District heating biomass',
        district_heating_standard: 'District heating standard',
        wood_chips: 'Wood chips',
        wood_pellets: 'Wood pellets',
        firewood: 'Firewood',
        coal: 'Coal',
        heating_oil: 'Heating oil',
        electric: 'Electric',
        heat_pump: 'Heating pump',
      },
      is_solar_heating_system: 'Is there a solar heating system?',
      solar_heating_system_type: {
        name: 'What kind of solar thermal do you have',
        heating_and_hot_water: 'For heating and hot water',
        only_hot_water: 'Only hot water',
      },
      age_of_heating_system: {
        name: 'How old is your heating-system',
        description: 'New technology means that the heater is less than 10 years old',
        less_than_10_years: 'New technology',
        between_10_and_20_years: 'Between 10 and 20  years',
        more_than_20_years: 'Older than  20 years',
      },
      temperature: {
        name: 'Which temperature do you have?',
        description: 'Each degree increases the energy demand  about 6%',
        less_than_21: 'Less than 21',
        between_21_and_23: 'Between 21 and 23',
        more_than_23: 'More than 23',
      },
      hot_water_producer: {
        name: 'How are you producing hot water?',
        heating_system: 'With the heating-system ',
        electric_boiler: 'Electric boiler ',
        gas_heater: 'Gas heater',
        heat_pump: 'Heat pump',
      },
      amount_of_hot_water: {
        name: 'How much hot water do you need',
        description: 'Low (just showering) \n Medium (50l/day showering and bathing) \n High (only bathing)',
        low: 'Low',
        medium: 'Medium',
        hight: 'High',
      },
      are_devices_less_than_10_years_old:
        'Are most of your devices like cooling- and washing-equipment, cooker, oven younger than 10 years?',
      power_consumers: {
        name: 'Which extraordinary power consumer do you have?',
        sauna: 'Sauna',
        outdoor_whirlpool: 'Outdoor whirlpool',
        swimming_pool: 'Swimming pool (heated)',
        air_condition: 'Air condition',
        waterbed: 'Waterbed',
      },
      solarPowerSystem: {
        title: 'Solar power system',
        isSolarPowerSystem: 'Do you have a PV system?',
        solarPowerSystemSize: 'What size is it?',
      },
      energyRequirements: {
        title: 'Specification of energy requirements',
        question: 'Do you know your exact annual electricity consumption and heat requirements?',
        naturalGas: 'Natural gas (kWh)',
        coal: 'Coal (kg)',
        firewood: 'Firewood (cubic meter)',
        woodChips: 'Wood chips (cubic meter)',
        woodPellets: 'Wood pellets (kg)',
        electricityUsed: 'Electricity used (kWh)',
        heatingOil: 'Heating oil (liter)',
        districtHeating: 'District heating (kWh)',
      },
    },
    co2CalculatorResult: {
      calculatedValue: 'Calculated value',
      userValue: 'User value',
      deviationWarningMessage: 'Deviation between system and user values is {{value}}%',
      energyScaleClasses: {
        title: 'Energy balance of your house',
        actualClass: 'Today',
        potentialClass: 'In the future',
      },
      customEnergyRequirementsTable: {
        headers: {
          fuelType: 'Fuel type',
          userInput: 'User input',
          energy: 'Energy',
          costs: 'Costs',
          ghg: 'GHG kg/a',
        },
        fuelType: {
          naturalGas: 'Natural gas (kWh)',
          coal: 'Coal (kg)',
          firewood: 'Firewood (cubic meter)',
          woodChips: 'Wood chips (cubic meter)',
          woodPellets: 'Wood pellets (kg)',
          electricityUsed: 'Electricity used (kWh)',
          heatingOil: 'Heating oil (liter)',
          districtHeating: 'District heating (kWh)',
        },
      },
      energyGhgEquivalents: {
        title: 'In simple words this means:',
        details: 'Umgerechnet bedeuten Deine Einsparungen Folgendes:',
        flights: 'Flights',
        equivalentCar: 'Car kilometers',
        equivalentPlane: 'Flights Vienna - New York',
        equivalentTrees: 'Trees',
      },
      improveHouseBtnLabel: 'Improve house',
      potentialEnergyBalance: {
        afterTitle: 'Savings potential of your house, per year',
        title: 'Your saving potential',
        description: 'Your values of full renovation and installation of a photovoltaic system (5kwP)',
        redevelopmentHouseLabel: 'Would you like to calculate your individual energy saving potential?',
        ghgBalance: {
          label: 'CO₂ Saving',
          value: '{{value}} kg',
        },
        totalEnergyCosts: {
          label: 'Energy costs',
          value: '{{value}} €',
        },
        totalEnergyBalancePerYear: {
          label: 'Energy consumption',
          value: '{{value}} kWh',
        },
      },
      heatingResult: {
        tableTitle: 'Heating result',
        totalExternalEnergyDemand: 'Total external energy demand',
        specificHeatingDemand: 'Specific heating demand',
        specificHeatingDemandDescription:
          'Describes the amount of heat required per square meter of heated gross floor area',
        heatingFactorKea: 'Heating Factor KEA',
        heatingFactorKeaDescription:
          'The KEA includes all energetic expenditures for production, transport and consumption of the heating medium.',
        heatingFactorGhg: 'Heating Factor GHG',
        heatingFactorGhgDescription:
          'Indicates how many kg of CO₂ per kWh of heat are produced with the selected heating medium.',
        totalHeatingDemand: 'Total heating demand',
        totalHeatingDemandDescription: 'Thermal energy that is necessary to heat the house to the desired temperature',
        primaryEnergyForHeating: 'Primary energy for heating',
        primaryEnergyForHeatingDescription:
          'This is the amount of energy in (kWh) that you need to heat the house, i.e. to cover the heating demand. A very strong influencing factor here is of course the efficiency of the heating system and the amount of energy that is required to produce the heating medium.',
        heatingGhgEmission: 'GHG emission heating',
        heatingGhgEmissionDescription: 'Total amount of CO₂ generated by your heating',
        heatingGhgEquivalentCar: 'This corresponds to kilometers with a petrol car',
        heatingGhgEquivalentCarDescription: 'The basis is an amount of CO₂ of 150g / km',
        heatingGhgEquivalentPlane: 'Number of air travel that corresponds to this amount of CO₂',
        heatingGhgEquivalentPlaneDescription: 'Vienna - New York - Vienna flights (economy class)',
        heatingGhgEquivalentTrees: 'Number of trees that store this amount of CO₂',
        heatingGhgEquivalentTreesDescription: 'A tree stores around 10kg of CO₂ per year',
        heatingCosts: 'Heating-costs',
        heatingCostsDescription: 'based avg. Prices for your heating-system in Austria',
      },
      hotWaterResult: {
        tableTitle: 'Hot water results',
        primaryEnergyForHotWater: 'Primary energy for hot water',
        primaryEnergyForHotWaterDescription:
          'This is the amount of energy in (kWh) that you need to produce hot water in your house',
        hotWaterGhgEmission: 'GHG emission',
        hotWaterGhgEmissionDescription: 'Total amount of CO₂ that results from hot water production',
        hotWaterGhgEquivalentCar: 'This corresponds to kilometers with a petrol car',
        hotWaterGhgEquivalentCarDescription: 'The basis is an amount of CO₂ of 150g / km',
        hotWaterGhgEquivalentPlane: 'Number of air travel that corresponds to this amount of CO₂',
        hotWaterGhgEquivalentPlaneDescription: 'Vienna - New York - Vienna flights (economy class)',
        hotWaterGhgEquivalentTrees: 'Number of trees that store this amount of CO₂',
        hotWaterGhgEquivalentTreesDescription: 'A tree stores around 10kg of CO₂ per year',
        hotWaterCosts: 'HotWater costs',
        hotWaterCostsDescription: 'based avg. Prices for your heating-system in Austria',
      },
      electricityResult: {
        tableTitle: 'Electricity results',
        totalPowerConsumptionPerYear: 'Your total power consumption per year',
        totalPowerConsumptionPerYearDescription: 'Total power consumption for your household',
        totalPowerProductionPerYear: 'Your total power production per year',
        totalPowerProductionPerYearDescription: 'Amount of electricity that is produced on average by your PV system',
        ownConsumption: 'Annual self-consumption',
        ownConsumptionDescription: 'Estimated self-consumption of the PV electricity',
        powerConsumptionFromEnergy: 'Amount of electricity that is purchased from the grid operator',
        powerConsumptionFromEnergyDescription:
          'This value can also be negative if more PV electricity is produced than electricity is consumed',
        electricalFeedInToGrid: 'Amount of electricity that is fed into the grid',
        electricalFeedInToGridDescription: 'Surplus electricity that can be sold',
        ghgSavingsSolarPowerSystem: 'CO₂ savings through PV systems',
        ghgSavingsSolarPowerSystemDescription:
          'The basis here is the amount of CO₂ that is generated on average when generating electricity in Europe',
        ghgBalanceOfPowerConsumption: 'CO₂ balance of your electricity consumption',
        ghgBalanceOfPowerConsumptionDescription:
          'This amount of CO₂ results from your electricity consumption when generating electricity in Austria',
        electricityGhgEquivalentCar: 'This corresponds to kilometers with a petrol car',
        electricityGhgEquivalentCarDescription: 'The basis is an amount of CO₂ of 150g / km',
        electricityGhgEquivalentPlane: 'Number of air travel that corresponds to this amount of CO₂',
        electricityGhgEquivalentPlaneDescription: 'Vienna - New York - Vienna flights (economy class)',
        electricityGhgEquivalentTrees: 'Number of trees that store this amount of CO₂',
        electricityGhgEquivalentTreesDescription: 'A tree stores around 10kg of CO₂ per year',
        solarPowerSystemHelpsToSave: 'Your PV system saves you annually',
        solarPowerSystemHelpsToSaveDescription:
          'Based on the green electricity tariff subsidy, the subsidy tariff in 2021 is 7.06 cents / kWh',
        totalElectricityCosts: 'Total Electricity costs',
        totalElectricityCostsDescription: 'based avg. Prices for your heating-system in Austria',
      },
      energyAndCo2BalanceResult: {
        tableTitle: 'The energy & CO2  balance of your house',
        systemTableTitle: 'The energy & CO2  balance of your house. Based on system calculations',
        customTableTitle: 'The energy & CO2  balance of your house. Based on custom values',
        totalEnergyBalancePerYear: 'Your total annual energy requirement',
        totalEnergyBalancePerYearDescription: 'How many kWh do you need for heating, hot water and electricity',
        energyGhgBalancePerYear: 'Your GHG balance per year',
        energyGhgBalancePerYearDescription: 'Amount of CO₂ that is produced as a result',
        energyGhgEquivalentCar: 'This corresponds to kilometers with a petrol car',
        energyGhgEquivalentCarDescription: 'The basis is an amount of CO₂ of 150g / km',
        energyGhgEquivalentPlane: 'Number of air travel that corresponds to this amount of CO₂',
        energyGhgEquivalentPlaneDescription: 'Vienna - New York - Vienna flights (economy class)',
        energyGhgEquivalentTrees: 'Number of trees that store this amount of CO₂',
        energyGhgEquivalentTreesDescription: 'A tree stores around 10kg of CO₂ per year',
        totalEnergyCosts: 'Total energy costs',
        totalEnergyCostsDescription: 'based on avg. prices in Austria',
      },
      valuesBeforeAndAfter: {
        tableTitle: 'Before/After Overview',
        beforeRenovation: 'Before renovation',
        afterRenovation: 'After renovation',
        totalEnergyDemand: 'Total energy demand',
        totalElectricPowerConsumption: 'Total electric power consumption',
        totalElectricPowerProduction: 'Total power electric power production',
        producedElectricityOwnConsumption: 'Self consumption of produced electricity',
        electricityConsumptionFromEnergySupplyCompany: 'Electric power consumption from energy supply company',
        electricalFeedIntoGrid: 'Electrical feed into grid',
        primaryEnergyForHotWater: 'Primary energy for hot water',
        primaryEnergyForHeating: 'Primary energy for heating',
        hotWaterIsPartOfHeating: 'With user entered data hot water is part of heating',
        costsArePartOfElectricityCosts: 'Costs are already part of electricity costs',
        includedCosts: 'Included costs',
        energyDemandArePartOfPowerConsumption: 'Energy demand is already included in the electricity demand',
        includedEnergyDemand: 'Included energy demand',
      },
      showCalculations: 'Show calculations',
      hideCalculations: 'Hide calculations',
      shouldShowAllCalculations: 'Do you want to see all CO2 calculations?',
      numberOfEconomyClassFlights: 'Number of economy-class flights Vienna-New York (round trip)',
      numberStoredTrees: 'A tree stores around 10kg of CO₂ per year',
    },
    project: {
      tabs: {
        firstTab: 'House info',
        secondTab: 'Select calculations',
        thirdTab: 'CO2 Status',
        fourthTab: 'Common',
        fifthTab: 'Energy',
        finalTab: 'Cost overview',
      },
      solar_power_system_size_installation: 'How big should the system be? (kWp)',
      solar_power_system_size_installation_description:
        'Please note that for 1 kWp ("Kilowatt peak") you need 7m² on your rooftop',
      solar_power_system_equipment_type: {
        name: 'The type of equipments of the Solar Power System',
        standard_description:
          'A Standard Solar Power System is mounted on your rooftop. The system is directly connected to your grid.',
        premium_description:
          'A premium system has additionally a solar battery and an App-based energy management system. Furthermore the panels a integrated into the rooftop which looks more aesthetic.',
        superior_description:
          'For a Superior system we assume that it has solar roof tiles for a perfect aesthetic look. Partially you can save some of the extra costs because you need less traditionally roof-tiles.',
      },
      equipment_type: 'Equipment type',
      project_name: 'Project name',
      zip_code: 'ZIP code',
      full_address: 'Full address',
      complete_previous_step_btn: 'Calculate project details',
      wrong_min_house_area: `The area of the house can't be less than the heated living area - {{value}} m²`,
      heated_living_area_disclaimer:
        'if you want to increase the limit heated living area, you need to change the living area in <linkToThirdStep>step 3</linkToThirdStep>',
      link_to_the_third_step: 'go to step 3',
      houseEnergyBalance: {
        header: 'Current energy balance of a house',
        beforeAndAfterHeader: 'Savings potential through the renovation measures',
        label: 'Energy figures for your house without renovation measures',
        specificHeatingDemand: '{{value}} kWh/m²a',
        co2_demand: 'CO₂-Tax {{value}}€/a',
        heating: 'Heating {{value}} kg CO₂/a',
        hotWater: 'Hot water {{value}} kg CO₂/a',
        electricity: 'Electricity {{value}} kg CO₂/a',
      },

      floors: 'Number of floors (without basement)',
      update_btn_label: 'Update values',
      improve_or_renovate_house: 'Would you like to improve or renovate your house?',

      edit_co2_calculator_values: 'Edit values',
      second_step_title: 'Renovate your house & make it climate-friendly',

      new_windows_number: 'How many windows should be changed',
      extra_large_windows_number: 'How many extra large windows (balcony-doors) do you have?',
      lift_and_slide_doors_number: 'How many lift and slide doors should there be?',
      heating_system_for_renovation: {
        name: 'Which heating system do you want to use in the future?',
        hot_water_with_new_heating_system: 'Should hot-water be produced with new heating-system?',
        hot_water_with_new_heating_system_description: 'Hot water be produced with new heating system description',
        district: 'District Heating',
        gas: 'Gas heating',
        pellet: 'Pellet heating',
        heat_pump: 'Heat pump',
      },
      roof: {
        description: ' ',
        name: 'Which roof-type does the house have?',
        gable: 'Gable Roof',
        flat: 'Flat Roof',
        hipped: 'Hipped Roof',
        gable_description: 'The area of an average steep pitched roof without dormer is calculated.',
        flat_description: 'The area of a flat roof with a small overhang is calculated.',
        hipped_description: 'The area of an average steep hipped roof without dormer is calculated.',
        area: 'Estimated roof area (m²)',
      },
      facade_insulation: {
        name: 'Facade insulation',
        description: ' ',
        type: {
          name: 'What type of house do you live in?',
          single_house: 'Detached house for one family',
          double_house: 'Double House',
          middle_house: 'Middle House',
          single_house_description: 'All sides of the house must be insulated.',
          double_house_description: 'Only 3 sides of the house need to be insulated',
          middle_house_description: 'Only 2 sides of the house need to be insulated',
        },
        area: 'Estimated facade area (m²)',
      },
      heating_consumption: 'Heating consumption',
      electricity_consumption: 'Electricity consumption',
      house_area: 'Usable living area (m²)',
      insulation_top_ceiling_area: 'Area of the top floor ceiling in m²',
      insulation_basement_ceiling_area: 'Insulation basement ceiling area',
      fifthStep: {
        title: 'Please choose your desired renovation measures',
        description:
          'In this step you can pre-select all measures you are thinking about. In the next step we will show you the impact on costs and CO2 where you can make your final decision.',
      },
    },
    requestPdfModal: {
      modalHeader: 'Project plan PDF request',
      projectImplementationStartPoint: {
        label: 'When do you want to start implementation?',
        options: {
          withinThreeMonths: 'Within 3 months',
          inThreeToSixMonths: 'In 3 to 6 months',
          laterThanInSixMonths: 'Later than in 6 months',
        },
      },
      requestPdfBtn: 'PDF-Report',
      submitFormBtn: 'Receive result report now',
      confirmationText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat commodo magna porttitor consequat. Nullam ex nisi, tincidunt in dolor ut, maximus ultrices orci. <br /><br />Donec euismod placerat sapien et commodo. Aenean in magna vestibulum nulla iaculis gravida. Suspendisse bibendum tellus eu tellus hendrerit faucibus. Donec porttitor felis velit, vitae congue enim euismod pellentesque. Ut porta pretium neque, et vulputate ligula porttitor vitae.',
    },
    plan: {
      ourPartner: 'Our partner',
      title: 'Your renovation project „{{projectName}}“',
      total: 'Total',
      totalCosts: 'Total costs',
      costsAndSavingsOfMeasures: 'Costs and savings of individual renovation measures',
      completePreviousStep: `You don't have planning categories, try to plan`,
      completePreviousStepBtn: 'Calculate project details',
      backToCalculator: 'Back to the calculator',
      successMessage: 'Project plan PDF was sent to the specified email',
      recalculateProjectBtn: 'Recalculate project',
      pdfCTASection: {
        description:
          'Get your free PDF-Report about energy- and renovation costs and CO2 balance based on or estimation algorithms!',
      },
      getAppointmentSection: {
        title: 'Do you need help? \n Do you have questions?',
        message: 'By clicking on the red button must be opened a new window where user can make an appointment',
        buttonLabel: 'Get an appointment',
      },
      totalSavingsPotentialSection: {
        title: 'Total savings potential',
        text: 'By implementing your measures, you can save {{interest}} % of your previous energy costs. An energy efficiency expert may be able to show you further possibilities. The Real Estate Expert of Sparkasse Bremen will be happy to help you find an expert.',
      },
      renovationPerspective: {
        title: 'What do you save in 15 years after your renovation project?',
        energyCosts: 'Gesamte Einsparung der Energiekosten',
        energyConsumption: 'Gesamte Einsparung des Energieverbrauchs',
        co2Emissions: 'Gesamte Einsparung des CO2-Ausstroßes',
        renovationBenefits: 'Gesamte Wertsteigerung Deiner Immobilie',
      },
      chartLabel: {
        totalCosts: 'Total costs',
        costSavings: 'Cost savings',
        co2Savings: 'CO2 savings',
      },
      tableOverview: {
        headers: {
          category: 'Category',
          price: 'Costs',
          co2Savings: 'CO2 savings',
          savingEnergyCosts: 'Saving energy costs',
        },
        perYear: 'per year',
        noReduction: "No reduction because you've already reached high standard",
        noSavings: 'No savings',
        roofSavingsArePartOfTopCeiling: 'Roof savings are part of the top ceiling savings',
        batteryStorage: {
          noCO2Savings: 'No change in the CO2 balance as no additional electricity is produced',
          noSavingEnergyCosts: 'The reduction is included in the savings from the PV system',
        },
      },
    },
    equipmentTypes: {
      superior: 'Superior',
      premium: 'Premium',
      standard: 'Standard',
      mixed: 'mixed',
    },
    dbcPartnerSection: {
      title: 'Kompletten Ergebnisbericht erhalten!',
      description: `
          <ul>
            <li>Kostenloser PDF-Ergebnisbericht zum Teilen</li>
            <li>Anschauliche Diagramme</li>
            <li>Zusätzliche Erläuterungen</li>
          </ul>
          <p>Im Anschluss kannst Du direkt eine unverbindliche Beratung zur Finanzierungsanfrage vereinbaren.</p>
        `,
    },
    dbcPartnerExtraSection: {
      title: 'Die Wertsteigerung Deiner Immobilie erfahren!',
      description:
        'Lade den Ergebnisbericht herunter und erfahre, wie der Wert Deiner Immobilie direkt nach der Umsetzung der Sanierungsmaßnahmen steigt.',
    },
    fortyTwoWattSection: {
      title: 'Energieberater:in gesucht? Unser Tipp: 42watt',
      description:
        'Die unabhängigen Expert:innen erstellen einen individuellen Sanierungsfahrplan für Dich. Schnell, digital, unkompliziert.',
      btnLabel: 'Zur 42watt Website',
    },
    projectFeatures: {
      battery_storage: 'Battery storage',
      battery_storage_description: 'Battery storage description',
      battery_storage_size: 'Battery storage size (kWh)',
      battery_storage_size_description: 'Battery storage size description',
      battery_storage_blackout_option: 'Do you want Blackout option?',
      battery_storage_blackout_option_description: 'Blackout option description',
      solar_heating: 'Solar heating',
      solar_heating_description: 'Solar heating description',
      solar_heating_equipment_type_description: 'Solar heating equipment type description',
      domestic_ventilation: 'Domestic ventilation',
      domestic_ventilation_description: 'Domestic ventilation description',
      domestic_ventilation_equipment_type_description: 'Domestic ventilation equipment type description',
      insulation_top_ceiling: 'Insulation top ceiling',
      insulation_top_ceiling_description: 'Insulation top ceiling description',
      insulation_top_ceiling_equipment_type_description: 'Insulation top ceiling equipment type description',
      insulation_basement_ceiling: 'Insulation basement ceiling',
      insulation_basement_ceiling_description: 'Insulation basement ceiling description',
      insulation_basement_ceiling_equipment_type_description: 'Insulation basement ceiling equipment type description',
      front_door: 'Front door',
      front_door_description: ' ',
      front_door_equipment_type_description: ' ',
      new_windows: 'New windows',
      new_windows_description: ' ',
      new_windows_equipment_type_description: ' ',
      heating_system: 'Heating system (inc. Radiators)',
      heating_system_description: ' ',
      heating_system_equipment_type_description: ' ',
      roof: 'Roof',
      roof_description: ' ',
      roof_equipment_type_description: ' ',
      facade_insulation: 'Facade insulation',
      facade_insulation_description: ' ',
      facade_insulation_equipment_type_description: 'Facade insulation equipment type description',
      solar_power_system: 'Solar power system',
      solar_power_system_description: ' ',
      wallbox_e_mobility: 'Wallbox E-Mobility',
      wallbox_e_mobility_description:
        'To be ready for the future you should consider to install a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
      wallbox_e_mobility_equipment_type_description:
        'Standard includes a wallbox which is installed based on the existing building wiring which means that it normaly has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
    },
    pageTitles: {
      createProject: 'Create project',
      plan: 'Plan',
    },
  },
};

export const backendErrors = {
  disabled_origin: 'You can not open the calculator',
  access_denied: 'Access denied',
  auth: {
    not_authorized: 'Not authorized',
  },
  project: {
    limit_of_projects: 'You cannot create more than 10 projects',
    expired: 'Project expired',
  },
};
