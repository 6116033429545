import React from 'react';
import { Field } from 'formik';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as DistrictHeatingStandard } from 'assets/icons/calculator/heatingSystem/district-heating-standard.svg';
import { ReactComponent as NaturalGas } from 'assets/icons/calculator/heatingSystem/natural-gas.svg';
import { ReactComponent as Electric } from 'assets/icons/calculator/heatingSystem/electric.svg';
import { ReactComponent as Oil } from 'assets/icons/calculator/heatingSystem/oil.svg';
import { ReactComponent as WoodChip } from 'assets/icons/calculator/heatingSystem/wood-chip.svg';
import { ReactComponent as Coal } from 'assets/icons/calculator/heatingSystem/coal.svg';
import { ReactComponent as Firewood } from 'assets/icons/calculator/heatingSystem/firewood.svg';
import { ReactComponent as DistrictHeatingBiomass } from 'assets/icons/calculator/heatingSystem/district-heating-biomass.svg';
import { ReactComponent as HeatingPump } from 'assets/icons/calculator/heatingSystem/heating-pump.svg';
import { ReactComponent as WoodPellets } from 'assets/icons/calculator/heatingSystem/wood-pellets.svg';
import Switch from 'components/inputs/Switch';

const SecondSection = ({ t, values }) => (
  <>
    <h2 className="text-primary text-left">{t('co2CalculatorForm.tell_about_your_heating')}</h2>
    <Field
      label={t('co2CalculatorForm.heating_system.name')}
      name="heating_system"
      contentInColumn
      inOneRow={false}
      component={ButtonSelect}
      options={[
        {
          icon: <HeatingPump className="fill" />,
          label: t('co2CalculatorForm.heating_system.heat_pump'),
          value: 'heat_pump',
        },
        {
          icon: <DistrictHeatingBiomass className="fill" />,
          label: t('co2CalculatorForm.heating_system.district_heating_biomass'),
          value: 'district_heating_biomass',
        },
        {
          icon: <DistrictHeatingStandard className="fill" />,
          label: t('co2CalculatorForm.heating_system.district_heating_standard'),
          value: 'district_heating_standard',
        },
        {
          icon: <WoodChip className="fill" />,
          label: t('co2CalculatorForm.heating_system.wood_chips'),
          value: 'wood_chips',
        },
        {
          icon: <WoodPellets className="fill" />,
          label: t('co2CalculatorForm.heating_system.wood_pellets'),
          value: 'wood_pellets',
        },
        {
          icon: <Firewood className="fill" />,
          label: t('co2CalculatorForm.heating_system.firewood'),
          value: 'firewood',
        },
        {
          icon: <Coal className="fill" />,
          label: t('co2CalculatorForm.heating_system.coal'),
          value: 'coal',
        },
        {
          icon: <Oil className="fill" />,
          label: t('co2CalculatorForm.heating_system.heating_oil'),
          value: 'heating_oil',
        },
        {
          icon: <Electric className="fill" />,
          label: t('co2CalculatorForm.heating_system.electric'),
          value: 'electric',
        },
        {
          icon: <NaturalGas className="fill" />,
          label: t('co2CalculatorForm.heating_system.natural_gas'),
          value: 'natural_gas',
        },
      ]}
    />
    <Field label={t('co2CalculatorForm.is_solar_heating_system')} name="is_solar_heating_system" component={Switch} />
    {values.is_solar_heating_system ? (
      <Field
        label={t('co2CalculatorForm.solar_heating_system_type.name')}
        name="solar_heating_system_type"
        component={ButtonSelect}
        inOneRow
        options={[
          {
            label: t('co2CalculatorForm.solar_heating_system_type.heating_and_hot_water'),
            value: 'heating_and_hot_water',
          },
          {
            label: t('co2CalculatorForm.solar_heating_system_type.only_hot_water'),
            value: 'only_hot_water',
          },
        ]}
      />
    ) : null}
    <Field
      label={t('co2CalculatorForm.age_of_heating_system.name')}
      name="age_of_heating_system"
      description={t('co2CalculatorForm.age_of_heating_system.description')}
      component={ButtonSelect}
      inOneRow
      options={[
        {
          label: t('co2CalculatorForm.age_of_heating_system.more_than_20_years'),
          value: 'older_than_20_years',
        },
        {
          label: t('co2CalculatorForm.age_of_heating_system.between_10_and_20_years'),
          value: 'between_10_and_20_years',
        },
        {
          label: t('co2CalculatorForm.age_of_heating_system.less_than_10_years'),
          value: 'less_than_10_years',
        },
      ]}
    />
    <Field
      label={t('co2CalculatorForm.temperature.name')}
      description={t('co2CalculatorForm.temperature.description')}
      name="temperature"
      component={ButtonSelect}
      inOneRow
      options={[
        {
          label: t('co2CalculatorForm.temperature.less_than_21'),
          value: 'less_than_21',
        },
        {
          label: t('co2CalculatorForm.temperature.between_21_and_23'),
          value: 'between_21_and_23',
        },
        {
          label: t('co2CalculatorForm.temperature.more_than_23'),
          value: 'more_than_23',
        },
      ]}
    />
  </>
);

export default SecondSection;
