import * as yup from 'yup';
import i18n from 'i18n';

const validationSchema = yup.object().shape({
  heated_living_area: yup
    .number()
    .required(i18n.t('errors.fillField'))
    .min(25, i18n.t('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, i18n.t('errors.values_within_range', { min: 25, max: 300 })),
  kind_of_house: yup.string().nullable().required('errors.chooseOne'),
  energy_standard: yup.string().nullable().required('errors.chooseOne'),
  renovations: yup
    .mixed()
    .notRequired()
    .when('energy_standard', {
      is: (energyStandard) => !['low_energy_house', 'passive_house'].includes(energyStandard),
      then: yup
        .array()
        .of(yup.string())
        .nullable()
        .required('errors.fillField')
        .min(1, i18n.t('errors.minItems', { min: 1 })),
    }),
  solar_heating_system_type: yup
    .mixed()
    .notRequired()
    .when(`is_solar_heating_system`, {
      is: true,
      then: yup.string().nullable().required('errors.chooseOne'),
    }),
  number_of_people: yup.number().nullable().required('errors.chooseOne'),
  heating_system: yup.string().nullable().required('errors.chooseOne'),
  age_of_heating_system: yup.string().nullable().required('errors.chooseOne'),
  temperature: yup.string().nullable().required('errors.chooseOne'),
  hot_water_producer: yup.string().nullable().required('errors.chooseOne'),
  amount_of_hot_water: yup.string().nullable().required('errors.chooseOne'),
  solar_power_system_size: yup
    .mixed()
    .notRequired()
    .when(`is_solar_power_system`, {
      is: true,
      then: yup.number().nullable().required('errors.fillField'),
    }),
  // heating_oil: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // natural_gas: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // coal: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // firewood: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // district_heating: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // wood_chips: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // wood_pellets: yup.number().required('errors.fillField').typeError('errors.fillField'),
  // electricity_used: yup.number().required('errors.fillField').typeError('errors.fillField'),
});

export default validationSchema;
