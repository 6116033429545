import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const termsOfUsePath = require('./TermsOfUse.md');
import s from './TermsOfUse.module.scss';
import { useNavigate } from 'react-router-dom';
import routePaths from 'router/routePaths';
import LoadingOverlay from 'components/common/LoadingOverlay';

const TermsOfUse: React.FunctionComponent = () => {
  const [text, setText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!text) {
      fetch(termsOfUsePath)
        .then((res) => res.text())
        .then((t) => setText(t));
    }
  }, [text]);

  if (!text) return <LoadingOverlay />;

  return (
    <Row>
      <Col xs={1}>
        <span onClick={() => navigate(routePaths.newProject)}>
          <div className={s.backButton}>
            <ArrowBack />
          </div>
        </span>
      </Col>
      <Col xs={11}>
        <div className={s.content}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
      </Col>
    </Row>
  );
};

export default TermsOfUse;
