import { get, inRange, last } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import EcoTracker from './EcoTrackerItem/EcoTracker';
import { EnergyScaleClasses } from './EnergyClassesAndIndicators/EnergyClassesAndIndicators';
import { useTranslation } from 'react-i18next';
import s from './CO2StatusProjectPlanSection.module.scss';
import energyScaleClasses from './EnergyClassesAndIndicators/energy-scale-classes';
import { useFinalStepContext } from 'components/CalculatorForm/FinalTab/FinalStepContext';

function getEnergyClass(specificHeatingDemand) {
  let energyClass;
  if (specificHeatingDemand < energyScaleClasses[0].max) {
    [energyClass] = energyScaleClasses;
  } else if (specificHeatingDemand > last(energyScaleClasses).min) {
    energyClass = last(energyScaleClasses);
  } else {
    energyClass = energyScaleClasses.find(({ max, min }) => inRange(specificHeatingDemand, min, max + 1));
  }
  return energyClass;
}

export function defineActualAndPotentialEnergyClasses({ before, after }) {
  const specificHeatingDemandBefore = get(before, 'result.specific_heating_demand', null);
  const specificHeatingDemandAfter = get(after || before, 'result.specific_heating_demand', null);

  const potentialEnergyClass = getEnergyClass(specificHeatingDemandAfter);
  const actualEnergyClass = getEnergyClass(specificHeatingDemandBefore);

  return { actualEnergyClass, potentialEnergyClass };
}

const CO2StatusProjectPlanSection = () => {
  const { t } = useTranslation();
  const { co2Calculations, energyLabels, setContextValues } = useFinalStepContext();

  const { actualEnergyClass, potentialEnergyClass } = useMemo(
    () =>
      defineActualAndPotentialEnergyClasses({
        before: co2Calculations.co2_emissions_before,
        after: co2Calculations.co2_emissions_after,
      }),
    [co2Calculations.co2_emissions_after, co2Calculations.co2_emissions_before],
  );

  useEffect(() => {
    const { before, after } = energyLabels;
    if (before.label !== actualEnergyClass.label || after.label !== potentialEnergyClass.label) {
      setContextValues({ energyLabels: { before: actualEnergyClass, after: potentialEnergyClass } });
    }
  }, [
    actualEnergyClass,
    actualEnergyClass.label,
    energyLabels,
    potentialEnergyClass,
    potentialEnergyClass.label,
    setContextValues,
  ]);

  return (
    <Row justify="center">
      <Col xs={12} md={8} lg={5}>
        <div className="card">
          <h3 className="mt-0">{t('co2CalculatorResult.energyScaleClasses.title')}</h3>
          <EnergyScaleClasses
            {...{ co2Calculations, actualEnergyClass, potentialEnergyClass, t, costOverviewTab: true }}
          />
        </div>
      </Col>
      <Col md={12} lg={7} className={s.energyBalanceCol}>
        <div className="card">
          <h3 className="mt-0">{t('co2CalculatorResult.potentialEnergyBalance.afterTitle')}</h3>
          <EcoTracker {...{ t, co2Calculations, type: 'after' }} />
        </div>
      </Col>
    </Row>
  );
};

export default CO2StatusProjectPlanSection;
