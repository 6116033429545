import React, { memo, useRef, useCallback } from 'react';
import get from 'lodash/get';
import { areEqualFields, scrollToNextMatchingSibling } from 'utils/form';
import s from './ImageSelect.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

function RadioItem({ imageSrc, img: Img, label, description, value, name, handleSelect, checked }) {
  return (
    <div className={s.radioItem} style={description && { alignSelf: 'flex-start' }}>
      <div className={s.wrapper}>
        <label className={s.imageLabel} htmlFor={`${name}.${value}`}>
          {imageSrc ? <img src={imageSrc} /> : <Img />}
          <div className={s.container}>
            <div className={s.round}>
              <input
                type="radio"
                id={`${name}.${value}`}
                name={name}
                value={value}
                checked={checked}
                onChange={handleSelect}
              />
              <label htmlFor={`${name}.${value}`} />
            </div>
          </div>
        </label>
      </div>
      <div className="ml-4">
        <label className={s.optionLabel}>{label}</label>
        {description ? (
          <div className="text-left">
            <small className="muted">{description}</small>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const ImageSelect = ({ form, options, field, label, sideEffectOnChange, skipScrollingToField }) => {
  const { errors, touched } = form;
  const { name, value } = field;
  const fieldWrapperRef = useRef();
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (e) => {
      const { value } = e.target;
      form.setFieldValue(name, value);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ value, form });
      if (value) scrollToNextMatchingSibling(fieldWrapperRef);
    },
    [form, name, sideEffectOnChange],
  );

  return (
    <div ref={fieldWrapperRef} className={classNames('field-wrapper', !skipScrollingToField ? 'scroll-to-field' : '')}>
      <label className={s.inlineSelectLabel}>{label}</label>
      <div className={s.radioGroup}>
        {options.map((option) => (
          <RadioItem key={option.value} {...{ ...option, name, handleSelect, checked: value === option.value }} />
        ))}
      </div>

      {get(errors, name) && get(touched, name) && <div className={s.errorSelect}>{t(get(errors, name))}</div>}
    </div>
  );
};

export default memo(ImageSelect, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['options']));
