import React, { useCallback, useMemo } from 'react';
import { cloneDeep, isEmpty, omit, pick, uniq } from 'lodash';
import LoadingOverlay from 'components/common/LoadingOverlay';
import {
  initialValuesCO2Calculator,
  whitelistedProps,
  co2CalculatorWhiteList,
  customEnergyRequirementWhiteList,
} from './ProjectDetailsCalculator.data';
import { useParams } from 'react-router-dom';
import useUpdateProjectMutation from 'hooks/calculator/useUpdateProjectMutation';
import CalculatorForm from 'components/CalculatorForm';
import useProject from 'hooks/project/useProject';
import { useTranslation } from 'react-i18next';
import { formatGraphqlErrors } from 'utils/helpers';
import GoHomeAlert from 'components/ProjectOverview/GoHomeAlert';
import { Col, Row } from 'react-grid-system';

const handleSpecificCategories = (_formData) => {
  const formData = cloneDeep(_formData);
  delete formData.type;

  if (formData.features.includes('new_windows')) {
    if (!Number.isFinite(formData.extra_large_windows_number)) {
      formData.extra_large_windows_number = 0;
    }
  }

  return formData;
};

export const useSubmitProjectDetailsCalculator = ({ projectId }) => {
  const updateProjectMutation = useUpdateProjectMutation();

  const onRecalculateProject = useCallback(
    async ({ values, isEditMode, currentStep, recalculate = true }) => {
      const features = Object.entries(values.features || {}).reduce((arr, [feature, isEnabled]) => {
        if (isEnabled) arr.push(feature);
        return arr;
      }, []);

      const formData: any = cloneDeep(
        omit(values, [...co2CalculatorWhiteList, ...customEnergyRequirementWhiteList, 'energyDemand']),
      );

      formData.features = uniq([...features]);
      if (features.includes('battery_storage') && !features.includes('solar_power_system')) {
        formData.features = formData.features.filter((f) => f !== 'battery_storage');
      }
      if (!features.includes('battery_storage')) {
        formData.battery_storage_size = null;
        formData.battery_storage_blackout_option = false;
      }

      const step = isEditMode ? null : currentStep + 1;
      formData.step = step;

      const _formData = handleSpecificCategories(formData);
      if (!recalculate) return _formData;

      await updateProjectMutation(projectId, formData);
    },
    [projectId, updateProjectMutation],
  );

  const onSubmit = useCallback(
    async ({ values, formikActions, isEditMode, currentStep, nextStep }) => {
      const { setSubmitting } = formikActions;
      setSubmitting(true);
      const formData = await onRecalculateProject({ values, isEditMode, currentStep, recalculate: false });
      const result = await updateProjectMutation(projectId, formData);

      if (result) {
        nextStep();
        window.scrollTo(0, 0);
      }
    },
    [onRecalculateProject, projectId, updateProjectMutation],
  );

  return { onSubmit, onRecalculateProject };
};

const getInitialValues = (project, calculations) => {
  if (isEmpty(project)) return {};

  const { form_values, ...whitelisted } = pick(project, whitelistedProps);
  const { co2_emissions_before, custom_energy_requirements } = calculations || {};

  const co2EmissionsFormValues = pick(co2_emissions_before?.form_values, co2CalculatorWhiteList);
  const customEnergyRequirements = pick(custom_energy_requirements, customEnergyRequirementWhiteList);

  const features = { ...project.features.reduce((acc, name) => ({ ...acc, [name]: true }), {}) };

  const initialValues = {
    ...initialValuesCO2Calculator,
    ...whitelisted,
    ...form_values,
    ...co2EmissionsFormValues,
    ...(customEnergyRequirements.length
      ? Object.entries(customEnergyRequirements).reduce((acc, [k, v]) => ({ ...acc, [k]: v.user_value }))
      : {}),
    features,
    hot_water_with_new_heating_system: true,
    energyDemand: false,
  };
  return initialValues;
};

const ProjectDetailsCalculator = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { onSubmit } = useSubmitProjectDetailsCalculator({ projectId });
  const { project, co2Calculations, loading, error } = useProject(projectId);
  const initialValues = useMemo(() => getInitialValues(project, co2Calculations), [co2Calculations, project]);

  if (loading) return <LoadingOverlay />;
  if (error) return <GoHomeAlert message={formatGraphqlErrors(error).message} />;

  if (!project) return <GoHomeAlert message={t('errors.projectNotFound')} />;

  return (
    <Row>
      <Col xs={12}>
        <CalculatorForm
          {...{ onSubmit, initialValues }}
          isEditMode={project.progress.step === null}
          step={project.progress.step}
        />
      </Col>
    </Row>
  );
};

export default ProjectDetailsCalculator;
