import Collapsible from 'react-collapsible';
import React, { useState } from 'react';
import { SectionWrapper } from 'components/common/heap';
import HeatingResult from './HeatingResult';
import HotWaterResult from './HotWaterResult';
import ElectricityResult from './ElectricityResult';
import s from '../CalculatorResultOverview.module.scss';
import { useTranslation } from 'react-i18next';

const AllCalculations = ({ co2Calculations, onUpdateCalculatedValue }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible
      open={isOpen}
      handleTriggerClick={() => setIsOpen(!isOpen)}
      transitionTime={800}
      trigger={
        <div style={{ margin: '25px 0' }}>
          <span>{t('co2CalculatorResult.shouldShowAllCalculations')}</span>
          <div className={s.showAllCalculations}>
            {t(`co2CalculatorResult.${isOpen ? 'hideCalculations' : 'showCalculations'}`)}
          </div>
        </div>
      }
    >
      <SectionWrapper>
        <SectionWrapper>
          <HeatingResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValue }} />
        </SectionWrapper>
        <SectionWrapper>
          <HotWaterResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValue }} />
        </SectionWrapper>
        <SectionWrapper>
          <ElectricityResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValue }} />
        </SectionWrapper>
      </SectionWrapper>
    </Collapsible>
  );
};

export default AllCalculations;
