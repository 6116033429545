import React, { RefObject, createContext, useCallback, useContext, useState } from 'react';

interface SalePrices {
  before: number;
  after: number;
  difference: number;
}

interface EnergyLabel {
  label: string;
  color: string;
}

interface EnergyLabels {
  before: EnergyLabel;
  after: EnergyLabel;
}

interface ChangeableValues {
  percentageCostsReduction?: number;
  ecoTrackerItems?: any[];
  projectId?: string;
  project?: any;
  projectPlan?: any;
  co2Calculations?: any;
  salePrices?: SalePrices;
  energyLabels?: EnergyLabels;
}

interface FinalStepValues extends ChangeableValues {
  setContextValues: (values: ChangeableValues) => void;
  formValuesRef: RefObject<any>;
  goToStep: (pageNumber: number) => void;
}

const defaultValues: FinalStepValues = {
  setContextValues: () => void 0,
  percentageCostsReduction: 1,
  projectId: '',
  ecoTrackerItems: [],
  project: null,
  projectPlan: null,
  co2Calculations: null,
  salePrices: { before: 0, after: 0, difference: 0 },
  energyLabels: { before: { label: 'E', color: '#F0C143' }, after: { label: 'C', color: '#CDEC4D' } },
  formValuesRef: null,
  goToStep: () => void 0,
};

const useContextState = () => {
  const [values, setValues] = useState({});

  const setContextValues = useCallback(
    (newValues: ChangeableValues) => {
      setValues({ ...values, ...newValues });
    },
    [values],
  );

  return { values, setContextValues };
};

export const FinalStepContext = createContext({ ...defaultValues });

export const FinalStepContextConsumer = FinalStepContext.Consumer;

export const useFinalStepContext = () => useContext(FinalStepContext);

interface IFinalStepContextProviderProps {
  children: React.ReactNode | React.ReactElement;
  value: Omit<FinalStepValues, 'setContextValues'>;
}

export const FinalStepContextProvider: FC<IFinalStepContextProviderProps> = ({ children, value }) => {
  const { values, setContextValues } = useContextState();
  return (
    <FinalStepContext.Provider value={{ ...value, ...values, setContextValues }}>{children}</FinalStepContext.Provider>
  );
};
