import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import s from './BreakEvenPointTrackerSection.module.scss';
import { Col, Row } from 'react-grid-system';
import BreakEvenPointForm from './BreakEvenPointForm';
import BreakEvenPointChart from './BreakEvenPointChart';
import { Formik, useFormikContext } from 'formik';
import { useFinalStepContext } from '../FinalTab/FinalStepContext';
import useCalculateBreakEvenPoint from 'hooks/useCalculateBreakEvenPoint';
import { BreakEvenPointFormValues } from './BreakEvenPointForm';
import { debounce, isEqual, isNumber, round } from 'lodash';

interface CalculateBreakEvenPointInput {
  equity: number;
  newEnergyCosts: number;
  oldEnergyCosts: number;
  period: number;
  governmentFunding: number;
  interestRate: number;
}

interface CalculateBreakEvenPointResponse {
  loanAmount: number;
  monthlyRate: number;
  breakEvenPoint: number;
}

const BreakEvenPointTracker = () => {
  const { project, ecoTrackerItems = [] } = useFinalStepContext();
  const [prevVariables, setPrevVariables] = useState<CalculateBreakEvenPointInput | null>(null);
  const { mutation } = useCalculateBreakEvenPoint(project._id);
  const { values } = useFormikContext<BreakEvenPointFormValues>();
  const [calculations, setCalculations] = useState<CalculateBreakEvenPointResponse>({
    loanAmount: project.price - values.equity - values.governmentFunding,
    monthlyRate: 0,
    breakEvenPoint: 0,
  });
  const ecoTrackerItem = useMemo(() => ecoTrackerItems.find(({ key }) => key === 'costs'), [ecoTrackerItems]);

  const callMutation = useCallback(
    async (input: CalculateBreakEvenPointInput) => {
      setPrevVariables(input);
      const data = await mutation(input);
      if (data) setCalculations(data);
    },
    [mutation],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const superDuperMethod = useCallback(debounce(callMutation, 2000), [callMutation]);

  useEffect(() => {
    const { equity, period, interestRate, governmentFunding } = values;
    const newEnergyCosts = round((ecoTrackerItem?.potentialValue || 0) / 12);
    const oldEnergyCosts = round((ecoTrackerItem?.actualValue || 0) / 12);
    const variables = { governmentFunding, equity, newEnergyCosts, oldEnergyCosts, period, interestRate };

    const isAllFieldsFilled = Object.values(variables).every(isNumber);
    if (!isAllFieldsFilled) return;

    if (!isEqual(prevVariables, variables)) {
      superDuperMethod(variables);
    }
  }, [
    ecoTrackerItem?.actualValue,
    ecoTrackerItem?.potentialValue,
    project.price,
    values,
    mutation,
    prevVariables,
    superDuperMethod,
    calculations.loanAmount,
  ]);

  return (
    <Row align="center">
      <Col lg={4} className={s.firstCol}>
        <BreakEvenPointForm {...{ loanAmount: calculations.loanAmount, monthlyRate: calculations.monthlyRate }} />
      </Col>
      <Col lg={8} className={s.secondCol}>
        <BreakEvenPointChart
          {...{ loanAmount: calculations.loanAmount, breakEvenPoint: calculations.breakEvenPoint }}
        />
      </Col>
    </Row>
  );
};

const BreakEvenPointTrackerContainer = () => {
  const { t } = useTranslation();
  const { project } = useFinalStepContext();

  const initialValues = useMemo(
    () => ({
      governmentFunding: project.price * 0.2,
      equity: 0,
      period: 20,
      interestRate: 5,
    }),
    [project.price],
  );

  const onSubmit = useCallback(() => void 0, []);

  return (
    <section className={s.section}>
      <h2 className="subtitle">{t('breakEvenPointTracker.title')}</h2>
      <Formik {...{ initialValues, onSubmit }}>{() => <BreakEvenPointTracker />}</Formik>
    </section>
  );
};

export default BreakEvenPointTrackerContainer;
