import React, { useCallback } from 'react';
import get from 'lodash/get';
import s from './RadioGroup.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

function RadioItem({ label, value, name, handleSelect, checked }) {
  return (
    <div className={s.radioItem}>
      <input type="radio" id={`${name}.${value}`} name={name} value={value} checked={checked} onChange={handleSelect} />
      <label className={s.optionLabel} htmlFor={`${name}.${value}`}>
        {label}
      </label>
    </div>
  );
}

const RadioGroup = ({ form, options, field, label, sideEffectOnChange }) => {
  const { errors, touched } = form;
  const { name } = field;
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (e) => {
      const { value } = e.target;
      form.setFieldValue(name, value);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ value, form });
    },
    [form, name, sideEffectOnChange],
  );

  return (
    <div className={classNames('field-wrapper', s.fieldWrapper)}>
      <label>{t(label)}</label>
      <div className={s.radioGroup}>
        {options.map(({ label, value }) => (
          <RadioItem key={value} {...{ value, label: t(label), name, handleSelect, checked: value === field.value }} />
        ))}
      </div>

      {get(errors, name) && get(touched, name) && <div className={s.errorSelect}>{get(errors, name)}</div>}
    </div>
  );
};

export default RadioGroup;
