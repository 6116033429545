import React from 'react';
import { Field } from 'formik';
import ButtonSelect from 'components/inputs/ButtonSelect';

const ThirdSection = ({ t }) => (
  <>
    <h2 className="text-primary text-left">{t('co2CalculatorForm.more_info_about_hot_water')}</h2>
    <Field
      label={t('co2CalculatorForm.hot_water_producer.name')}
      name="hot_water_producer"
      component={ButtonSelect}
      inOneRow={false}
      options={[
        {
          label: t('co2CalculatorForm.hot_water_producer.heating_system'),
          value: 'heating_system',
        },
        {
          label: t('co2CalculatorForm.hot_water_producer.electric_boiler'),
          value: 'electric_boiler',
        },
        {
          label: t('co2CalculatorForm.hot_water_producer.gas_heater'),
          value: 'gas_heater',
        },
        {
          label: t('co2CalculatorForm.hot_water_producer.heat_pump'),
          value: 'heat_pump',
        },
      ]}
    />
    <Field
      label={t('co2CalculatorForm.amount_of_hot_water.name')}
      name="amount_of_hot_water"
      component={ButtonSelect}
      description={t('co2CalculatorForm.amount_of_hot_water.description')}
      inOneRow
      options={[
        {
          label: t('co2CalculatorForm.amount_of_hot_water.low'),
          value: 'low',
        },
        {
          label: t('co2CalculatorForm.amount_of_hot_water.medium'),
          value: 'medium',
        },
        {
          label: t('co2CalculatorForm.amount_of_hot_water.hight'),
          value: 'hight',
        },
      ]}
    />
  </>
);

export default ThirdSection;
