import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import config from 'config/config';

export default () =>
  Sentry.init({
    dsn: config.sentryDSN,
    autoSessionTracking: true,
    ignoreErrors: ['ResizeObserver', 'Not authorized'],
    integrations: [
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
    ],
  });
