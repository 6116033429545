import React from 'react';
import theme from 'config/theme';
import cn from 'classnames';
import Loading from '../Loading';
import s from './Button.module.scss';
import { get } from 'lodash';

const themeToClassNameMap = {
  primary: s.primary,
  outline: s.outline,
  danger: s.danger,
  monochrome: s.monochrome,
};

const Button = ({
  children,
  className,
  color = 'primary',
  disabled,
  loading,
  type = 'button',
  onClick,
  startIcon,
  endIcon,
  style = {},
  ...rest
}: any) => {
  return (
    <button
      className={cn(s.btn, get(themeToClassNameMap, color, 'primary'), className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={style}
      {...rest}
    >
      <div
        className={cn(s.btnContent, (startIcon || endIcon) && s.btnContentWithIcon)}
        style={loading ? { visibility: 'hidden' } : {}}
      >
        {startIcon && <div>{startIcon}</div>}
        {children ? <div className={s.btnChildren}>{children}</div> : null}
        {endIcon && <div>{endIcon}</div>}
      </div>
      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={25} color={color === 'primary' ? 'white' : theme.primaryColor} />
        </div>
      ) : null}
    </button>
  );
};

export default Button;
