import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { ReactComponent as LocationMarker } from 'assets/icons/location-marker.svg';
import { GoogleMap } from '@react-google-maps/api';

const MapContainer = ({ position }) => {
  return (
    <GoogleMap mapContainerStyle={{ width: '100%', height: '370px', position: 'static' }} center={position} zoom={15}>
      <LocationMarker {...position} />
    </GoogleMap>
  );
};

export default memo(MapContainer, isEqual);
