import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Row, Col, Visible } from 'react-grid-system';
import { formatCurrency, formatValue } from 'utils/helpers';
import s from './ProjectPlanTables.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CircleCross } from 'assets/icons/circle-cross.svg';
import { ReactComponent as Circle } from 'assets/icons/circle.svg';
import _, { cloneDeep, get, isNil, round } from 'lodash';
import { useFinalStepContext } from 'components/CalculatorForm/FinalTab/FinalStepContext';
import 'rc-tooltip/assets/bootstrap.css';
import TableScrollButtons from 'components/common/HorizontalScrollButtons/TableScrollButtons';
import Tooltip from './Tooltip';
import { useSubmitProjectDetailsCalculator } from 'pages/ProjectDetailsCalculator';

export type Category = {
  label: string;
  name: string;
  price: number;
  priceLabel: string;
  savingEnergyCosts: number;
  co2Savings: number;
  savingEnergyCostsLabel: string;
  co2SavingsLabel: string;
  tooltipCosts?: string;
  tooltipCO2?: string;
};

type InitialCategory = {
  label: string;
  name: string;
  user_price: number | null;
  calculated_price: number;
  category: string;
  costs: string;
  savingEnergyCosts: number;
  co2Savings: number;
};

const category2CO2SavingsField = [
  { renovation: 'insulation_facade', categories: ['facade_insulation'] },
  { renovation: 'new_windows', categories: ['new_windows'] },
  { renovation: 'insulation_basement_ceiling', categories: ['insulation_basement_ceiling'] },
  { renovation: 'heating_system', categories: ['heating_system'] },
  { renovation: 'solar_power_system', categories: ['solar_power_system'] },
  { renovation: 'controlled_living_space_ventilation', categories: ['domestic_ventilation'] },
  {
    renovation: 'insulation_top_ceiling',
    categories: ['insulation_top_ceiling', 'roof'],
  },
];

function getPlanningCategories({ categories, energy_renovations, t }) {
  const rows = categories.map((category: InitialCategory) => {
    const { label, user_price, calculated_price, name } = category;
    const saving = category2CO2SavingsField.find(({ categories }) => categories.includes(category.name));
    const co2Value = get(energy_renovations, `${saving?.renovation}.co2_saving`, 0);
    const co2Savings = round(co2Value, 2);

    const energyCosts = get(energy_renovations, `${saving?.renovation}.saving_energy_costs`, 0);
    let tooltipCosts = '';
    let tooltipCO2 = '';
    const price = !isNil(user_price) ? user_price : calculated_price;
    let savingEnergyCostsLabel = formatCurrency(energyCosts);

    const isBatteryStorage = name === 'battery_storage';

    if (!energyCosts) {
      savingEnergyCostsLabel = t(`plan.tableOverview.noSavings`);
      tooltipCosts = t(`plan.tableOverview.noReduction`);
    }

    let co2SavingsLabel = `${formatValue(co2Savings)} kg`;
    if (!co2Savings) {
      co2SavingsLabel = t(`plan.tableOverview.noSavings`);
      tooltipCO2 = t(`plan.tableOverview.${isBatteryStorage ? 'batteryStorage.noCO2Savings' : 'noReduction'}`);
    }

    return {
      label: t(label),
      price,
      priceLabel: formatCurrency(price),
      co2SavingsLabel,
      co2Savings,
      savingEnergyCostsLabel,
      savingEnergyCosts: energyCosts,
      name,
      tooltipCosts,
      tooltipCO2,
    };
  });
  const roofCategoryIdx = rows.findIndex(({ name }) => name === 'roof');
  const topCeilingCategoryIdx = rows.findIndex(({ name }) => name === 'insulation_top_ceiling');
  if (roofCategoryIdx !== -1 && topCeilingCategoryIdx !== -1) {
    const el = rows[roofCategoryIdx];
    const tooltip = t('plan.tableOverview.roofSavingsArePartOfTopCeiling');
    const label = t('plan.tableOverview.noSavings');
    rows[roofCategoryIdx] = {
      ...el,
      savingEnergyCosts: 0,
      savingEnergyCostsLabel: label,
      co2SavingsLabel: label,
      tooltipCosts: tooltip,
      tooltipCO2: tooltip,
      co2Savings: 0,
    };
  }
  return rows;
}

function calculateTotal(arr, key) {
  const total = arr.reduce((acc, item) => acc + item[key], 0);
  return total;
}

const ProjectPlanTables = () => {
  const { projectPlan, co2Calculations, formValuesRef, projectId } = useFinalStepContext();
  const { onRecalculateProject } = useSubmitProjectDetailsCalculator({ projectId });
  const { t } = useTranslation();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [allCategories] = useState(get(projectPlan, 'planningCategories', []));

  const enhancedCategories: Category[] = useMemo(() => {
    const { energy_renovations } = co2Calculations;
    const categories = cloneDeep(allCategories);

    const sortedCategories = getPlanningCategories({ categories, energy_renovations, t }).sort(
      (a, b) => b.co2Savings - a.co2Savings,
    );
    const batteryStorageCategoryIdx = sortedCategories.findIndex(({ name }) => name === 'battery_storage');
    if (batteryStorageCategoryIdx !== -1) {
      const batteryStorageCategory = sortedCategories[batteryStorageCategoryIdx];
      sortedCategories.splice(batteryStorageCategoryIdx, 1);
      sortedCategories.push(batteryStorageCategory);
    }
    return sortedCategories;
  }, [allCategories, co2Calculations, t]);

  const [selectedCategories, setSelectedCategories] = useState<Category[]>(enhancedCategories);

  const onRecalculateClick = useCallback(
    async (newCategories: Category[]) => {
      const newFeatures = newCategories.map(({ name }) => name).reduce((acc, key) => ({ ...acc, [key]: true }), {});
      formValuesRef.current.features = newFeatures;
      await onRecalculateProject({
        values: formValuesRef.current,
        isEditMode: true,
        currentStep: null,
      });
    },
    [formValuesRef, onRecalculateProject],
  );

  const toggleCategory = useCallback(
    async (categoryName: string) => {
      const category = selectedCategories.find(({ name }) => name === categoryName);
      let newCategories: Category[] = [];
      if (category) {
        newCategories = selectedCategories.filter(({ name }) => name !== categoryName);
      } else {
        const newCategory = enhancedCategories.find(({ name }) => name === categoryName);
        newCategories = [newCategory, ...selectedCategories];
      }
      setSelectedCategories(newCategories);
      await onRecalculateClick(newCategories);
    },
    [enhancedCategories, onRecalculateClick, selectedCategories],
  );

  const totalCosts = useMemo(() => calculateTotal(selectedCategories, 'price'), [selectedCategories]);
  const totalSavings = useMemo(() => calculateTotal(selectedCategories, 'savingEnergyCosts'), [selectedCategories]);
  const totalCO2Savings = useMemo(() => round(calculateTotal(selectedCategories, 'co2Savings')), [selectedCategories]);

  return (
    <Row className="mb-4">
      <Col xs={12}>
        <h2 className="subtitle">{t('plan.totalCosts')}</h2>
        {/* <p className="subtitle-description text-secondary">{t('plan.costsAndSavingsOfMeasures')}</p> */}
        <div style={{ overflowX: 'scroll', position: 'relative' }} ref={tableWrapperRef}>
          <table className="table">
            <thead>
              <tr>
                <th />
                <th className="text-left">{t('plan.tableOverview.headers.category')}</th>
                <th>{t('plan.tableOverview.headers.price')}</th>
                <th>{t('plan.tableOverview.headers.savingEnergyCosts')}</th>
                <th>{t('plan.tableOverview.headers.co2Savings')}</th>
              </tr>
            </thead>

            <tbody>
              {enhancedCategories.map((category, i) => {
                const {
                  label,
                  name,
                  priceLabel,
                  co2Savings,
                  savingEnergyCosts,
                  savingEnergyCostsLabel,
                  co2SavingsLabel,
                  tooltipCosts,
                  tooltipCO2,
                } = category;
                const show = !!selectedCategories.find((selectedCategory) => name === selectedCategory.name);
                const isFirstRow = i === 0;
                return (
                  <tr key={label} className={isFirstRow ? s.highlightedRow : ''}>
                    <td>
                      {show ? (
                        <CircleCross className="cursor-pointer" onClick={() => toggleCategory(name)} />
                      ) : (
                        <Circle className="cursor-pointer" onClick={() => toggleCategory(name)} />
                      )}
                    </td>
                    <td>{label}</td>
                    <td className="text-right">{priceLabel}</td>
                    <td className="text-right">
                      {!savingEnergyCosts && tooltipCosts ? (
                        <Tooltip overlay={tooltipCosts} body={savingEnergyCostsLabel} placement="right" />
                      ) : (
                        savingEnergyCostsLabel
                      )}
                    </td>

                    <td className="text-right">
                      {!co2Savings && tooltipCO2 ? (
                        <Tooltip overlay={tooltipCO2} body={co2SavingsLabel} placement="left" />
                      ) : (
                        co2SavingsLabel
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td />
                <td>{t('plan.total')}</td>
                <td style={{ textAlign: 'end' }}>{formatCurrency(totalCosts)}</td>
                <td style={{ textAlign: 'end' }}>{formatCurrency(totalSavings)}</td>
                <td style={{ textAlign: 'end' }}>{`${formatValue(totalCO2Savings)} kg`}</td>
              </tr>
            </tfoot>
          </table>
          <Visible xs sm>
            <TableScrollButtons {...{ tableWrapperRef }} />
          </Visible>
        </div>
      </Col>
    </Row>
  );
};

export default ProjectPlanTables;
