import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toastErrorMessage } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const REQUEST_PROJECT_PDF = gql`
  mutation requestProjectPDF($input: RequestProjectPDFInput!, $projectId: ID!) {
    requestProjectPDF(input: $input, projectId: $projectId)
  }
`;

const useRequestProjectPDF = (hideModal) => {
  const { t } = useTranslation();
  const [requestProjectPdf] = useMutation(REQUEST_PROJECT_PDF);

  const mutate = useCallback(
    async (variables) => {
      try {
        await requestProjectPdf({ variables });
        hideModal();
        toast.success(t('plan.successMessage'));
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [hideModal, requestProjectPdf, t],
  );

  return mutate;
};

export default useRequestProjectPDF;
