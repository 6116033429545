import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import EnergyGhgEquivalents from './EnergyGhgEquivalents';
import EnergyReductionItems from './EcoTrackerItem/EcoTracker';
import { useTranslation } from 'react-i18next';
import { defineActualAndPotentialEnergyClasses } from './CO2StatusProjectPlanSection';

const EnergyReductionAndGhgEquivalents = ({ co2Calculations }) => {
  const { t } = useTranslation();

  const { actualEnergyClass, potentialEnergyClass } = useMemo(
    () =>
      defineActualAndPotentialEnergyClasses({
        before: co2Calculations?.co2_emissions_before,
        after: co2Calculations?.co2_emissions_potential,
      }),
    [co2Calculations],
  );

  return (
    <>
      <Row className="mb-4">
        <Col lg={8}>
          <EnergyReductionItems {...{ co2Calculations, t, actualEnergyClass, potentialEnergyClass }} />
        </Col>
        <Col lg={12}>
          <EnergyGhgEquivalents {...{ co2Calculations }} />
        </Col>
      </Row>
    </>
  );
};

export default EnergyReductionAndGhgEquivalents;
