import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { CO2_CALCULATIONS_FRAGMENT } from 'graphql/fragments/co2Calculations';

export const PROJECT_PLAN_FRAGMENT = gql`
  fragment projectPlan on ProjectPlan {
    price
    planningCategories {
      name
      label
      description
      calculated_price
      user_price
      equipment_type
    }
  }
`;

export const PROJECT_OVERVIEW_QUERY = gql`
  query projectOverview($projectId: ID!) {
    projectPlan(projectId: $projectId) {
      ...projectPlan
    }
    project(id: $projectId) {
      _id
      price
      type
      name
      form_values {
        ... on RenovationHouseFormValues {
          battery_storage_size
        }
      }
    }
    co2Calculations(projectId: $projectId) {
      ...co2CalculationsFragment
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
  ${CO2_CALCULATIONS_FRAGMENT}
`;

const useProjectOverview = (projectId) => {
  const { data, loading, error } = useQuery(PROJECT_OVERVIEW_QUERY, { variables: { projectId } });
  const projectPlan = get(data, 'projectPlan', null);
  const project = get(data, 'project', null);
  const co2Calculations = get(data, 'co2Calculations', null);

  return { projectPlan, project, co2Calculations, loading, error };
};

export default useProjectOverview;
