import React from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import AppLayout from 'components/layout/AppLayout';
import CreateProject from 'pages/CreateProject';
import ErrorPage from 'pages/ErrorPage';
import ProjectDetailsCalculator from 'pages/ProjectDetailsCalculator';
import { createMemoryRouter, RouterProvider } from 'react-router-dom';
import routePaths from './routePaths';
import TermsOfUse from 'pages/TermsOfUse';

const routes = [
  {
    path: routePaths.newProject,
    element: <CreateProject />,
    loader: () => <LoadingOverlay />,
    errorElement: <ErrorPage />,
  },
  {
    path: routePaths.project,
    element: <ProjectDetailsCalculator />,
    loader: () => <LoadingOverlay />,
    errorElement: <ErrorPage />,
  },
  {
    path: routePaths.termsOfUse,
    element: <TermsOfUse />,
    loader: () => <LoadingOverlay />,
    errorElement: <ErrorPage />,
  },
];
const router = createMemoryRouter(routes, {
  initialEntries: [routePaths.newProject],
  initialIndex: 1,
});

const RootRouter = () => {
  return (
    <AppLayout>
      <RouterProvider router={router} />
    </AppLayout>
  );
};

export default RootRouter;
