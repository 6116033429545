import React from 'react';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import RootRouter from './router/RootRouter';
import apolloClient from './graphql/apollo';
import './styles/index.scss';
import startup from 'startup';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';

startup();

function FallbackComponent() {
  return <div>An error has occured</div>;
}

const App: React.FunctionComponent = () => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <ApolloProvider client={apolloClient}>
        <RootRouter />
        <ToastContainer />
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
