import { isNil } from 'lodash';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { areEqualFields, onKeyDown } from 'utils/form';

const TextInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  endIcon,
  skipScrollingToField,
  lowerCase = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { label, type = 'text', style } = props;
  const { value, name } = field;
  const getClassNameForInput =
    touched[field.name] && errors[field.name] ? 'error-input default-input' : 'default-input';
  const fieldWrapperRef = useRef();

  const handleKeyDown = useCallback(
    (e) => {
      if (value) onKeyDown(e, fieldWrapperRef);
    },
    [value],
  );

  const handleChange = useCallback(
    (e) => {
      let v = e.target.value;
      if (lowerCase) v = v.toLowerCase();
      setFieldValue(name, v);
    },
    [lowerCase, setFieldValue, name],
  );

  const fieldWrapperClassnames = useMemo(() => {
    let classes = 'field-wrapper';
    if (!skipScrollingToField) classes += ' scroll-to-field';
    return classes;
  }, [skipScrollingToField]);

  const inputValue = useMemo(() => {
    if (lowerCase) return value.toLowerCase();
    return !isNil(value) ? value : '';
  }, [lowerCase, value]);

  return (
    <div className={fieldWrapperClassnames} style={style} ref={fieldWrapperRef}>
      {endIcon ? <span style={{ position: 'absolute', right: '15px', bottom: '33px' }}>{endIcon}</span> : null}
      <input
        {...field}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={inputValue}
        type={type}
        autoComplete="off"
        className={getClassNameForInput}
        id={name}
        style={endIcon ? { paddingRight: '40px' } : {}}
      />
      <label className={value ? 'label-when-focused-input' : 'text-label'} htmlFor={name} id={`label-${name}`}>
        {label}
      </label>
      {errors[name] && touched[name] && <div className="text-danger">{t(errors[name])}</div>}
    </div>
  );
};

export default memo(TextInput, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['type']));
