import isEqual from 'lodash/isEqual';
import { get } from 'lodash';
import { animateScroll } from 'react-scroll';

export const getIndexElementFromArray = ({ array, searchedValue }: any) =>
  array.findIndex((element: any) => element === searchedValue);

export const areEqualFields = (prevProps: any, nextProps: any, additionalKeys = []) => {
  const keys = ['field', 'form.errors', 'form.touched', ...additionalKeys];
  const res = keys.every((key) => isEqual(get(prevProps, key), get(nextProps, key)));
  return res;
};

function scrollToElement($targetElement: any) {
  const coordinates = $targetElement.getBoundingClientRect();
  animateScroll.scrollTo(coordinates.top + window.pageYOffset - 300);
}

function focusInputElement($targetElement: any) {
  const $inputElement = $targetElement.querySelector('input[type="text"], input[type="number"]');
  if ($inputElement) $inputElement.focus();
}

function getMatchingSibling($elem: any, selector: any): any {
  const $nextSibling = $elem.nextSibling;
  if (!$nextSibling) return null;
  if ($nextSibling.matches(selector)) return $nextSibling;

  const $deepMatchingElem = $nextSibling.querySelector(selector);
  if ($deepMatchingElem) return $deepMatchingElem;

  return getMatchingSibling($nextSibling, selector);
}

function getMatchingParent(currentElement: any, selector: any): any {
  if (currentElement.parentElement.tagName === 'FORM') return null;
  const { parentElement } = currentElement;
  const $nextMatchingField = getMatchingSibling(parentElement, selector);
  return $nextMatchingField || getMatchingParent(parentElement, selector);
}

export function scrollToNextMatchingSibling(ref: any) {
  const $currentElement = ref.current;
  if (!$currentElement) return;

  const selector = 'div[class*="scroll-to-field"]';
  const $nextMatchingFieldWrapper =
    getMatchingSibling($currentElement, selector) || getMatchingParent($currentElement, selector);

  let $targetElement;
  if ($nextMatchingFieldWrapper) {
    $targetElement = $nextMatchingFieldWrapper;
  } else {
    $targetElement = document.querySelector('button[type="submit"]');
  }
  if (!$targetElement) return;

  scrollToElement($targetElement);
  focusInputElement($targetElement);
}

export const onKeyDown = (keyEvent: any, fieldWrapperRef: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
    scrollToNextMatchingSibling(fieldWrapperRef);
  }
};
