import { debounce, isNil } from 'lodash';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { areEqualFields, onKeyDown } from 'utils/form';
import s from './SliderWithTooltip.module.scss';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

const Input = ({
  form,
  name,
  min,
  max,
  units,
  value,
  setValue,
  sliderValue,
  thousandSeparator,
  sideEffectOnChange,
  changeableStep,
  setChangeableStep,
  fieldWrapperRef,
}) => {
  const { i18n } = useTranslation();
  const inputRef = useRef();
  const [, forceRerender] = useState(false);

  useEffect(() => {
    if (sliderValue !== value) {
      setValue(sliderValue);
    }
  }, [setValue, sliderValue, value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((_values) => {
      let v;
      if (isNil(_values.floatValue)) {
        v = null;
      } else {
        v = _values.floatValue > max ? max : _values.floatValue;
      }
      setValue(v);

      if (typeof sideEffectOnChange === 'function') sideEffectOnChange(v);
      if (Number.isFinite(v) && v >= min && v <= max) form.setFieldValue(name, v);

      forceRerender((b) => !b);
    }, 500),
    [max, setValue],
  );

  const handleFocus = useCallback(
    (e) => {
      e.target.focus();
      if (changeableStep) {
        setChangeableStep(false);
      }
    },
    [changeableStep, setChangeableStep],
  );

  const handleBlur = useCallback(() => {
    form.setFieldTouched(name, true);
  }, [form, name]);

  const handleKeyDown = useCallback(
    (e) => {
      onKeyDown(e, fieldWrapperRef);
    },
    [fieldWrapperRef],
  );

  return (
    <div className={s.inputWrapper}>
      <label htmlFor={`${name}-number-input`}>
        <NumberFormat
          ref={inputRef}
          max={max}
          value={value}
          allowNegative={false}
          allowLeadingZeros={false}
          allowEmptyFormatting={false}
          {...(!isNil(thousandSeparator) ? { thousandSeparator } : config[i18n.language])}
          name={`${name}-number-input`}
          autoComplete="off"
          id={`${name}-number-input`}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          className={s.numberInput}
          type="text"
          onValueChange={handleChange}
          onBlur={handleBlur}
        />
      </label>
      <div className={s.units}>{units}</div>
    </div>
  );
};

export default memo(Input, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['value', 'sliderValue']));
