import React, { useCallback, useMemo } from 'react';
import StepWizard from 'react-step-wizard';
import useFormValuesRef from 'hooks/useFormValuesRef';
import CalculatorFourthTab from './CalculatorFourthTab';
import CalculatorFifthTab from './CalculatorFifthTab';
import CalculatorFirstStep from './CalculatorFirstTab';
import Nav from './Nav';
import CalculatorThirdTab from './CalculatorThirdTab';
import CalculatorSecondTab from './CalculatorSecondTab';
import CalculatorFinalTab from './FinalTab/CalculatorFinalTab';
import { pick } from 'lodash';
import { customEnergyRequirementWhiteList } from 'pages/ProjectDetailsCalculator.data';

const formValueMock = {
  name: 'My house',
  country: 'DE',
  heated_living_area: 80,
  kind_of_house: 'single_family_house',
  energy_standard: 'between_1960_and_1975',
  renovations: [],
  is_solar_heating_system: false,
  solar_heating_system_type: 'heating_and_hot_water',
  number_of_people: 4,
  heating_system: 'natural_gas',
  age_of_heating_system: 'older_than_20_years',
  temperature: 'less_than_21',
  hot_water_producer: 'heating_system',
  amount_of_hot_water: 'medium',
  are_devices_less_than_10_years_old: true,
  power_consumers: [],
  is_solar_power_system: false,
  solar_power_system_size: null,
  full_address: 'Kyiv',
  coordinates: {
    lat: 10,
    lng: 10,
  },
};

function checkRenderingSecondTab(values) {
  return Object.values(pick(values, customEnergyRequirementWhiteList)).some((n) => n > 0);
}

const withStyles = (Component) => {
  const WihStylesComponent = (props) => {
    return (
      <div style={{ paddingBottom: '24px' }}>
        <Component {...props} />
      </div>
    );
  };
  return WihStylesComponent;
};

const CalculatorForm = ({ initialValues, onSubmit, isEditMode, step }) => {
  const formValuesRef = useFormValuesRef({ formValueMock, initialValues });
  const [shouldRenderSecondTab, setShouldRenderSecondTab] = React.useState<boolean>(false);

  const tabs = useMemo(
    () => [
      { component: withStyles(CalculatorFirstStep), label: 'project.tabs.firstTab' },
      ...(shouldRenderSecondTab
        ? [{ component: withStyles(CalculatorSecondTab), label: 'project.tabs.secondTab' }]
        : []),
      { component: withStyles(CalculatorThirdTab), label: 'project.tabs.thirdTab' },
      { component: withStyles(CalculatorFourthTab), label: 'project.tabs.fourthTab' },
      { component: withStyles(CalculatorFifthTab), label: 'project.tabs.fifthTab' },
      { component: withStyles(CalculatorFinalTab), label: 'project.tabs.finalTab' },
    ],
    [shouldRenderSecondTab],
  );

  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
      const shouldRender = checkRenderingSecondTab(formValuesRef.current);
      if (shouldRender !== shouldRenderSecondTab) setShouldRenderSecondTab(shouldRender);
    },
    [formValuesRef, shouldRenderSecondTab],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <StepWizard initialStep={step} transitions={{}} isLazyMount nav={<Nav isEditMode={isEditMode} tabs={tabs} />}>
      {tabs.map(({ label, component: C }) => (
        <C key={label} {...{ onSubmitStep, onBack, formValuesRef, initialValues, onSubmit, isEditMode }} />
      ))}
    </StepWizard>
  );
};

export default CalculatorForm;
