import gql from 'graphql-tag';

export default gql`
  fragment renovationHouseFormValues on RenovationHouseFormValues {
    house_area
    solar_power_system_size_installation
    floors_number
    new_windows_number
    lift_and_slide_doors_number
    extra_large_windows_number
    facade_insulation_type
    facade_insulation_area
    front_door
    roof_type
    roof_area
    heating_system_for_renovation
    insulation_top_ceiling_area
    insulation_basement_ceiling_area
    battery_storage_blackout_option

    domestic_ventilation_equipment_type
    facade_insulation_equipment_type
    roof_equipment_type
    battery_storage_size
    solar_power_system_equipment_type
    front_door_equipment_type
    heating_system_equipment_type
    new_windows_equipment_type
  }
`;
