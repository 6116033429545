import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { PROJECT_OVERVIEW_QUERY } from '../projectPlan/useProjectOverview';
import { toastErrorMessage } from 'utils/helpers';

const UPDATE_PROJECT_RENOVATION_HOUSE_MUTATION = gql`
  mutation updateProjectRenovationHouse($projectId: ID!, $formData: UpdateProjectRenovationHouseInput!) {
    updateProjectRenovationHouse(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectMutation = () => {
  const [updateProjectRenovationHouseMutation] = useMutation(UPDATE_PROJECT_RENOVATION_HOUSE_MUTATION);

  const mutate = useCallback(
    async (projectId, formData) => {
      try {
        const {
          data: { updateProjectRenovationHouse: updatedProject },
        } = await updateProjectRenovationHouseMutation({
          variables: { projectId, formData: { ...formData } },
          refetchQueries: [{ query: PROJECT_OVERVIEW_QUERY, variables: { projectId } }],
          awaitRefetchQueries: true,
        });

        return updatedProject;
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [updateProjectRenovationHouseMutation],
  );
  return mutate;
};

export default useUpdateProjectMutation;
