import React, { useEffect, useMemo } from 'react';
import { ReactComponent as CO2Icon } from 'assets/icons/calculator/co2.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/calculator/lightning.svg';
import { ReactComponent as EuroIcon } from 'assets/icons/calculator/euro.svg';
import { clamp, get, round } from 'lodash';
import { formatValue } from 'utils/helpers';
import s from './EcoTracker.module.scss';
import { useFinalStepContext } from 'components/CalculatorForm/FinalTab/FinalStepContext';

export const getUserInputOrCalculatedValue = (obj) => {
  return Number.isFinite(obj.user_input) ? obj.user_input : obj.calculated_value;
};

function getEcoTrackerItems(calculations, type) {
  const prop = {
    potential: 'co2_emissions_potential.result',
    after: 'co2_emissions_after.result',
  };
  const potentialValue = get(calculations, prop[type], {});
  const {
    co2_emissions_before: { result: co2BeforeResult },
  } = calculations;

  return [
    {
      icon: EuroIcon,
      key: 'costs',
      label: 'co2CalculatorResult.potentialEnergyBalance.totalEnergyCosts.label',
      valueLabel: 'co2CalculatorResult.potentialEnergyBalance.totalEnergyCosts.value',
      actualValue: getUserInputOrCalculatedValue(co2BeforeResult.total_energy_costs),
      potentialValue: getUserInputOrCalculatedValue(
        potentialValue.total_energy_costs || co2BeforeResult.total_energy_costs,
      ),
    },
    {
      icon: LightningIcon,
      key: 'energy',
      label: 'co2CalculatorResult.potentialEnergyBalance.totalEnergyBalancePerYear.label',
      valueLabel: 'co2CalculatorResult.potentialEnergyBalance.totalEnergyBalancePerYear.value',
      actualValue: getUserInputOrCalculatedValue(co2BeforeResult.total_energy_balance_per_year),
      potentialValue: getUserInputOrCalculatedValue(
        potentialValue.total_energy_balance_per_year || co2BeforeResult.total_energy_balance_per_year,
      ),
    },
    {
      icon: CO2Icon,
      key: 'co2',
      label: 'co2CalculatorResult.potentialEnergyBalance.ghgBalance.label',
      valueLabel: 'co2CalculatorResult.potentialEnergyBalance.ghgBalance.value',
      actualValue: getUserInputOrCalculatedValue(co2BeforeResult.energy_ghg_balance_per_year),
      potentialValue: getUserInputOrCalculatedValue(
        potentialValue.energy_ghg_balance_per_year || co2BeforeResult.energy_ghg_balance_per_year,
      ),
    },
  ];
}

const EcoTrackerItem = ({ t, label, icon: Icon, actualValue, diff, potentialValue, valueLabel, percentageDiff }) => {
  const lineWidth = useMemo(() => clamp(percentageDiff, 0, 100), [percentageDiff]);
  const formattedDiff = useMemo(() => (diff < 0 ? 0 : formatValue(-diff)), [diff]);
  const percentageValue = useMemo(() => (percentageDiff > 100 ? 0 : `-${100 - percentageDiff}`), [percentageDiff]);

  return (
    <div className={s.item}>
      <div className={s.circle}>
        <Icon width="100%" height="100%" />
      </div>

      <div className={s.info}>
        <div className={s.topItem}>
          <p className={s.label}>{t(label)}</p>
          <div className={s.wrapper}>
            <div className={s.barChart} style={{ backgroundColor: '#F0C143' }}>
              <div style={{ backgroundColor: '#CDEC4D', width: `${lineWidth}%` }} className={s.potentialValue} />
            </div>
            <div className={s.percentage}>{percentageValue} %</div>
          </div>
        </div>

        <div className={s.bottomItem}>
          <div className={s.actual}>
            <span>{`${t('co2CalculatorResult.energyScaleClasses.actualClass')}: `}</span>
            <span>{`${t(valueLabel, { value: formatValue(round(actualValue)) })}`}</span>
          </div>
          <div className={s.potential}>
            <span>{`${t('co2CalculatorResult.energyScaleClasses.potentialClass')}: `}</span>
            <span>{`${t(valueLabel, { value: formatValue(round(potentialValue)) })}`}</span>
          </div>
          <b className={s.diff}>{t(valueLabel, { value: formattedDiff })}</b>
        </div>
      </div>
    </div>
  );
};

export default function EcoTracker({ co2Calculations, t, type = 'potential' }) {
  const { ecoTrackerItems = [], setContextValues } = useFinalStepContext();
  const items = useMemo(() => getEcoTrackerItems(co2Calculations, type), [co2Calculations, type]);

  const enhancedEcoTrackerItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
      percentageDiff: round((item.potentialValue / item.actualValue) * 100),
      diff: round(item.actualValue - item.potentialValue),
    }));
  }, [items]);

  useEffect(() => {
    if (
      typeof setContextValues === 'function' &&
      ecoTrackerItems[0]?.potentialValue !== enhancedEcoTrackerItems[0]?.potentialValue
    ) {
      const { potentialValue, actualValue } = enhancedEcoTrackerItems.find((item) => item.key === 'costs');
      const percentageCostsReduction = 100 - round((potentialValue / actualValue) * 100);
      setContextValues({ ecoTrackerItems: enhancedEcoTrackerItems, percentageCostsReduction });
    }
  }, [ecoTrackerItems, enhancedEcoTrackerItems, setContextValues]);

  return (
    <div className={s.potentialValues}>
      {enhancedEcoTrackerItems.map((item) => {
        return <EcoTrackerItem key={item.label} {...{ ...item, t }} />;
      })}
    </div>
  );
}
