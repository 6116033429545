import gql from 'graphql-tag';
import PROJECT_FORM_VALUES from 'graphql/fragments/projectFormValues';

export const PROJECT_FRAGMENT = gql`
  fragment project on Project {
    _id
    pdf
    zip
    name
    price
    budget
    features
    form_values {
      ... on RenovationHouseFormValues {
        ...renovationHouseFormValues
      }
    }
    progress {
      project_details
      step
    }
  }
  ${PROJECT_FORM_VALUES}
`;
