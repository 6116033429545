import React from 'react';
import { ReactComponent as ArrowForward } from 'assets/icons/circle-arrow-forward.svg';
import { ReactComponent as ExclamationTriangle } from 'assets/icons/exclamation-triangle.svg';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import { Row, Col } from 'react-grid-system';
import PageTitle from 'components/common/PageTitle';
import s from './WarningMessage.module.scss';

const WarningMessage = ({
  title,
  message,
  btnLabel,
  btnLink,
  disabledBtn = false,
  onBtnClick,
  btnEndIcon = <ArrowForward />,
  btnStartIcon = null,
}: any) => (
  <div className={s.warningMessage}>
    <Row>
      <Col md={12}>
        <PageTitle className={s.title}>{title}</PageTitle>
      </Col>
    </Row>
    <Row>
      <Col md={12} className={s.wrapper}>
        <ExclamationTriangle width="40px" height="40px" />
        <p className={s.message}>{message}</p>
        {btnLink ? (
          <Link to={btnLink}>
            <Button loading={disabledBtn} disabled={disabledBtn} endIcon={btnEndIcon} startIcon={btnStartIcon}>
              {btnLabel}
            </Button>
          </Link>
        ) : null}
        {typeof onBtnClick === 'function' ? (
          <Button
            loading={disabledBtn}
            disabled={disabledBtn}
            onClick={onBtnClick}
            endIcon={btnEndIcon}
            startIcon={btnStartIcon}
          >
            {btnLabel}
          </Button>
        ) : null}
      </Col>
    </Row>
  </div>
);

export default WarningMessage;
