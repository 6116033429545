import React, { useCallback, useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import RTooltip from 'rc-tooltip';

const Tooltip = ({ overlay, body, placement }: { overlay: string; body: string; placement: string }) => {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = useCallback((v: boolean) => {
    setVisible(v);
  }, []);

  return (
    <RTooltip
      showArrow={false}
      mouseEnterDelay={0}
      mouseLeaveDelay={0.1}
      destroyTooltipOnHide
      trigger="click"
      {...{ visible, onVisibleChange, placement }}
      overlay={<div>{overlay}</div>}
      motion={{ motionName: 'rc-tooltip-zoom' }}
    >
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '8px',
          color: visible ? '#242020' : '#B3B8B9',
        }}
      >
        <span>{body}</span> <InfoIcon style={{ cursor: 'pointer', minWidth: '20px' }} />
      </div>
    </RTooltip>
  );
};

export default Tooltip;
