import React, { useCallback } from 'react';
import cn from 'classnames';
import s from './Nav.module.scss';
import { useTranslation } from 'react-i18next';

const emptyFunc = (i) => {
  return undefined;
};

const Nav = ({ currentStep = emptyFunc, tabs, goToStep = emptyFunc, isEditMode }) => {
  const { t } = useTranslation();

  const getClassNamesForTab = useCallback(
    (tabIndex) => {
      let classnames = 'tab';
      if (currentStep === tabIndex + 1) classnames += ' activeTab';
      if (currentStep > tabIndex + 1 && !isEditMode) classnames += ' completedTab';
      if (isEditMode) classnames += ' clickableTab completedTab';
      return classnames;
    },
    [currentStep, isEditMode],
  );

  const onTabClick = useCallback(
    (tabIndex) => {
      if (!isEditMode) return;
      goToStep(tabIndex);
    },
    [goToStep, isEditMode],
  );

  return (
    <div className={cn('tabs', s.tabs)}>
      {tabs.map(({ label }, i) => {
        const classnames = getClassNamesForTab(i);
        return (
          <button onClick={() => onTabClick(i + 1)} type="button" key={label} className={classnames}>
            <div className="circle">
              <span className="step">{i + 1}</span>
            </div>
            <div className="title">{t(label)}</div>
          </button>
        );
      })}
    </div>
  );
};

export default Nav;
