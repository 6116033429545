import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toastErrorMessage } from 'utils/helpers';

const SELECT_CALCULATION_TYPE_MUTATION = gql`
  mutation selectCalculationType($projectId: ID!, $type: CalculationType!) {
    selectCalculationType(projectId: $projectId, type: $type) {
      success
    }
  }
`;

const useSelectCalculationType = () => {
  const [selectCalculationTypeMutation] = useMutation(SELECT_CALCULATION_TYPE_MUTATION);

  const handleUpdate = useCallback(
    async (variables) => {
      try {
        await selectCalculationTypeMutation({ variables });
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [selectCalculationTypeMutation],
  );

  return handleUpdate;
};

export default useSelectCalculationType;
